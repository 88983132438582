import React, { Component } from "react";
import Header from '../../shared-components/Header/Header';
import Map from '../../assets/images/contact-us/map.png';
import { Link } from 'react-router-dom';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextareaAutosize from '@mui/material/TextareaAutosize';
// import TextField from '@material-ui/icons/TextField';
import AccountCircle from '@material-ui/icons/AccountCircle';
import './ContactUs.scss';
import Footer from "../../shared-components/Footer/Footer";
import { Accordion } from "react-bootstrap";
import emailIcon from '../../assets/images/contact-us/emailIcon.svg';
import contactIcon from '../../assets/images/contact-us/contactIcon.svg';
import follow from '../../assets/images/contact-us/follow.svg';
import facebookIcon from '../../assets/images/contact-us/FB.svg';
import twitterIcon from '../../assets/images/contact-us/twitter.svg';
import instaIcon from '../../assets/images/contact-us/instagram.svg';
import linkedInIcon from '../../assets/images/contact-us/linkdin.svg';
import youtubeIcon from '../../assets/images/contact-us/youtube.svg';


export class ContactUs extends Component {
    state = {
        chars_left: 1000,
        max_char: 1000
    }
    handleWordCount = event => {
        const charCount = event.target.value.length;
        const maxChar = this.state.max_char;
        const charLength = charCount;
        this.setState({ chars_left: charLength });
    }

    render() {


        return (
            <div className="contactUsPage">
                <Header />
                <div className="innerBanner contactUsbanner text-center">
                    <div className="bannerInfo">
                        <h1>Contact Us</h1>
                        <p>Need a specific record? Want to plan a visit? Wish to enquire about internship? Here's how you can get in touch!</p>
                    </div>
                </div>
                <div className="contactUs p-0 p-lg-5">
                    <div className="container p-4 pb-0 p-lg-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="contactInfo">
                                    
                                    <p>To make the most efficient use of your time, please write, telephone, or e-mail us in advance to make sure that the records you want to see are at our archives. </p>
                                    <p class="newPara">Consultation of archival materials is by appointment only, during normal business hours (Monday to Friday, 10:00 am to 4:30 pm)</p>

                                    <div className="row p-lg-0 pb-3">
                                        <div className="col-12 col-lg-4">
                                            <div className="label"><img src={contactIcon} alt="Contact Number"></img> Contact Number </div>
                                            <p className="details">+91 22 6796 4124/4196/4197</p>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="label"><img src={emailIcon} alt="Email ID"></img> Email ID</div>
                                            <p className="details"><a href="mailto:archives@godrej.com">archives@godrej.com</a></p>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="label"><img src={follow} alt="Follow Us on"></img> Follow Us on</div>
                                            
                                            <div className="text-start mt-md-0 details">
                                                <ul className="footerIcon mb-0">
                                                    
                                                    <Link to={{ pathname: "https://www.facebook.com/GodrejArchives/" }} target="_blank" className="linkStyleNew"> <img src={facebookIcon} alt="Facebook Icon"></img> </Link>
                                                    <Link to={{ pathname: "https://twitter.com/godrejarchives?lang=en" }} target="_blank" className="linkStyleNew"> <img src={twitterIcon} alt="Twitter Icon"></img></Link>
                                                    <Link to={{ pathname: "https://www.instagram.com/godrej.archives/?hl=en" }} target="_blank" className="linkStyleNew"> <img src={instaIcon} alt="Instagram Icon"></img> </Link>
                                                    <Link to={{ pathname: "https://www.linkedin.com/in/godrej-archives-5452a9144/" }} target="_blank" className="linkStyleNew"> <img src={linkedInIcon} alt="Linkedin Icon"></img> </Link>
                                                    <Link to={{ pathname: "https://www.youtube.com/channel/UCGNtoQJx94guoF3nclCXuJg" }} target="_blank" className="linkStyleNew"> <img src={youtubeIcon} alt="YouTube Icon"></img> </Link>
                                                </ul>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="location mt-4"><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.9954222559354!2d72.92411531421324!3d19.107856755937316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c7bec4ffe8e3%3A0x788a705c67172563!2sGodrej%20Archives!5e0!3m2!1sen!2sin!4v1637555629511!5m2!1sen!2sin"></iframe></div>
                                </div>
                            </div>
                            <div className="col-md-6 d-none">
                                <div className="contactForm">
                                    <h4 className="formHeader mb-4">PLAN A VISIT</h4>
                                    <FormControl variant="standard" className="w-100">

                                        <Input
                                            id="input-with-icon-adornment" placeholder="Type Full Name here"
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <AccountCircle />
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                    <FormControl className="w-100 my-4">

                                        <Input
                                            id="input-with-icon-adornment" placeholder="Type Email ID here"
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <AccountCircle />
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                    <TextareaAutosize className="w-100"
                                        aria-label="minimum height"
                                        minRows={3}
                                        placeholder="Type Additional Detail here"
                                        style={{ width: 200 }}
                                        maxLength="1000"
                                        onChange={this.handleWordCount}
                                    />

                                    <p className="ms-auto countingInfo">  {this.state.chars_left} / {this.state.max_char} Chars</p>
                                    <div className="text-center"><button className="submitBtn">Submit</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="faq pt-90">
                    <div className="container">
                        <h2 className="secHeader mb-4">FAQ</h2>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>What are the Godrej Archives?</Accordion.Header>
                                <Accordion.Body>
                                Godrej Archives is a team of historians preserving the history and developments of the Godrej Group. Our aim is to unfold the contribution made by the Godrej Group to the industrial history of India, as well as the country's future.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>What do you collect?</Accordion.Header>
                                <Accordion.Body>
                                We collect records pertaining to the history of Godrej. These may include corporate records, legal records, financial records, production records, minutes, reports, catalogues, sales & marketing literature, photographs, products, other publications etc.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Where are you located?</Accordion.Header>
                                <Accordion.Body>
                                We are located in Plant No.19A, within the premises of Godrej & Boyce Mfg. Co. Ltd. in Pirojshanagar, Vikhroli West, Mumbai. We request you to get in touch with us prior to the actual visit in order to enable us to serve you more efficiently. You can mail us at archives@godrej.com or call us on 022-67964124 / 4196 / 4197
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Can I use the Godrej Archives, if I am not affiliated with the company?</Accordion.Header>
                                <Accordion.Body>
                                Of course you can! The Godrej Archives is open to all during our office timings. We request you to get in touch with us prior to the actual visit in order to enable us to serve you more efficiently. You can mail us at archives@godrej.com or call us on 022-67964124 / 4196 / 4197
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Do you offer research services for someone who is unable to visit the archives?</Accordion.Header>
                                <Accordion.Body>
                                We welcome enquiries relating to history of Godrej and help researchers find aids and material location. However, research help will not be provided unless the user is willing to recompense the Archives for staff time.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Are there restrictions on access to any of the Archives holdings?</Accordion.Header>
                                <Accordion.Body>
                                Yes, we reserve the right to deny access to specific information at our discretion.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>Can one take copies of material with Godrej Archives?</Accordion.Header>
                                <Accordion.Body>
                                Yes, reprography services are available at Godrej Archives at a certain charge. However, we will not copy an original item that is deemed too fragile for copying, or any document that may be damaged while copying.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>How does one offer an object for donation?</Accordion.Header>
                                <Accordion.Body>
                                Godrej Archives accepts relevant material of archival value as a donation or on loan, from individuals and organizations. If you possess any material (documents, photographs, old products etc.) pertaining to the history of Godrej, please call us or email us.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                    </div>
                    <div class="mb-5"></div>
                    <Footer />
                </div>
            </div>
        )
    }
}
export default ContactUs;