import React, { Component } from "react";
import "./ReferenceEnquiryForm.scss";
import Header from "../../../shared-components/Header/Header";
import Footer from "../../../shared-components/Footer/Footer";
import resetIcon from "../../../assets/images/resetIcon.svg";
import { FaCity } from "react-icons/fa";
import { BsTelephone, BsGlobe, BsFlag } from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";
import { BiBuildings } from "react-icons/bi";
import { Form, FloatingLabel } from "react-bootstrap";
import PrivacyService from "../../../services/Privacy.service";
import EnquiryService from "../../../services/Enquiry.service";
import { data } from "jquery";


const privacy_link = process.env.REACT_APP_MY_LINK;

export class ReferenceEnquiryForm extends Component {

  constructor(props) {
    super(props);    
    this.state = {
      chars_left: 0,
      max_char: 1000, 
      privacyurl:privacy_link,
      countryid:'',
      stateid:'',
      cityid:'',
      CountryData: [],
      StateData:[],
      CityData:[],
      subname: '',
      subemail: '',
      submobile:'',
      subquery:'',
      ddlCountry:'',
      ddlState:'',
      ddlCity:'',
      subchkPrivacyNotice:'',
      subchkaccurate:'', 
      fields: {
       subname: '',
       subemail: '',
       submobile:'',
       subquery:'',
       ddlCountry:'',
       ddlState:'',
       ddlCity:'',
       subchkPrivacyNotice:'',
       subchkaccurate:'',
       countryid: '',
       stateid:'',
       cityid:'',      
      },
      errors: {
         subname: '',
         subemail: '',
         submobile:'',
         subquery:'',
         ddlCountry:'',
         ddlState:'',
         ddlCity:'',
         subchkPrivacyNotice:'',
         subchkaccurate:'',
         countryid: '',
         stateid:'',
         cityid:'',
      }
    };
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeMobile = this.handleChangeMobile.bind(this);
    this.handleChangeQuery = this.handleChangeQuery.bind(this);
  }

  componentDidMount() {
       //this.getPrivacyDetails();
       this.getCountryList();
  }

  handleChangeName(e) {
    this.setState({ subname: e.target.value });
      this.setState({
        errors: {
          ...this.state.errors,
        },
        fields: {
          ...this.state.fields,
          [e.target.name]: e.target.value
        }
      });
    }

    handleChangeEmail(e) {
      this.setState({ subemail: e.target.value });
        this.setState({
          errors: {
            ...this.state.errors,
          },
          fields: {
            ...this.state.fields,
            [e.target.name]: e.target.value
          }
        });
      }

      handleChangeMobile(e) {
        this.setState({ submobile: e.target.value });
          this.setState({
            errors: {
              ...this.state.errors,
            },
            fields: {
              ...this.state.fields,
              [e.target.name]: e.target.value
            }
          });
        }

        handleChangeQuery(e) {
          this.setState({ subquery: e.target.value });
            this.setState({
              errors: {
                ...this.state.errors,
              },
              fields: {
                ...this.state.fields,
                [e.target.name]: e.target.value
              }
            });
          }

    getPrivacyDetails()
{
  //Commented by saditi
  //debugger;
  const req = {
    
    pg_company: "GNB",
    pg_PolicyName: "GodrejArchives",
  }
  PrivacyService.GetPrivacyPolicySrv(req).then(    
    response => {
      //debugger;
      if (response.data.model != null) {
        if (response.data.message == "200")
        {
          this.state.privacyurl=response.data.model.policyURL;
        }
        else {
          alert('Error while fetching data');
        }
      }
      else {
        alert('Error while fetching data');
      }
    },
    error => {
      alert('Error while fetching data');
    }
    
  );

}  

getCountryList()
{
  EnquiryService.GetCountriesSrv().then(
    response => {
      if (response.data.model != null) {
        if (response.data.message == "200")
        {
          if (response.data.model != null) {
            if (response.data.model.length > 0 && response.data.model[0] !== null) {

this.setState({
  CountryData: response.data.model
  });
 
                }
                
            }
            else {
              alert('Error while fetching data');
            }


        }
        else {
          alert('Error while fetching data');
        }
        }
        else {
          alert('Error while fetching data');
        }
      
    },
    error => {
      alert('Error while fetching data');
    }
    
    
  );

}  
SelectCity = (e) => {
  this.setState({
  cityid: e.target.value
  });
  }

ChangeState = (e) => {
  this.setState({
  countryid: e.target.value
  });
  this.getStateList(e.target.value)
  }

getStateList(country_id)
{
  const req = {
    
    countryid: country_id
  }
  EnquiryService.GetStatesSrv(req).then(
    response => {
      if (response.data.model != null) {
        if (response.data.message == "200")
        {
          if (response.data.model != null) {
            if (response.data.model.length > 0 && response.data.model[0] !== null) {

this.setState({
  StateData: response.data.model
  });
 
                }
                
            }
            else {
              alert('Error while fetching data');
            }


        }
        else {
          alert('Error while fetching data');
        }
        }
        else {
          alert('Error while fetching data');
        }
      
    },
    error => {
      alert('Error while fetching data');
    }
    
    
  );

} 

ChangeCity = (e) => {
  this.setState({
  stateid: e.target.value
  });   
  this.getCityList(e.target.value)  
  }

getCityList(state_id)
{
  const req = {
    
    stateid: state_id
  }
  EnquiryService.GetCitiesSrv(req).then(
    response => {
      if (response.data.model != null) {
        if (response.data.message == "200")
        {
          if (response.data.model != null) {
            if (response.data.model.length > 0 && response.data.model[0] !== null) {

this.setState({
  CityData: response.data.model
  });
 
                }
                
            }
            else {
              alert('Error while fetching data');
            }


        }
        else {
          alert('Error while fetching data');
        }
        }
        else {
          alert('Error while fetching data');
        }
      
    },
    error => {
      alert('Error while fetching data');
    }
    
    
  );

}  

  flipCheckbox()
  {
    this.setState({subchkPrivacyNotice:!this.state.subchkPrivacyNotice})
  }
 
  flipCheckbox1()
  {
    this.setState({subchkaccurate:!this.state.subchkaccurate})
  }

  // handleChange(e) {
  //   let fields = this.state.fields;
  //   fields[e.target.name] = e.target.value;
  //   this.setState({
  //     fields
  //   });

  // }

  handleWordCount = (event) => {
    const charCount = event.target.value.length;
    const maxChar = this.state.max_char;
    const charLength = charCount;
    this.setState({ chars_left: charLength });
  };

  btnReset = () => {
    window.location.reload(false);
    this.setState({
      subname: '',
      subemail:'',
      submobile:'',
      subchkPrivacyNotice:'',
      subchkaccurate:'',
      subquery:'',
      countryid:'',
      stateid:'',
      cityid:''   
  });
  }

  btnSaveEnquiry = () => {  
    const { fields } = this.state;  
    if (!this.validateForm()) {
      return;
      }
    if (fields.subname && fields.subemail && fields.submobile && fields.countryid && fields.stateid 
      && fields.cityid && fields.subquery && fields.subchkPrivacyNotice && fields.subchkaccurate) {
        const data = {
            subname: fields.subname,
            subemail: fields.subemail,
            submobile:fields.submobile,
            subchkPrivacyNotice:fields.subchkPrivacyNotice,
            subchkaccurate:fields.subchkaccurate,
            subquery:fields.subquery,
            ddlCountry:fields.countryid,
            ddlState:fields.stateid,
            ddlCity:fields.cityid
        };
  
    }
    const req = {
      reffullname : this.state.subname,
      refcountry:this.state.countryid,
      refstate :this.state.stateid,
      refcity :this.state.cityid,
      refemailid :this.state.subemail,
      refcontactno :this.state.submobile,
      refnotes :this.state.subquery,
      refpolicyconsent :this.state.subchkPrivacyNotice,
      refvaliddataconsent :this.state.subchkaccurate,
    }
    EnquiryService.SaveReferenceEnquirySrv(req).then(
      response => {
        if (response.data.model != null) {
          if (response.data.model.status == "Success") {
           alert('Data Saved Successfully');
           window.location.reload(false);
            this.setState({
              subname: '',
              subemail: '',
              
          });
          
        
          }
          else {
            alert('Error while submitting data');
            window.location.reload(false);
          }
  
  
        }
        else {
          alert('Error while submitting data');
          window.location.reload(false);
        }
      },
      error => {
        alert('Error while submitting data');
        window.location.reload(false);
      }
      
    );
  }

//  privacylink()
// {
//   const PRIV_URL=PRIVACY_URL();
// }


  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["subname"]) {
      formIsValid = false;
      errors["subname"] = "*Please enter your full name.";
    }

    if (typeof fields["subname"] !== "undefined") {
      if (!fields["subname"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["subname"] = "*Please enter alphabet characters only.";
      }
    }

    if (!fields["subemail"]) {
      formIsValid = false;
      errors["subemail"] = "*Please enter your email-ID.";
    }

    if (typeof fields["subemail"] !== "undefined") {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!fields["subemail"]) {
        formIsValid = false;
      errors["subemail"] = "*Please enter your email-ID.";
      }
      else {        
       if (!pattern.test(fields["subemail"])) {
         formIsValid = false;
         errors["subemail"] = "*Please enter valid email-ID.";
      }
    }
    }

    if (!fields["submobile"]) {
      formIsValid = false;
      errors["submobile"] = "*Please enter your contact number.";
    }

    if (typeof fields["submobile"] !== "undefined") {
      var pattern = new RegExp( /^[0-9\b]+$/);
    
      if (!fields["submobile"]) {
        formIsValid = false;
        errors["submobile"] = "*Please enter your contact number.";
      }
      else {
        if (!pattern.test(fields["submobile"])) {
        formIsValid = false;        
        errors["submobile"] = "*Please enter numbers only.";
      }
      
      }      
    }
    if(fields["submobile"].length !==0){
    if(fields["submobile"].length !== 10){
      formIsValid = false;    
      errors["submobile"] = "*Please enter valid phone number.";
  
    }
  }

    if (!fields["subquery"]) {
      formIsValid = false;
      errors["subquery"] = "*Please enter your questions/notes.";
    }

    if (!this.state.subchkPrivacyNotice){
      formIsValid = false;
      errors["subchkPrivacyNotice"] = "*Please select consent for privacy notice.";
    }
  
    if (!this.state.subchkaccurate){
      formIsValid = false;
      errors["subchkaccurate"] = "*Please select declaration for valid inforamtion provided by you.";
    }
   
    if (this.state.countryid==0){
      formIsValid = false;
      errors["countryid"] = "*Please select country.";
    }
    if (this.state.stateid==0){
      formIsValid = false;
      errors["stateid"] = "*Please select state.";
    }
    if (this.state.cityid==0){
      formIsValid = false;
      errors["cityid"] = "*Please select city.";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;


  }

  render() {
    const options = {
      errors:"",
      items: 1,
      nav: true,
      autoplay: false,
      loop: false,
      rewindNav: false,
      navText: [
        "<div class='nav-btn prev-slide'></div>",
        "<div class='nav-btn next-slide'></div>",
      ],
      stagePadding: 50,
    };
    return (
      <div class="referenceEnquiry">
        <Header />
        <div className="innerBanner referenceEnquirybanner text-center">
          <div className="bannerInfo">
            <h1>REFERENCE ENQUIRIES</h1>
            <p>
            A document. A photograph. Information about any archival record about Godrej history is
just a form away!
            </p>
          </div>
        </div>
        <div className="reference-enquiry lightOrangeBg py-90">
          <div className="container p-4 p-lg-0">
            <div className="row">
              <div className="col-md-9 m-auto">
                <div className="enquireForm">
                  <div className="header px-5 py-4">
                    <h2 className="secHeader whiteBorder">
                      REFERENCE ENQUIRY FORM
                    </h2>
                    <p className="m-0 ps-3">All fields are mandatory</p>
                  </div>
                  <div className="formBio p-4 p-lg-5">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="formGrid">
                          <div className="formIcon">
                            <AiOutlineUser />
                          </div>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Full Name"
                           
                          >
                            <Form.Control type="text"
                            placeholder="Full Name" 
                            name="subname"
                            value={this.state.subname} 
                            //onChange={this.handleChange}
                            onChange={this.handleChangeName}
                            maxlength="30"
                              />
                          </FloatingLabel>
                          
                        </div>   
                        <span className="text-danger">{this.state.errors.subname}</span>                            
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="formGrid">
                          <div className="formIcon">
                            <BsGlobe />
                          </div>
                          <FloatingLabel
                            controlId="floatingSelectGrid"
                            // label="Select Country"
                          >
                           
                            <Form.Select aria-label="Floating label select example"
                      type="text"
                      name="dllCountry"
                      value={this.state.countryid}
                      onChange={this.ChangeState}
                          >
                      <option value="">Select Country</option>
                      {this.state.CountryData.map((e, key) => {  
return <option key={key} value={e.countryid}>{e.countryname}</option>;  
})}   
                    </Form.Select>
                          </FloatingLabel>
                          
                        </div>
                        <span className="text-danger">{this.state.errors.countryid}</span>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="formGrid">
                          <div className="formIcon">
                            <BsFlag />
                          </div>
                          <FloatingLabel
                            controlId="floatingSelectGrid"
                            // label="Select State"
                          >
                            <Form.Select aria-label="Floating label select example"
                             type="text"
                             name="ddlState"
                             value={this.state.stateid}
                             onChange={this.ChangeCity}
                                 >
                             <option value="">Select State</option>
                             {this.state.StateData.map((e, key) => {  
       return <option key={key} value={e.stateid}>{e.statename}</option>;  
       })}   
                            </Form.Select>
                          </FloatingLabel>
                          
                        </div>
                        <span className="text-danger">{this.state.errors.stateid}</span>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="formGrid">
                          <div className="formIcon">
                            <BiBuildings />
                          </div>
                          <FloatingLabel
                            controlId="floatingSelectGrid"
                            // label="Select City"
                          >
                            <Form.Select aria-label="Floating label select example"
                             type="text"
                             name="ddlCity"
                             value={this.state.cityid}
                             onChange={this.SelectCity}
                            >
                            <option value="">Select City</option>
                             {this.state.CityData.map((e, key) => {  
       return <option key={key} value={e.cityid}>{e.cityname}</option>;  
       })}   
                            </Form.Select>
                          </FloatingLabel>
                          
                        </div>
                        <span className="text-danger">{this.state.errors.cityid}</span>
                      </div>
                      <div className="col-md-6">
                        <div className="formGrid">
                          <div className="formIcon">
                            <i className="fa fa-envelope-o"></i>
                          </div>

                          <FloatingLabel
                            controlId="floatingInput"
                            label="Email address"
                           
                          >
                            <Form.Control
                              type="email"
                              name="subemail"
                              value={this.state.subemail} 
                              //onChange={this.handleChange}
                              onChange={this.handleChangeEmail}
                              placeholder="name@example.com"
                              maxlength="50"
                            />
                          </FloatingLabel>
                          
                        </div>
                        <span className="text-danger">{this.state.errors.subemail}</span>
                      </div>
                      <div className="col-md-6">
                        <div className="formGrid">
                          <div className="formIcon">
                            {/* <i className="fa fa-phone"></i> */}
                            <BsTelephone />
                          </div>

                          <FloatingLabel
                            controlId="floatingInput"
                            label="Contact Number"
                            
                          >
                            <Form.Control
                              type="text"
                              name="submobile"
                              value={this.state.fields.submobile} 
                              //onChange={this.handleChange}
                              onChange={this.handleChangeMobile}
                              maxlength="10"
                              placeholder="Contact Number"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />                             
                          </FloatingLabel>
                          
                        </div>
                        <span className="text-danger">{this.state.errors.submobile}</span>
                      </div>
                      <div className="col-md-12">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Questions/Notes"
                          className="mb-2 mt-2"
                        >
                          <Form.Control
                            as="textarea"
                            rows={5}
                            maxLength="1000"
                            //onChange={e => { this.handleChange(e); this.handleWordCount(e) }} 
                            onChange={e => { this.handleChangeQuery(e); this.handleWordCount(e) }}                           
                            name="subquery"
                            //onClick={this.handleChange}
                            value={this.state.subquery} 
                          />
                        </FloatingLabel>
                        <span className="text-danger">{this.state.errors.subquery}</span>
                        <p className="ms-auto countingInfo">
                          {this.state.chars_left} / {this.state.max_char} Characters
                        </p>
                      </div>
                      <div className="col-md-12 mb-3">
                        <Form.Group                          
                          controlId="formBasicCheckbox"
                        >
                         <div class="row">
                          <div className="col-md-1 pt-0 checkStyle">
                          <Form.Check
                            type="checkbox"
                            id="subchkPrivacyNotice"
                            value={this.state.fields.subchkPrivacyNotice}
                            onChange={this.flipCheckbox.bind(this)}
                            className="pt-0"/>
                            </div>
                            <div className="col-md-11 p-0">
                            <label
                            class="form-check-label"
                            for="subchkPrivacyNotice"
                          >
                            I have read the <a href={this.state.privacyurl} target="_blank">Privacy Notice</a> and hereby provide my
                            consent to process the information for the purposes
                            defined in the notice.
                          </label>
                          </div>
                          </div>
                         
                         
                        </Form.Group>
                        <span className="text-danger">{this.state.errors.subchkPrivacyNotice}</span>
                      </div>
                      <div className="col-md-12">
                        <Form.Group                          
                          controlId="formBasicCheckboxx"
                        >
                          <div class="row">
                          <div className="col-md-1 pt-0 checkStyle">
                          <Form.Check
                            type="checkbox"
                            id="subchkaccurate"
                            value={this.state.fields.subchkaccurate}
                            onChange={this.flipCheckbox1.bind(this)} />
                           </div>
                            <div className="col-md-11 p-0">
                             <label
                            class="form-check-label"
                            for="subchkaccurate" >
                            I hereby declare that the information provided by me is accurate.
                          </label>
                          </div>
                          </div>
                        </Form.Group>
                        <span className="text-danger">{this.state.errors.subchkaccurate}</span>
                      </div>
                      <div className="text-center actionBtn mt-4">
                        <button className="resetBtn" onClick={this.btnReset}>
                          <img
                            src={resetIcon}
                            alt="Reset Icon"
                            className="me-2"
                          ></img>
                          Reset
                        </button>
                        <button className="submitBtn ms-2" onClick={this.btnSaveEnquiry}>Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default ReferenceEnquiryForm;
