import React, { Component } from "react";
import "./Talks.scss";
import Header from "../../../shared-components/Header/Header";
import Footer from "../../../shared-components/Footer/Footer";
import talks1 from "../../../assets/images/what-we-do/talks/1.png";
import talks2 from "../../../assets/images/what-we-do/talks/2.png";
import talks3 from "../../../assets/images/what-we-do/talks/3.png";
import talks4 from "../../../assets/images/what-we-do/talks/4.png";
import talks5 from "../../../assets/images/what-we-do/talks/5.png";
import talks6 from "../../../assets/images/what-we-do/talks/6.png";
import talks7 from "../../../assets/images/what-we-do/talks/7.png";
import talks8 from "../../../assets/images/what-we-do/talks/8.png";
import talks10 from "../../../assets/images/what-we-do/talks/10.png";
import talks11 from "../../../assets/images/what-we-do/talks/11.png";
import talks12 from "../../../assets/images/what-we-do/talks/12.png";
import talks13 from "../../../assets/images/what-we-do/talks/13.png";
import talk14 from "../../../assets/images/what-we-do/talks/TirthankarRoy.png";
import talk15 from "../../../assets/images/what-we-do/talks/IndiraChowdhury.png";
import talk16 from "../../../assets/images/what-we-do/talks/PrafullAnubhai.png";
import talk17 from "../../../assets/images/what-we-do/talks/DavidArnold.png";
import talk18 from "../../../assets/images/what-we-do/talks/MedhaKudaisya.png";
import talk19 from "../../../assets/images/what-we-do/talks/RituBirla.png";
import talk20 from "../../../assets/images/what-we-do/talks/RossBassett.png";
import talk21 from "../../../assets/images/what-we-do/talks/AmiyaBagchi.png";
import talk22 from "../../../assets/images/what-we-do/talks/DwijendraTripathi.png";
import talk23 from "../../../assets/images/what-we-do/talks/DouglasHaynes.png";
import Button from "@material-ui/core/Button";
import downloadIcon from "../../../assets/images/downloadIcon.svg";
import FeedbackForm from "./FeedbackForm";
import { Nav, Navbar} from "react-bootstrap";

import { PaginationItem } from "@material-ui/lab";

export class Talks extends Component {
  // changeRating(newRating, name) {
  //   this.setState({
  //     rating: newRating,
  //   });
  // }
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     max_char: 1000,
  //   };
  // }

  // handleWordCount = (event) => {
  //   let charCount = event.target.value.length;
  //   let maxChar = 1000;
  //   let charLength = maxChar - charCount;
  //   this.setState({ max_char: charLength });
  // };
  constructor(props) {
    super(props);
    this.state = {
      //activePage: 15, // commented by Tabbu
        thursdayTalk1: true,
        annualLecture2: false,
        //masterClass3: false,
        open: false,
        openFeedback:false,
        feedbackOpen: false,
        formFeedback:''
    };
    this.handleChildUnmount = this.handleChildUnmount.bind(this);
  }
     
  openDialog(type) {
    this.setState({ open: true });
    this.setState({ type });
  }
  closeDialog() {
    this.setState({ open: false });
  }

   // FeedbackFormRef = ({handleOpen}) => {
  //   debugger;
  //   //const {val}=handleOpen || {};
  //   //if(handleOpen!=null || handleOpen!=undefined)
  //   this.openDialogBox = handleOpen;
  // }
  
  onOpenDialogClick = (e) => {
    //  this.openDialogBox(e);
    const formName = e
      this.setState({
        formFeedback:formName
      })
    this.setState({ openFeedback: true})
    }
  
    handleChildUnmount(){
      this.setState({openFeedback: false});
      this.setState({formFeedback:''});
  } 

    handleButtonStateChange = (toggleKey) => {
      const stateCopy = { ...this.state };
      const nextValue = !stateCopy[toggleKey];
      Object.keys(stateCopy).forEach((key) => (stateCopy[key] = false));
      stateCopy[toggleKey] = nextValue;
      this.setState(stateCopy);
  };
  
  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });
  }

  render() {
    const options = {
      items: 1,
      nav: true,
      autoplay: false,
      loop: false,
      rewindNav: false,
      navText: [
        "<div class='nav-btn prev-slide'></div>",
        "<div class='nav-btn next-slide'></div>",
      ],
      stagePadding: 50,
    };

    return (
      <div class="talksMain">
        <Header />
        <div className="innerBanner talksBanner text-center">
          <div className="bannerInfo">
            <h1>Talks</h1>
            <p>
              As we preserve Godrej history, we ultimately aim to promote
              business history &amp; archives, and to make corporates everywhere
              see heritage as a strategic asset. Let our banner wave!
            </p>
          </div>
        </div>
        <div className="blogs py-90">
          <div className="container">
            <h2 className="secHeader redBorder mb-4">Talks</h2>
            <p>
              Through our Annual Lecture Series, ThursdayTalks, occasional
              Masterclasses and other talks, we stir discussions around business
              history, business archives, products & commodities, national
              development, global economy and more. We believe that this effort
              will generate awareness &amp; appreciation among general public
              about business archives and the corporate heritage of the country.
                    </p>

                </div>
                <div>
                    <Navbar style={{ background: "#E8EBF1" }}>
                        <div className="container p-0">
                            <Nav className="text-center">
                                <Nav.Link
                                    onClick={() => this.handleButtonStateChange("thursdayTalk1")}
                                    active={this.state.thursdayTalk1}
                                    disabled={this.state.thursdayTalk1}
                                >
                                    Thursday Talk
                                </Nav.Link>
                                <Nav.Link
                                    onClick={() => this.handleButtonStateChange("annualLecture2")}
                                    active={this.state.annualLecture2}
                                    disabled={this.state.annualLecture2}
                                >
                                   Annual Lecture
                                </Nav.Link>
                                {/*<Nav.Link
                                    onClick={() => this.handleButtonStateChange("masterClass3")}
                                    active={this.state.masterClass3}
                                    disabled={this.state.masterClass3}
                                >
                                    Master Class
                                </Nav.Link>*/}
                                
                                
                            </Nav>
                        </div>
                    </Navbar>
                    <br />
                    </div>
                <div className="container">
                    
                    <div>
                        <div className="row">
                            {this.state.thursdayTalk1 && (
                                <>

              <div className="col-sm-6 col-lg-4 col-12 mb-5 ">
                <div className="blogGrid">
                  <figure>
                    <img src={talks6} alt="Post 1" className="img-fluid"></img>
                  </figure>
                  <div className="blogsInfo pt-4">
                    <div className="title">
                      6<sup>th</sup> Thursday Talk
                    </div>
                    <h4 className="my-4">
                      Mad Men of Bombay: Revisiting the Creative Revolution in
                      Indian
                    </h4>
                    <Nav.Link href="/talk-details/1" bsPrefix="detailsBtn">
                      DETAILS
                    </Nav.Link>
                    <Button
                      to="/"
                      className="ms-3 feedbackBtn"
                      onClick={this.onOpenDialogClick.bind(this,"THURSDAY TALK")}
                    >
                      Feedback
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-5 ">
                <div className="blogGrid">
                  <figure>
                    <img src={talks5} alt="Post 1" className="img-fluid"></img>
                  </figure>
                  <div className="blogsInfo pt-4">
                    <div className="title">
                      5<sup>th</sup> Thursday Talk
                    </div>
                    <h4 className="my-4">
                      The Global Blue Jeans Transformation
                    </h4>
                    <Nav.Link href="/talk-details/2" bsPrefix="detailsBtn">
                      DETAILS
                    </Nav.Link>
                    <Button
                      to="/"
                      className="ms-3 feedbackBtn"
                     // onClick={this.openDialog.bind(this, "THURSDAY TALK")}
                     onClick={this.onOpenDialogClick.bind(this, "THURSDAY TALK")}
                    >
                      Feedback
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-5 ">
                <div className="blogGrid">
                  <figure>
                    <img src={talks4} alt="Post 1" className="img-fluid"></img>
                  </figure>
                  <div className="blogsInfo pt-4">
                    <div className="title">
                      4<sup>th</sup> Thursday Talk
                    </div>
                    <h4 className="my-4">
                      Timber Trade Trajectory - Urbanization of Bombay and
                      Deforestation of
                    </h4>
                    <Nav.Link href="/talk-details/3" bsPrefix="detailsBtn">
                      DETAILS
                    </Nav.Link>
                    <Button
                      to="/"
                      className="ms-3 feedbackBtn"
                      //onClick={this.openDialog.bind(this, "THURSDAY TALK")}
                      onClick={this.onOpenDialogClick.bind(this, "THURSDAY TALK")}
                    >
                      Feedback
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-5 ">
                <div className="blogGrid">
                  <figure>
                    <img src={talks3} alt="Post 1" className="img-fluid"></img>
                  </figure>
                  <div className="blogsInfo pt-4">
                    <div className="title">
                      3<sup>rd</sup> Thursday Talk
                    </div>
                    <h4 className="my-4">
                      Power, Privilege and Posture: The Politics of the Seat in
                      India
                    </h4>
                    <Nav.Link href="/talk-details/4" bsPrefix="detailsBtn">
                      DETAILS
                    </Nav.Link>
                    <Button
                      to="/"
                      className="ms-3 feedbackBtn"
                      //onClick={this.openDialog.bind(this, "THURSDAY TALK")}
                      onClick={this.onOpenDialogClick.bind(this, "THURSDAY TALK")}
                    >
                      Feedback
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-5 ">
                <div className="blogGrid">
                  <figure>
                    <img src={talks2} alt="Post 1" className="img-fluid"></img>
                  </figure>
                  <div className="blogsInfo pt-4">
                    <div className="title">
                      2<sup>nd</sup> Thursday Talk
                    </div>
                    <h4 className="my-4">Business in the Time of Contagion</h4>
                    <Nav.Link href="/talk-details/5" bsPrefix="detailsBtn">
                      DETAILS
                    </Nav.Link>
                    <Button
                      to="/"
                      className="ms-3 feedbackBtn"
                      //onClick={this.openDialog.bind(this, "THURSDAY TALK")}
                      onClick={this.onOpenDialogClick.bind(this, "THURSDAY TALK")}
                    >
                      Feedback
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-5 ">
                <div className="blogGrid">
                  <figure>
                    <img src={talks1} alt="Post 1" className="img-fluid"></img>
                  </figure>
                  <div className="blogsInfo pt-4">
                    <div className="title">
                      1<sup>st</sup> Thursday Talk
                    </div>
                    <h4 className="my-4">Typewriter Tip Tip</h4>
                    <Nav.Link href="/talk-details/6" bsPrefix="detailsBtn">
                      DETAILS
                    </Nav.Link>
                    <Button
                      to="/"
                      className="ms-3 feedbackBtn"
                      //onClick={this.openDialog.bind(this, "THURSDAY TALK")}
                      onClick={this.onOpenDialogClick.bind(this, "THURSDAY TALK")}
                    >
                      Feedback
                    </Button>
                  </div>
                </div>
                                    </div>

                                </>
                            )}
                        </div>

                        <div>
                            {this.state.annualLecture2 && (
                                <>
                <div className="row mt-5">
              <div className="col-sm-6 col-lg-4 col-12 mb-5 ">
                <div className="blogGrid">
                  <figure>
                    <img src={talks10} alt="Post 1" className="img-fluid"></img>
                  </figure>
                  <div className="blogsInfo pt-4">
                    <div className="title">
                      16<sup>th</sup> Annual Lecture | 2022
                    </div>
                    <h4 className="my-4">The Global Merchants</h4>
                    <Nav.Link href="/annual-details/16" bsPrefix="detailsBtn">
                      DETAILS
                    </Nav.Link>
                    <Button
                      to="/"
                      className="ms-3 feedbackBtn"
                      //onClick={this.openDialog.bind(this, "THURSDAY TALK")}
                      onClick={this.onOpenDialogClick.bind(this, "THURSDAY TALK")}
                    >
                      Feedback
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-5 ">
                <div className="blogGrid">
                  <figure>
                    <img src={talks7} alt="Post 1" className="img-fluid"></img>
                  </figure>
                  <div className="blogsInfo pt-4">
                    <div className="title">
                      15<sup>th</sup> Annual Lecture | 2019
                    </div>
                    <h4 className="my-4">
                      Empire of Cotton: The Global Origins of Modern Capitalism
                    </h4>
                    <Nav.Link href="/annual-details/1" bsPrefix="detailsBtn">
                      DETAILS
                    </Nav.Link>
                    <Button
                      to="/"
                      className="ms-3 feedbackBtn"
                      //onClick={this.openDialog.bind(this, "ANNUAL LECTURE")}
                      onClick={this.onOpenDialogClick.bind(this, "ANNUAL LECTURE")}
                    >
                      Feedback
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-5 ">
                <div className="blogGrid">
                  <figure>
                    <img src={talks8} alt="Post 1" className="img-fluid"></img>
                  </figure>
                  <div className="blogsInfo pt-4">
                    <div className="title">
                      14<sup>th</sup> Annual Lecture | 2018
                    </div>
                    <h4 className="my-4">
                      India Moving: A History of Migration
                    </h4>
                    <Nav.Link href="/annual-details/2" bsPrefix="detailsBtn">
                      DETAILS
                    </Nav.Link>
                    <Button
                      to="/"
                      className="ms-3 feedbackBtn"
                      //onClick={this.openDialog.bind(this, "ANNUAL LECTURE")}
                      onClick={this.onOpenDialogClick.bind(this, "ANNUAL LECTURE")}
                    >
                      Feedback
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-5 ">
                <div className="blogGrid">
                  <figure>
                    <img src={talks11} alt="Post 1" className="img-fluid"></img>
                  </figure>
                  <div className="blogsInfo pt-4">
                    <div className="title">
                      13<sup>th</sup> Annual Lecture | 2017
                    </div>
                    <h4 className="my-4">
                      What Business History Tells Us about the Future of
                      Globalisation
                    </h4>
                    <Nav.Link href="/annual-details/3" bsPrefix="detailsBtn">
                      DETAILS
                    </Nav.Link>
                    <Button
                      to="/"
                      className="ms-3 feedbackBtn"
                      //onClick={this.openDialog.bind(this, "ANNUAL LECTURE")}
                      onClick={this.onOpenDialogClick.bind(this, "ANNUAL LECTURE")}
                    >
                      Feedback
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-5 ">
                <div className="blogGrid">
                  <figure>
                    <img src={talks12} alt="Post 1" className="img-fluid"></img>
                  </figure>
                  <div className="blogsInfo pt-4">
                    <div className="title">
                      12<sup>th</sup> Annual Lecture | 2016
                    </div>
                    <h4 className="my-4">
                      Corporate and Business Oral History: Opportunities and
                      challenges
                    </h4>
                    <Nav.Link href="/annual-details/4" bsPrefix="detailsBtn">
                      DETAILS
                    </Nav.Link>
                    <Button
                      to="/"
                      className="ms-3 feedbackBtn"
                      //onClick={this.openDialog.bind(this, "ANNUAL LECTURE")}
                      onClick={this.onOpenDialogClick.bind(this, "ANNUAL LECTURE")}
                    >
                      Feedback
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-5 ">
                <div className="blogGrid">
                  <figure>
                    <img src={talks13} alt="Post 1" className="img-fluid"></img>
                  </figure>
                  <div className="blogsInfo pt-4">
                    <div className="title">
                      11<sup>th</sup> Annual Lecture | 2016
                    </div>
                    <h4 className="my-4">
                      The Challenge of Contemporary History
                    </h4>
                    <Nav.Link href="/annual-details/5" bsPrefix="detailsBtn">
                      DETAILS
                    </Nav.Link>
                    <Button
                      to="/"
                      className="ms-3 feedbackBtn"
                      //onClick={this.openDialog.bind(this, "ANNUAL LECTURE")}
                      onClick={this.onOpenDialogClick.bind(this, "ANNUAL LECTURE")}
                    >
                      Feedback
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-5 ">
                <div className="blogGrid">
                  <figure>
                    <img src={talk14} alt="Post 1" className="img-fluid"></img>
                  </figure>
                  <div className="blogsInfo pt-4">
                    <div className="title">
                      10<sup>th</sup> Annual Lecture | 2014
                    </div>
                    <h4 className="my-4">
                      How the Raj changed India: Another Look
                    </h4>
                    <Nav.Link href="/annual-details/6" bsPrefix="detailsBtn">
                      DETAILS
                    </Nav.Link>
                    <Button
                      to="/"
                      className="ms-3 feedbackBtn"
                      //onClick={this.openDialog.bind(this, "ANNUAL LECTURE")}
                      onClick={this.onOpenDialogClick.bind(this, "ANNUAL LECTURE")}
                    >
                      Feedback
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-5 ">
                <div className="blogGrid">
                  <figure>
                    <img src={talk15} alt="Post 1" className="img-fluid"></img>
                  </figure>
                  <div className="blogsInfo pt-4">
                    <div className="title">
                      9<sup>th</sup> Annual Lecture | 2013
                    </div>
                    <h4 className="my-4">
                      Between Memory and History: Oral History and the framing
                      of institutional narratives
                    </h4>
                    <Nav.Link href="/annual-details/7" bsPrefix="detailsBtn">
                      DETAILS
                    </Nav.Link>
                    <Button
                      to="/"
                      className="ms-3 feedbackBtn"
                      //onClick={this.openDialog.bind(this, "ANNUAL LECTURE")}
                      onClick={this.onOpenDialogClick.bind(this, "ANNUAL LECTURE")}
                    >
                      Feedback
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-5 ">
                <div className="blogGrid">
                  <figure>
                    <img src={talk16} alt="Post 1" className="img-fluid"></img>
                  </figure>
                  <div className="blogsInfo pt-4">
                    <div className="title">
                      8<sup>th</sup> Annual Lecture | 2012
                    </div>
                    <h4 className="my-4">
                      Role of Values in Institution Building: IIM-A Experience
                    </h4>
                    <Nav.Link href="/annual-details/8" bsPrefix="detailsBtn">
                      DETAILS
                    </Nav.Link>
                    <Button
                      to="/"
                      className="ms-3 feedbackBtn"
                      //onClick={this.openDialog.bind(this, "ANNUAL LECTURE")}
                      onClick={this.onOpenDialogClick.bind(this, "ANNUAL LECTURE")}
                    >
                      Feedback
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-5 ">
                <div className="blogGrid">
                  <figure>
                    <img src={talk17} alt="Post 1" className="img-fluid"></img>
                  </figure>
                  <div className="blogsInfo pt-4">
                    <div className="title">
                      7<sup>th</sup> Annual Lecture | 2012
                    </div>
                    <h4 className="my-4">
                      The Rise of the Indian Typewriter, 1890-1960
                    </h4>
                    <Nav.Link href="/annual-details/9" bsPrefix="detailsBtn">
                      DETAILS
                    </Nav.Link>
                    <Button
                      to="/"
                      className="ms-3 feedbackBtn"
                      //onClick={this.openDialog.bind(this, "ANNUAL LECTURE")}
                      onClick={this.onOpenDialogClick.bind(this, "ANNUAL LECTURE")}
                    >
                      Feedback
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-5 ">
                <div className="blogGrid">
                  <figure>
                    <img src={talk18} alt="Post 1" className="img-fluid"></img>
                  </figure>
                  <div className="blogsInfo pt-4">
                    <div className="title">
                      6<sup>th</sup> Annual Lecture | 2011
                    </div>
                    <h4 className="my-4">
                      The Chequered Career of Business History in India
                    </h4>
                    <Nav.Link href="/annual-details/10" bsPrefix="detailsBtn">
                      DETAILS
                    </Nav.Link>
                    <Button
                      to="/"
                      className="ms-3 feedbackBtn"
                      //onClick={this.openDialog.bind(this, "ANNUAL LECTURE")}
                      onClick={this.onOpenDialogClick.bind(this, "ANNUAL LECTURE")}
                    >
                      Feedback
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-5 ">
                <div className="blogGrid">
                  <figure>
                    <img src={talk19} alt="Post 1" className="img-fluid"></img>
                  </figure>
                  <div className="blogsInfo pt-4">
                    <div className="title">
                      5<sup>th</sup> Annual Lecture | 2010
                    </div>
                    <h4 className="my-4">
                      Business and Philanthropy in the Vernacular: Episodes from
                      Colonial Legal History
                    </h4>
                    <Nav.Link href="/annual-details/11" bsPrefix="detailsBtn">
                      DETAILS
                    </Nav.Link>
                    <Button
                      to="/"
                      className="ms-3 feedbackBtn"
                      //onClick={this.openDialog.bind(this, "ANNUAL LECTURE")}
                      onClick={this.onOpenDialogClick.bind(this, "ANNUAL LECTURE")}
                    >
                      Feedback
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-5 ">
                <div className="blogGrid">
                  <figure>
                    <img src={talk20} alt="Post 1" className="img-fluid"></img>
                  </figure>
                  <div className="blogsInfo pt-4">
                    <div className="title">
                      4<sup>th</sup> Annual Lecture | 2009
                    </div>
                    <h4 className="my-4">
                      MIT Trained Swadeshi: MIT and Indian Nationalism,
                      1880-1947
                    </h4>
                    <Nav.Link href="/annual-details/12" bsPrefix="detailsBtn">
                      DETAILS
                    </Nav.Link>
                    <Button
                      to="/"
                      className="ms-3 feedbackBtn"
                      //onClick={this.openDialog.bind(this, "ANNUAL LECTURE")}
                      onClick={this.onOpenDialogClick.bind(this, "ANNUAL LECTURE")}
                    >
                      Feedback
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-5 ">
                <div className="blogGrid">
                  <figure>
                    <img src={talk21} alt="Post 1" className="img-fluid"></img>
                  </figure>
                  <div className="blogsInfo pt-4">
                    <div className="title">
                      3<sup>rd</sup> Annual Lecture | 2008
                    </div>
                    <h4 className="my-4">
                      The Story of the State Bank of India Archives
                    </h4>
                    <Nav.Link href="/annual-details/13" bsPrefix="detailsBtn">
                      DETAILS
                    </Nav.Link>
                    <Button
                      to="/"
                      className="ms-3 feedbackBtn"
                      //onClick={this.openDialog.bind(this, "ANNUAL LECTURE")}
                      onClick={this.onOpenDialogClick.bind(this, "ANNUAL LECTURE")}
                    >
                      Feedback
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-5 ">
                <div className="blogGrid">
                  <figure>
                    <img src={talk22} alt="Post 1" className="img-fluid"></img>
                  </figure>
                  <div className="blogsInfo pt-4">
                    <div className="title">
                      2<sup>nd</sup>Annual Lecture | 2007
                    </div>
                    <h4 className="my-4">
                      Indian Business History: Fallacies of Interpretation
                    </h4>
                    <Nav.Link href="/annual-details/14" bsPrefix="detailsBtn">
                      DETAILS
                    </Nav.Link>
                    <Button
                      to="/"
                      className="ms-3 feedbackBtn"
                      //onClick={this.openDialog.bind(this, "ANNUAL LECTURE")}
                      onClick={this.onOpenDialogClick.bind(this, "ANNUAL LECTURE")}
                    >
                      Feedback
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-5 ">
                <div className="blogGrid">
                  <figure>
                    <img src={talk23} alt="Post 1" className="img-fluid"></img>
                  </figure>
                  <div className="blogsInfo pt-4">
                    <div className="title">
                      1<sup>st</sup> Annual Lecture | 2006
                    </div>
                    <h4 className="my-4">
                      Soap Wars: Indian Capitalism and Advertisements in a
                      Highly Competitive Business Environment, 1915-1950
                    </h4>
                    <Nav.Link href="/annual-details/15" bsPrefix="detailsBtn">
                      DETAILS
                    </Nav.Link>
                    <Button
                      to="/"
                      className="ms-3 feedbackBtn"
                      //onClick={this.openDialog.bind(this, "ANNUAL LECTURE")}
                      onClick={this.onOpenDialogClick.bind(this, "ANNUAL LECTURE")}
                    >
                      Feedback
                    </Button>
                  </div>
                </div>
                                    </div>
                                        </div>
                                </>
                            )}
                        </div>

                        {/*<div>
                            {this.state.masterClass3 && (
                                <>
                                    <p>Master Class Data will come here.</p>
                                </>
                            )}
                        </div>*/}

              {/* {this.state.open && (
                <FeedbackForm
                  type={this.state.type}
                  open={this.state.open}
                  closeDialog={this.closeDialog.bind(this)}
                />
              )} */}
             {this.state.openFeedback ? <FeedbackForm unmountMe={this.handleChildUnmount}  openFeedback={this.state.openFeedback} formFeedback={this.state.formFeedback} ></FeedbackForm>:null}
            
                    </div></div>
          
          {/* <PaginationItem
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={450}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
          ></PaginationItem> */}
          {/* <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <a class="page-Nav.Link" href="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>
              <li class="page-item">
                <a class="page-Nav.Link" href="#">
                  1
                </a>
              </li>
              <li class="page-item">
                <a class="page-Nav.Link" href="#">
                  2
                </a>
              </li>
              <li class="page-item">
                <a class="page-Nav.Link" href="#">
                  3
                </a>
              </li>
              <li class="page-item">
                <a class="page-Nav.Link" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav> */}
        </div>
        <Footer />
      </div>
    );
  }
}
export default Talks;
