import React, { Component } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { Form, FloatingLabel } from "react-bootstrap";
import resetIcon from "../../../assets/images/resetIcon.svg";
import closeIcon from "../../../assets/images/closeIcon.svg";
import PrivacyService from "../../../services/Privacy.service";
import FeedbackService from "../../../services/Feedback.service";

const privacy_link = process.env.REACT_APP_MY_LINK;
export class FeedbackForm extends Component {

  
// const FeedbackForm = (props) => {
//   const [max_char, setMax_char] = useState(1000); 


  constructor(props) {
    super(props);    
    this.state = {
      open:props.openFeedback, 
      formName:props.formFeedback,  
      starsContent: [],
      ratingContent: 0,
      hoveredContent: 0,
      selectedIconContent: '★',
      deselectedIconContent: '☆',
      starsDelivery: [],
      ratingDelivery: 0,
      hoveredDelivery: 0,
      selectedIconDelivery: '★',
      deselectedIconDelivery: '☆',
      chars_left: 0,
      max_char: 1000, 
      privacyurl:privacy_link,
       subscalerating:'',
       subdeliveryrating:'',
       subsuggestion: '',
       subemail: '',
       subtopic:'', 
       subchkPrivacyNotice:'',
       subchkaccurate:'', 
      fields: {
       subscalerating:'',
       subdeliveryrating:'',
       subsuggestion: '',
       subemail: '',
       subtopic:'',
       subchkPrivacyNotice:'',
       subchkaccurate:'',
       ratingContent:0,
       ratingDelivery:0      
      },
      errors: {
        subscalerating:'',
        subdeliveryrating:'',
        subsuggestion: '',
        subemail: '',
        subtopic:'',
        subchkPrivacyNotice:'',
        subchkaccurate:'',
        ratingContent:'',
        ratingDelivery:''        
      }
      
    };
    let outOfContent = props.outOfContent ? props.outOfContent : 5;

        for (var i = 0; i < outOfContent; i++) {
            this.state.starsContent.push(i + 1);
        }

        let outOfDelivery = props.outOfDelivery ? props.outOfDelivery : 5;

        for (var i = 0; i < outOfDelivery; i++) {
            this.state.starsDelivery.push(i + 1);
        }   
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeSuggestion = this.handleChangeSuggestion.bind(this);
    this.handleChangeTopic = this.handleChangeTopic.bind(this);    
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  
changeRatingContent(newRating) {
    this.setState({
        ratingContent: newRating
    });

    if (this.props.onChange)
        this.props.onChange(newRating);
}

hoverRatingContent(ratingContent) {
    this.setState({
        hoveredContent: ratingContent
    });
}


changeRatingDelivery(newRatingDel) {
  this.setState({
      ratingDelivery: newRatingDel
  });

  if (this.props.onChange)
      this.props.onChange(newRatingDel);
}

hoverRatingDelivery(ratingDelivery) {
  this.setState({
      hoveredDelivery: ratingDelivery
  });
}


handleOpen(e) {
  debugger;
  const formName = e
    this.setState({
      formName
    })
  this.setState({ open: true})
}

handleClose(){
  this.setState({ open: false })  
  this.props.unmountMe();
}

componentDidMount() {
  //this.getPrivacyDetails(); --commented by saditi
}

flipCheckbox()
{
  this.setState({subchkPrivacyNotice:!this.state.subchkPrivacyNotice})
}

flipCheckbox1()
{
  this.setState({subchkaccurate:!this.state.subchkaccurate})
}

handleChangeEmail(e) {
  this.setState({ subemail: e.target.value });
    this.setState({
      errors: {
        ...this.state.errors,
      },
      fields: {
        ...this.state.fields,
        [e.target.name]: e.target.value
      }
    });
  }

  handleChangeSuggestion(e) {
    this.setState({ subsuggestion: e.target.value });
      this.setState({
        errors: {
          ...this.state.errors,
        },
        fields: {
          ...this.state.fields,
          [e.target.name]: e.target.value
        }
      });
    }

handleChangeTopic(e) {
  this.setState({ subtopic: e.target.value });
    this.setState({
          errors: {
            ...this.state.errors,
          },
          fields: {
            ...this.state.fields,
            [e.target.name]: e.target.value
      }
  });
}

getPrivacyDetails()
{
  const req = {
    
    pg_company: "GNB",
    pg_PolicyName: "GodrejArchives",
  }
  PrivacyService.GetPrivacyPolicySrv(req).then(
    response => {
      if (response.data.model != null) {
        if (response.data.message == "200")
        {
          this.state.privacyurl=response.data.model.policyURL;
        }
        else {
          alert('Error while fetching data');
        }
      }
      else {
        alert('Error while fetching data');
      }
    },
    error => {
      alert('Error while fetching data');
    }
    
  );

} 

handleWordCount = (event) => {
    const charCount = event.target.value.length;
    const maxChar = this.state.max_char;
    const charLength = charCount;
    this.setState({ chars_left: charLength });
  };

btnReset = () => { 
  window.location.reload(false);
  this.setState({
    subsuggestion:'',
    subtopic:'',    
    subemail: '',
    ratingContent:'',
    ratingDelivery:''  

});
}

btnSaveFeedback = () => {  
  const { fields } = this.state;  
  if (!this.validateForm()) {
    return;
    }
  if (fields.subemail && fields.subsuggestion && fields.subtopic && fields.subchkPrivacyNotice && fields.subchkaccurate
    && fields.rating && fields.rating1) {
      const data = {
          subemail: fields.subemail,          
          subchkPrivacyNotice:fields.subchkPrivacyNotice,
          subchkaccurate:fields.subchkaccurate,
          subsuggestion:fields.subsuggestion,
          subtopic:fields.subtopic,
          ratingContent:fields.ratingContent,
          ratingDelivery:fields.ratingDelivery
      };

  }
  if (this.state.formName=="THURSDAY TALK")
  {
    //Commented by saditi
    //debugger;
  const req = {
    tffsuggestions : this.state.subsuggestion,
    tffsuggestedtopics:this.state.subtopic,
    tffemailid :this.state.subemail,
    tffpolicyconsent :this.state.subchkPrivacyNotice,
    tffvaliddataconsent :this.state.subchkaccurate,
    tffratingcontent :this.state.ratingContent,
    tffratingdelivery :this.state.ratingDelivery
  }
  FeedbackService.SaveThursdayFeedbackSrv(req).then(
    response => {
      if (response.data.model != null) {
        //debugger;
        if (response.data.model.status == "Success") {
         alert('Data Saved Successfully');
         window.location.reload(false);
          this.setState({
            subemail: '',
            
        });
        
      
        }
        else {
          alert('Error while submitting data');
          window.location.reload(false);
        }


      }
      else {
        alert('Error while submitting data');
        window.location.reload(false);
      }
    },
    error => {
      alert('Error while submitting data');
      window.location.reload(false);
    }
    
  );
  }
  else if(this.state.formName=="ANNUAL LECTURE")
  {
    //debugger;
    const req = {
      affsuggestions : this.state.subsuggestion,
      affsuggestedtopics:this.state.subtopic,
      affemailid :this.state.subemail,
      affpolicyconsent :this.state.subchkPrivacyNotice,
      affvaliddataconsent :this.state.subchkaccurate,
      affratingcontent :this.state.ratingContent,
      affratingdelivery :this.state.ratingDelivery
    }
    FeedbackService.SaveAnnualFeedbackSrv(req).then(
      response => {
        if (response.data.model != null) {
          debugger;
          if (response.data.model.status == "Success") {
            alert('Data Saved Successfully');
            window.location.reload(false);
            this.setState({
              subemail: '',
              
          });
          
        
          }
          else {
            alert('Error while submitting data');
            window.location.reload(false);
          }
  
  
        }
        else {
          alert('Error while submitting data');
          window.location.reload(false);
        }
      },
      error => {
        alert('Error while submitting data');
        window.location.reload(false);
      }
      
    );
  }
}

validateForm() {
  let fields = this.state.fields;
  let errors = {};
  let formIsValid = true;

  if (this.state.ratingContent ==0)
  { 
    formIsValid = false;
    errors["ratingContent"] = "*Please rate content.";
  }

  if (this.state.ratingDelivery ==0)
  { 
    formIsValid = false;
    errors["ratingDelivery"] = "*Please rate delivery.";
  }

  if (!fields["subemail"]) {
    formIsValid = false;
    errors["subemail"] = "*Please enter your email-ID.";
  }

  if (typeof fields["subemail"] !== "undefined") {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!fields["subemail"]) {
      formIsValid = false;
    errors["subemail"] = "*Please enter your email-ID.";
    }
    else {        
     if (!pattern.test(fields["subemail"])) {
       formIsValid = false;
       errors["subemail"] = "*Please enter valid email-ID.";
    }
  }
  }

  if (!fields["subsuggestion"]) {
    formIsValid = false;
    errors["subsuggestion"] = "*Please enter your suggestions";
  }

  if (!fields["subtopic"]) {
    formIsValid = false;
    errors["subtopic"] = "*Please enter your topic.";
  }

  if (!this.state.subchkPrivacyNotice){
    formIsValid = false;
    errors["subchkPrivacyNotice"] = "*Please select consent for privacy notice.";
  }

  if (!this.state.subchkaccurate){
    formIsValid = false;
    errors["subchkaccurate"] = "*Please select declaration for valid inforamtion provided by you.";
  }
 
  
  this.setState({
    errors: errors
  });
  return formIsValid;


}
 render() 
 {
  const { starsContent, ratingContent, hoveredContent, deselectedIconContent, selectedIconContent } = this.state;
  const { starsDelivery, ratingDelivery, hoveredDelivery, deselectedIconDelivery, selectedIconDelivery } = this.state;

  return (   
    
      <Dialog open={this.state.open} onEnter={console.log("Hey.")} >
        <div className="whatWeDoMain">
        <div className="header px-3 py-4">
          <div className="close"  onClick={this.handleClose}>
            <img src={closeIcon} alt="Close Icon"></img>
          </div>
          <h2 className="secHeader">FEEDBACK FORM</h2>
          <p className="px-3 m-0">All fields are mandatory</p>
        </div>

        <DialogContent>
          <div className="dialogInfo ">
            <div className="formBio ps-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="formHeader mt-4 mb-3">
                    Rate on scale of 1 to 5
                   <span> (1 being the lowest &amp; 5 being
                    the highest).</span>
                  </div>
                </div>
                        
                 <div className="col-md-6">                  
                 <div className="formGrid" >
                 Content  
                 <div className="formGrid" style={{ fontSize: '2em', color: "#FFCC00" }}>
                
                    {starsContent.map(star => {
                        return (
                            <span
                                value={this.state.ratingContent}
                                style={{ cursor: 'pointer' }}
                                onClick={() => { this.changeRatingContent(star); }}
                                onMouseEnter={() => { this.hoverRatingContent(star); }}
                                onMouseLeave={() => { this.hoverRatingContent(0); }}
                            >
                                {ratingContent < star ?
                                    hoveredContent < star ? deselectedIconContent : selectedIconContent
                                    :
                                    selectedIconContent
                                }
                            </span>
                        );
                    })}                   
                 </div>  
                 <span className="text-danger">{this.state.errors.ratingContent}</span>            
                 </div>
                 </div>
                <div className="col-md-6">
                  <div className="formGrid">
                  Delivery
                    <div className="formGrid" style={{ fontSize: '2em', color: "#FFCC00" }}>
                     
                      {starsDelivery.map(star => {
                        return (
                            <span
                                value={this.state.ratingDelivery}
                                style={{ cursor: 'pointer' }}
                                onClick={() => { this.changeRatingDelivery(star); }}
                                onMouseEnter={() => { this.hoverRatingDelivery(star); }}
                                onMouseLeave={() => { this.hoverRatingDelivery(0); }}
                            >
                                {ratingDelivery < star ?
                                    hoveredDelivery < star ? deselectedIconDelivery : selectedIconDelivery
                                    :
                                    selectedIconDelivery
                                }
                            </span>
                        );
                    })}
                    </div>
                    <span className="text-danger">{this.state.errors.ratingDelivery}</span> 
                  </div>
                 
                </div>
                <div className="col-md-12">
                  <div className="formHeader mt-4 mb-3">
                    Do you wish to make any other suggestions?
                  </div>
                </div>

                <div className="col-md-12 mb-3">
                  <div className="formGrid">
                    <div className="col-md-12">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Your Suggestions"                        
                      >
                        <Form.Control
                          type="text"
                          name="subsuggestion"
                          value={this.state.fields.subsuggestion} 
                          //onChange={this.handleChangeSuggestion}
                          onChange={e => { this.handleChangeSuggestion(e); this.handleWordCount(e) }} 
                          placeholder="Your Suggestions"
                          maxLength="1000"
                        />
                      </FloatingLabel>
                      <span className="text-danger">{this.state.errors.subsuggestion}</span>
                      <p className="ms-auto countingInfo">
                      {this.state.chars_left} / {this.state.max_char} Characters
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="formHeader mt-4 mb-3">
                  What other topics related to business history would you love
                    to hear about in our Thursday Talks?

                  </div>
                </div>
                <div className="col-md-12">
                  <div className="formGrid">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Your Suggested Topics"                      
                    >
                      <Form.Control
                        type="text"
                        name="subtopic"
                        value={this.state.fields.subtopic} 
                        onChange={this.handleChangeTopic}
                        placeholder="Your Suggested Topics" maxlength="500"
                      />
                    </FloatingLabel>
                    <span className="text-danger">{this.state.errors.subtopic}</span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="formHeader mt-4 mb-3">
                    Share your Email Id (if you would like Godrej Archives to
                    communicate to you about future talks and events).
                  </div>
                </div>
                <div className="formGrid">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Email ID"
                    
                  >
                    <Form.Control type="email" 
                      name="subemail"
                      value={this.state.fields.subemail} 
                      onChange={this.handleChangeEmail}
                    placeholder="Email ID" maxlength="50"/>
                  </FloatingLabel>
                  <span className="text-danger">{this.state.errors.subemail}</span>
                </div>
                
                 <div className="col-md-12 mb-1 mt-3">
                        <Form.Group                          
                          controlId="formBasicCheckbox"
                        >
                          <div class="row">
                          <div className="col-md-1 pt-0">
                          <Form.Check
                            type="checkbox"
                            id="subchkPrivacyNotice"
                            value={this.state.fields.subchkPrivacyNotice}
                            onChange={this.flipCheckbox.bind(this)}
                            className="pt-0"/>
                            </div>
                            <div className="col-md-11 p-0">
                            <label
                            class="form-check-label"
                            for="subchkPrivacyNotice"
                          >                            
                            I have read the <a href={this.state.privacyurl} target="_blank">Privacy Notice</a> and hereby provide my
                            consent to process the information for the purposes
                            defined in the notice.
                          </label>
                          </div>
                          </div>
                        </Form.Group>
                        <span className="text-danger">{this.state.errors.subchkPrivacyNotice}</span>
                      
                      </div>
                      <div className="col-md-12 mt-2">
                        <Form.Group                         
                          controlId="formBasicCheckboxx"
                        >
                          <div class="row">
                          <div className="col-md-1 pt-0">
                          <Form.Check
                            type="checkbox"
                            id="subchkaccurate"
                            value={this.state.fields.subchkaccurate}
                            onChange={this.flipCheckbox1.bind(this)} />
                           </div>
                            <div className="col-md-11 p-0">
                             <label
                            class="form-check-label"
                            for="subchkaccurate" >
                            I hereby declare that the information provided by me is accurate.
                          </label>
                          </div>
                          </div>
                          
                        </Form.Group>
                        <span className="text-danger">{this.state.errors.subchkaccurate}</span>
                      </div>
              </div>
                <div className="text-center actionBtn mt-4">
                  <button className="resetBtn" >
                    <img
                      src={resetIcon}
                      alt="Reset Icon"
                      className="me-2"
                      onClick={this.btnReset}
                    ></img>
                    Reset
                  </button>
                  <button className="submitBtn ms-2" onClick={this.btnSaveFeedback}>SUBMIT</button>
                </div>
               
            </div>
          </div>
        </DialogContent>
        </div>
      </Dialog>
    // </>
  );
};
}
                    
export default FeedbackForm;
