import React, { Component, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import missionIcon from "../../assets/images/who-we-are/missionIcon.svg";
import visionIcon from "../../assets/images/who-we-are/visionIcon.svg";
import recordSurvey from "../../assets/images/who-we-are/record-survey.png";
import oralHistory from "../../assets/images/who-we-are/oral-history.png";
import catalogue from "../../assets/images/who-we-are/catalogue.png";
import Conservation from "../../assets/images/who-we-are/Conservation.png";
import Digitisation from "../../assets/images/who-we-are/Digitisation.png";
import outReach from "../../assets/images/who-we-are/out-reach.png";
import team1 from "../../assets/images/meet-team/VrundaPathare.png";
import team2 from "../../assets/images/meet-team/AmrutaMarathe.png";
import team3 from "../../assets/images/meet-team/SudhaJadhav.png";
import team4 from "../../assets/images/meet-team/ShwetaJadhav.png";
import team5 from "../../assets/images/meet-team/SoumyaBhave.png";
import team6 from "../../assets/images/meet-team/KishorVaity.png";
import team7 from "../../assets/images/meet-team/ChandrakantJadhav.png";
import team8 from "../../assets/images/meet-team/MilindNimse.png";
import team9 from "../../assets/images/meet-team/TusharKansara.png";
import team10 from "../../assets/images/meet-team/PriyalChheda.png";
import closeButton from "../../assets/images/who-we-are/closeButton2.svg";

import "./WhoWeAre.scss";
import Header from "../../shared-components/Header/Header";
import Footer from "../../shared-components/Footer/Footer";
import { Box, Slide } from "@mui/material";
import { Button } from "react-bootstrap";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 140) : text}
      <span onClick={toggleReadMore}>
        <br />
        {isReadMore ? "Read more" : " Show less"}
      </span>
    </p>
  );
};

export class WhoWeAre extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article1: false,
      article2: false,
      article3: false,
      article4: false,
      article5: false,
      article6: false,
      article7: false,
      article8: false,
      article9: false,
      article10: false,
    };
  }
  handleButtonStateChange = (toggleKey) => {
    const stateCopy = { ...this.state };
    const nextValue = !stateCopy[toggleKey];
    Object.keys(stateCopy).forEach((key) => (stateCopy[key] = false));
    stateCopy[toggleKey] = nextValue;
    this.setState(stateCopy);
  };

  render() {
    return (
      <div className="whoweare">
        <Header />
        <div className="innerBanner whoWeareBanner text-center">
          <div className="bannerInfo">
            <h1>Who we are</h1>
            <p>
              Custodians of Godrej history, we belong to the tribe of knowledge
              seekers &amp; keepers
            </p>
          </div>
        </div>
        <div className="introduction pt-90">
          <div className="container p-4 pb-0 p-lg-0">
            <div className="row">
              <div className="col-md-6">
                <h2 className="secHeader mb-4 mt-2">Introduction</h2>
                <p>
                  Located in Mumbai, Godrej Archives is the Godrej group's
                  business archive. The Archives collects, preserves and manages
                  records covering 125 years of the company's history. The idea
                  was mooted in 1997, the group's centenary year, by Mr. Sohrab
                  Godrej, former chairman of the Godrej group, who had over the
                  years, taken up the task of collecting relevant documents and
                  photographs. Since then, Archives has been recording the
                  history of Godrej – its products, plants and people – and
                  making it available for research.
                </p>
                <p>
                  The Archives helps the organisation identify records that are
                  of historical values. Godrej Archives works in collaboration
                  with preservation and conservation experts in the country to
                  conserve records using state-of-the-art standards and
                  practices.
                </p>
                <p>
                  Explore this website to find out what treasures we have,
                  reflecting the history of Godrej and its contributions to the
                  history of Industrial India.
                </p>
              </div>
              <div className="col-md-6">
                <div className="introductionInfo position-relative">
                  <h2 className="title">
                    <span class="vision">Vision</span>
                  </h2>
                  <ul>
                    <li>
                      To create an institute preserving Godrej’s history –
                      create awareness about business history and archives
                    </li>
                  </ul>

                  <h2 className="title">
                     <span class="vision">Mission</span>
                  </h2>
                  <ul>
                    <li>
                      To collect, document, preserve, interpret and communicate
                      to people the history of Godrej
                    </li>
                    <li>
                      To create resources in the form of oral history recordings
                    </li>
                    <li>
                      To make available sources pertaining to Godrej's past to
                      researchers, students and the business world
                    </li>
                    <li>
                      To promote awareness of Business History and Archives
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="functions py-90 lightOrange">
          <div className="container p-4 p-lg-0">
            <h2 className="secHeader mb-4">Functions</h2>
            <div className="row">
              <div className="col-md-4 mb-4">
                <div className="functionGrid">
                  <figure>
                    <img
                      src={recordSurvey}
                      alt="Functions"
                      className="img-fluid"
                    ></img>{" "}
                  </figure>
                  <div className="functionBio">
                    {/* <div className="number">01</div> */}
                    <h4 className="my-4">Record Survey</h4>
                    <p>
                      <ReadMore>
                      The Godrej Archives team surveys any material donated / found / about to be discarded related to the different business units of Godrej and selects historically significant material to be transferred to the Archives. Since the last few years, the Archives conducts such surveys proactively by contacting various business units on a monthly basis enquiring about past as well as current records. These surveys act like preliminary examination of the potential archival material which gives the Archives' staff a general idea regarding its storage requirement and conservation & digitization priority status.
                      </ReadMore>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="functionGrid">
                  <figure>
                    <img
                      src={oralHistory}
                      alt="Functions"
                      className="img-fluid"
                    ></img>{" "}
                  </figure>

                  <div className="functionBio">
                    {/* <div className="number">02</div> */}
                    <h4 className="my-4">Oral History Project</h4>
                    <p>
                      <ReadMore>
                        Until now the Godrej Archives has documented more than
                        350 oral histories including those of staff employees,
                        factory workers, security &amp; canteen personnel,
                        Udayachal school staff, Godrej dealers &amp; agents
                        across the country, Branch officials, etc. It begins
                        with contacting the oral history interview candidate
                        (anyone who has completed 25 years as employee) and
                        sending a preliminary questionnaire to get a general
                        idea about the candidate's life and career. Followed by
                        this is the actual interview with audio recording only.
                        Post processing of this involves transcribing and
                        copyediting the interviews and getting an approved copy
                        from the interviewee. The final versions are made
                        available for research through which narratives of
                        different aspects of history of Godrej, the country, its
                        economy &amp; businesses, etc. are also pieced together.
                      </ReadMore>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="functionGrid">
                  <figure>
                    <img
                      src={catalogue}
                      alt="Functions"
                      className="img-fluid"
                    ></img>{" "}
                  </figure>

                  <div className="functionBio">
                    {/* <div className="number">03</div> */}
                    <h4 className="my-4">Cataloguing</h4>
                    <p>
                      <ReadMore>
                        The Cataloguing Unit of the Godrej Archives creates
                        reference media / finding aids for collections we
                        receive. What earlier used to be managed with Excel
                        sheets is now accomplished via a Digital Asset
                        Management Software wherein details covering more than
                        20 meta data fields such as title, time period, number
                        of pages &amp; sheets, type of material, provenance,
                        copyright, digitization status, conservation status,
                        keywords and even a small summary of the record are
                        documented. The system offers simple as well as
                        combination search options, thus ensuring ease of access
                        to us and the researchers. Further, the traditional aids
                        such as lists of records, descriptive catalogues and
                        collection notes are also available.
                      </ReadMore>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="functionGrid">
                  <figure>
                    <img
                      src={Conservation}
                      alt="Functions"
                      className="img-fluid"
                    ></img>{" "}
                  </figure>

                  <div className="functionBio">
                    {/* <div className="number">04</div> */}
                    <h4 className="my-4">Conservation</h4>
                    <p>
                      <ReadMore>
                        Once records are transferred to the archives, they
                        undergo a basic cleaning process before they are stored
                        permanently in our main repositories. Further, the
                        Conservation team takes up the task of protecting and
                        preserving a wide range of archival material (paper
                        documents, photographs, objects of metal, stone, wood,
                        etc.) on a priority basis. Damaged records are repaired
                        and restored using permissible chemical solutions and
                        time-tested conserving techniques and treatment
                        documentation is maintained. However, we strongly
                        emphasize on preventive conservation and therefore all
                        the collection is stored in archival quality boxes and
                        containers shelved in clean, humidity-controlled,
                        air-conditioned repositories.
                      </ReadMore>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="functionGrid">
                  <figure>
                    <img
                      src={Digitisation}
                      alt="Functions"
                      className="img-fluid"
                    ></img>{" "}
                  </figure>

                  <div className="functionBio">
                    {/* <div className="number">05</div> */}
                    <h4 className="my-4">Digitization</h4>
                    <p>
                      <ReadMore>
                        Our in-house Digitization Lab uses state-of-the-art
                        scanners and Eizo monitors to facilitate accurate
                        digitization of archival material. The material is
                        digitized in several formats (thumbnail image, jpg,
                        tiff) suitable for a variety of purposes. Recently, a
                        camera rig (not yet fully functional) developed by our
                        own Godrej Tooling was also installed. It will enable
                        digitzation of oversize documents that cannot fit into
                        conventional scanners.
                      </ReadMore>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="functionGrid">
                  <figure>
                    <img
                      src={outReach}
                      alt="Functions"
                      className="img-fluid"
                    ></img>{" "}
                  </figure>

                  <div className="functionBio">
                    {/* <div className="number">06</div> */}
                    <h4 className="my-4">Outreach</h4>
                    <p>
                      <ReadMore>
                        Outreach activities of Godrej Archives include various
                        social media presence, exhibitions, talks and lectures,
                        media articles, online &amp; offline publications,
                        induction sessions of employees, visits by external
                        people, etc. These are aimed at raising awareness about
                        the Godrej Archives and about archiving &amp; business
                        history in general. Our latest outreach initiative has
                        been a series of bi-monthly online talks titled
                        #ThursdayTalk. Six talks have been conducted so far and
                        are available for viewing on our YouTube and this
                        website.
                      </ReadMore>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="meetTeam py-90">
          <div className="container p-4 p-lg-0">
            <h2 className="secHeader mb-5">Meet the team</h2>
            <div className="row position-relative">
              <Slide
                direction="right"
                in={this.state.article1}
                mountOnEnter
                unmountOnExit
              >
                <Box class="boxStyle">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4">
                        <figure className="boxIndent">
                          <img src={team1} alt="team" className="img-fluid"></img>
                          <div class="nameStyle2">
                      <h4>Vrunda Pathare</h4>
                      <hr width="200px"></hr>
                      <span>
                        Head, Godrej Archives
                      </span>
                    </div>
                        </figure>
                       
                      </div>
                      <div className="col-md-7 scrollHeight">
                        <PerfectScrollbar>
                          <p>
                            Vrunda brings her research and archiving experience
                            combined with her academic qualification in history
                            and records management to the efficient working of
                            Godrej Archives. She was instrumental in setting up
                            the Godrej Archives and establishing archival
                            processes as an integral part of the organisational
                            activity. Vrunda has been trained in the Records
                            Management from the National Archives of India and
                            holds a postgraduate degree in History from
                            University of Mumbai. She started her career as a
                            Research Assistant to Sadashiv Gorakshkar (Former
                            Director, CSMVS, Mumbai; Past President,
                            Commonwealth Association of Museums) on following
                            two projects: <br></br> 1. ‘Rajbhavans in Maharashtra’ – A
                            book on Comprehensive History of Rajbhavans in
                            Maharashtra published in the year 2002 and 2. ONGC
                            (Oil and Natural Gas Corporation Ltd.) Museum
                            Project. Later, she worked as an Assistant Archivist
                            at TIFR Archives, Mumbai. She is currently the Secretary of Section on Business Archives (SBA) of International Council on Archives and the President of the Oral History Association of India (OHAI). She is serving
                            as a member of the Steering Committee for the Dr.
                            Avabai Wadia and Dr. Bomanji Khurshedji Wadia
                            Archives for Women. In the past, she had served as a
                            member of the Advisory Board of Rajbhavan Archives
                            of Government of Maharashtra (India) and also as a
                            Member of the IIMA Archive Committee. She has served
                            on the Board of Studies for the Department of
                            History at various colleges in Mumbai. Vrunda has
                            authored a monograph titled Arthur Bedford Orlebar,
                            Founders and Guardians of The Asiatic Society of
                            Mumbai (ISBN 978-93-85509-36-0) published in 2018 as
                            a part of the Asiatic Society Monograph Series. She
                            recently contributed an essay giving overview of
                            business archives in India to the 3rd edition of the
                            ICA SBA publication - 'Business Archives in
                            International Comparison' published in 2021. She has
                            also been a resource person in many national
                            workshops on archives and has presented papers in
                            national and international conferences.
                          </p>
                        </PerfectScrollbar>
                      </div>
                      <div className="col-md-1">
                        <Button
                          onClick={() => this.setState({ article1: false })} 
                          className="mobileTop"
                        >
                         <figure>
                          <img src={closeButton} alt="team"></img>
                        </figure>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Box>
              </Slide>
              <Slide
                direction="right"
                in={this.state.article2}
                mountOnEnter
                unmountOnExit
              >
                <Box class="boxStyle">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4">
                  
                        <figure className="boxIndent">
                          <img src={team2} alt="team" className="img-fluid"></img>
                          <div class="nameStyle2">
                          <h4>Amruta Marathe</h4>
                      <hr width="200px"></hr>
                      <span>
                        Head, Digitization, Conservation &amp; Cataloguing Unit
                      </span>
                    </div>
                        </figure>
                      </div>
                      <div className="col-md-7 scrollHeight">
                        <PerfectScrollbar>
                          <p>
                            Amruta Marathe heads the very-much-inter-linked
                            activities of Digitization, Conservation &amp;
                            Cataloguing at the Godrej Archives. As our digital
                            archivist, Amruta was involved in conceptualizing
                            and executing the process flow for the Digital Asset
                            Management Software (DAMS) used at the Archives and
                            developing the user interface for retrieval systems.
                            Her master’s degree in Library &amp; Information
                            Science and a Post Graduate Diploma in Museology and
                            Conservation, plus an extensive experience of 7.5
                            years of working at a print media archive comes
                            handy in managing the digital archives, DAMS,
                            digitization activity, information services and
                            planning conservation initiatives. She has
                            participated in national as well as international
                            conferences and workshops on information management
                            and setting up virtual and digital libraries.
                          </p>
                        </PerfectScrollbar>
                      </div>
                      <div className="col-md-1">
                        <Button
                          onClick={() => this.setState({ article2: false })}
                          className="mobileTop"
                        >
                          <figure>
                          <img src={closeButton} alt="team"></img>
                        </figure>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Box>
              </Slide>
              <Slide
                direction="right"
                in={this.state.article3}
                mountOnEnter
                unmountOnExit
              >
                <Box class="boxStyle">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4">
                        
                        <figure className="boxIndent">
                          <img src={team3} alt="team" className="img-fluid"></img>
                          <div class="nameStyle2">
                          <h4>Sudha Jadhav</h4>
                    <hr width="200px"></hr>
                    <span>
                      Head, Collection Management &amp; Research Services
                    </span>
                    </div>
                        </figure>
                      </div>
                      <div className="col-md-7 scrollHeight">
                        <PerfectScrollbar>
                          <p>
                          Sudha Jadhav, who's been with the Godrej Archives 
                          since 2010, manages our extensive &amp; diverse collections 
                          and her in-depth knowledge of the finding aids provide for 
                          excellent research services. Being a post-graduate in History and a
                            certificate holder in Archives Management, Sudha is
                            adept at identifying, acquiring and accessioning
                            historically relevant records of the organization
                            via various avenues such as surveys, collection
                            drives, etc. and also assists in developing the oral
                            history collection at Godrej Archives. She is also
                            responsible for planning and arranging proper
                            storage for archival material. As a result, she
                            works in close tandem with other units of the
                            archives such as Cataloguing, Digitization,
                            Conservation, and Outreach. She handles research
                            queries and assists researchers and visitors to the
                            archives by disseminating relevant information as
                            per their requests and also manages the day-to-day
                            administration of the archives' office.
                          </p>
                        </PerfectScrollbar>
                      </div>
                      <div className="col-md-1">
                        <Button
                          onClick={() => this.setState({ article3: false })}
                          className="mobileTop"
                        >
                           <figure>
                          <img src={closeButton} alt="team"></img>
                        </figure>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Box>
              </Slide>
              <Slide
                direction="right"
                in={this.state.article4}
                mountOnEnter
                unmountOnExit
              >
                <Box class="boxStyle">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4">
                      <figure className="boxIndent">
                          <img src={team4} alt="team" className="img-fluid"></img>
                          <div class="nameStyle2">
                          <h4>Shweta Jadhav</h4>
                    <hr width="200px"></hr>
                    <span>Head, Outreach &amp; Content Creation</span>
                    </div>
                        </figure>
                      </div>
                      <div className="col-md-7 scrollHeight">
                        <PerfectScrollbar>
                          <p>
                            Qualified in history, archives management and
                            education, Shweta Jadhav, manages the Outreach
                            Programme, and assists in planning and executing
                            various initiatives of the archives including
                            exhibitions, annual lecture series, workshops,
                            conferences and other events &amp; activities to
                            develop a reciprocal relationship between both the
                            archives and its audience. She handles induction
                            sessions of employees as well as guided tours for
                            visitors. She designs programmes for school and
                            college students to create a meaningful experience
                            for the students. She creates content for the
                            website and the Godrej Archives page on Intranet,
                            manages the social media accounts and contributes to
                            CHANGE, the inhouse magazine of Godrej and
                            occasionally to external publications. Her extensive
                            work experience which involved working with
                            startups, business archives, corporate CSR wings and
                            teaching stints with schools and colleges enables
                            her to engage effectively with our audience and
                            users of archives to spread awareness about Godrej
                            Archives and the field of business history. She was
                            awarded the First Prize as 'Young Researcher Best
                            Paper' at the 13th Dr. Mani Kamerkar National
                            Seminar titled 'Complex Narratives: Exploring Indian
                            Business History from Ancient to Modern Times' held
                            at H R College of Commerce &amp; Economics, Mumbai
                            in September 2019.
                          </p>
                        </PerfectScrollbar>
                      </div>
                      <div className="col-md-1">
                        <Button
                          onClick={() => this.setState({ article4: false })}
                          className="mobileTop"
                        >
                           <figure>
                          <img src={closeButton} alt="team"></img>
                        </figure>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Box>
              </Slide>
                            
              
              { (
                <div className="col-md-3 mb-4">
                  <div className="teamGrid">
                    <figure>
                      <img
                        src={team1}
                        alt="team"                        
                        style={{ height: "350px" }}
                      ></img>
                    </figure>

                    <div class="nameStyle" onClick={() =>
                          this.handleButtonStateChange("article1")
                        }>
                      <h4>Vrunda Pathare</h4>
                      <hr width="200px"></hr>
                      <span>
                      Head, Godrej Archives
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {(
                <div className="col-md-3 mb-4">
                  <div className="teamGrid">
                    <figure>
                      <img
                        src={team2}
                        alt="team"                        
                        style={{ height: "350px" }}
                      ></img>
                    </figure>

                    <div class="nameStyle" onClick={() => this.handleButtonStateChange("article2")}>
                      <h4>Amruta Marathe</h4>
                      <hr width="200px"></hr>
                      <span>
                        Head, Digitization, Conservation &amp; Cataloguing Unit
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {(
                <div className="col-md-3 mb-4">
                  <div className="teamGrid">
                    <figure>
                      <img
                        src={team3}
                        alt="team"
                        
                        style={{ height: "350px" }}
                      ></img>
                    </figure>
                  
                  <div class="nameStyle" onClick={() => this.handleButtonStateChange("article3")}>
                    <h4>Sudha Jadhav</h4>
                    <hr width="200px"></hr>
                    <span>
                      Head, Collection Management &amp; Research Services
                    </span>
                  </div>
                  </div>
                </div>
              )}
              {(
                <div className="col-md-3 mb-4">
                  <div className="teamGrid">
                    <figure>
                      <img
                        src={team4}
                        alt="team4"                        
                        style={{ height: "350px" }}
                      ></img>
                    </figure>
                  
                  <div class="nameStyle" onClick={() => this.handleButtonStateChange("article4")}>
                    <h4>Shweta Jadhav</h4>
                    <hr width="200px"></hr>
                    <span>Head, Outreach &amp; Content Creation</span>
                  </div>
                  </div>
                </div>
              )}
              </div>

              <div className="row position-relative">
              <Slide
                direction="right"
                in={this.state.article5}
                mountOnEnter
                unmountOnExit
              >
                <Box class="boxStyle">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4">
                      <figure className="boxIndent">
                          <img src={team5} alt="team" className="img-fluid"></img>
                          <div class="nameStyle2">
                          <h4>Soumya Bhave</h4>
                    <hr width="200px"></hr>
                    <span>Oral History Team</span>
                    </div>
                        </figure>
                      </div>
                      <div className="col-md-7 scrollHeight">
                        <PerfectScrollbar>
                          <p>
                            Soumya's first interaction with Godrej Archives
                            dates back to 2019 when she interned with the
                            institution as a student. She is currently employed
                            as a Research Associate after completing her
                            Master of Arts in Public History from Centre for Public
                            History, Srishti Manipal Institute of Art, Design
                            and Technology, Bengaluru. Her training in the
                            fields of Public History, Oral History and Heritage
                            Interpretation comes handy as she manages our oral
                            history processing unit with confidence. As a young
                            researcher, Soumya attempts to unearth intriguing
                            stories, oral histories, essential historical
                            documents from the archive that enrich the
                            understanding of the long, glorious legacy of Godrej.
                             In her young yet budding career, she has
                            engaged with a range of outreach projects such as
                            the Godrej Archives' exhibition 'On the Move:
                            Memories of Movement and Migration through the
                            Navganis' of Phadalewadi (June 2019) and also our
                            first online exhibition ‘Godrej MemoryNama’ (June
                            2021). She regularly assists in the content creation
                            avenue of Godrej Archives as well. Her interest in
                            archiving has inspired her to build her own family
                            culinary archive under the mentorship of Ishita Shah
                            of Curating for Culture. Through her research
                            practice, Soumya aspires to pivot Godrej Archives'
                            academic presence in the realm of business history.
                          </p>
                        </PerfectScrollbar>
                      </div>
                      <div className="col-md-1">
                        <Button
                          onClick={() => this.setState({ article5: false })}
                          className="mobileTop"
                        >
                          <figure>
                          <img src={closeButton} alt="team"></img>
                        </figure>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Box>
              </Slide>
              <Slide
                direction="right"
                in={this.state.article6}
                mountOnEnter
                unmountOnExit
              >
                <Box class="boxStyle">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4">
                      <figure className="boxIndent">
                          <img src={team6} alt="team" className="img-fluid"></img>
                          <div class="nameStyle2">
                          <h4>Kishor Vaity</h4>
                    <hr width="200px"></hr>
                    <span>Digitization Team</span>
                    </div>
                        </figure>
                      </div>
                      <div className="col-md-7 scrollHeight">
                        <PerfectScrollbar>
                          <p>
                            Kishor Vaity has been working as a Digitization
                            Associate with the Godrej Archives since 2016.
                            Kishor handles scanning and photography of archival
                            collection at the archives. Prior to working at the
                            Godrej Archives, Kishor worked as Digital Archive
                            Manager at the Osian’s Connoisseurs of Art Pvt. Ltd.
                            for more than ten years where he was tasked with
                            photography and scanning of artworks and antique
                            objects. He has also attended the ‘Film Preservation
                            &amp; Restoration’ workshop held by the Film
                            Heritage Foundation in 2018 in Kolkata to further
                            hone his skills of film digitization.
                          </p>
                        </PerfectScrollbar>
                      </div>
                      <div className="col-md-1">
                        <Button
                          onClick={() => this.setState({ article6: false })}
                          className="mobileTop"
                        >
                          <figure>
                          <img src={closeButton} alt="team"></img>
                        </figure>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Box>
              </Slide>
              <Slide
                direction="right"
                in={this.state.article7}
                mountOnEnter
                unmountOnExit
              >
                <Box class="boxStyle">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4">
                      <figure className="boxIndent">
                          <img src={team7} alt="team" className="img-fluid"></img>
                          <div class="nameStyle2">
                          <h4>Chandrakant Jadhav</h4>
                    <hr width="200px"></hr>
                    <span>Digitization Team</span>
                    </div>
                        </figure>
                      </div>
                      <div className="col-md-7 scrollHeight">
                        <PerfectScrollbar>
                          <p>
                          Chandrakant Jadhav, our Digitization Associate, currently 
                          handles the responsibility of scanning films and editing 
                          images of archival records.  His years of experience in
                            imaging, scanning and digitization owing to his long
                            stint at Mazda Imaging Pvt. Ltd. and then in the
                            Digital Department at Osian’s Auction House, has
                            been a valuable asset for the digitisation team at
                            the archives. Earlier, he had also handled designing
                            and page layout at the Daily Shipping Newspaper. In
                            his last digitisation project - digitisation of
                            ‘Birds of Prey’ collection of the famed
                            ornithologist Rishad Naoroji, he ably executed image
                            editing of positive and negatives of photographs
                            &amp; scanning of 35 mm to 120 mm film reels, for
                            over a decade. Chandrakant holds a B.A. degree from
                            the Shivaji University, Kolhapur and has several
                            certifications in the Adobe Creative Cloud including
                            Adobe Photoshop, Adobe Illustrator, etc. He
                            continues to upgrade his knowledge and stay abreast
                            of the latest developments in the field by attending
                            related workshops.
                          </p>
                        </PerfectScrollbar>
                      </div>
                      <div className="col-md-1">
                        <Button
                          onClick={() => this.setState({ article7: false })}
                          className="mobileTop"
                        >
                           <figure>
                          <img src={closeButton} alt="team"></img>
                        </figure>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Box>
              </Slide>
              <Slide
                direction="right"
                in={this.state.article8}
                mountOnEnter
                unmountOnExit
              >
                <Box class="boxStyle">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4">
                      <figure className="boxIndent">
                          <img src={team8} alt="team" className="img-fluid"></img>
                          <div class="nameStyle2">
                          <h4>Milind Nimse</h4>
                    <hr width="200px"></hr>
                    <span>Cataloguing Team</span>
                    </div>
                        </figure>
                      </div>
                      <div className="col-md-7 scrollHeight">
                        <PerfectScrollbar>
                          <p>
                            Milind Nimse recently joined our Cataloguing Unit in
                            2020 and is responsible for cataloguing of physical
                            records, documentation of born digital records and
                            conducting condition audits of digitized material.
                            Throughout the last decade, he was working with
                            Rishad Naoroji, famous Indian ornithologist, on his
                            ‘Birds of Prey’ collection where he handled
                            cataloguing of photographs, their physical and
                            digital storage, documentation of research notes,
                            etc. Qualified in MCVC Electronics, with skills of
                            working with various software and apps, and
                            extensive on-the-job training in office
                            administration, documentation and digital management
                            acquired at his past workplaces such as Apeejay
                            School, Aon Global Ltd. and Osian’s Auction House,
                            Milind makes a meaningful contribution to the
                            creation of finding aids and reference media to
                            facilitate research services at Godrej Archives.
                          </p>
                        </PerfectScrollbar>
                      </div>
                      <div className="col-md-1">
                        <Button
                          onClick={() => this.setState({ article8: false })}
                          className="mobileTop"
                        >
                           <figure>
                          <img src={closeButton} alt="team"></img>
                        </figure>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Box>
              </Slide>
             
              {(
                <div className="col-md-3 mb-4">
                  <div className="teamGrid">
                    <figure>
                      <img
                        src={team5}
                        alt="team"                        
                        style={{ height: "350px" }}
                      ></img>{" "}
                    </figure>
                  
                  <div class="nameStyle" onClick={() => this.handleButtonStateChange("article5")}>
                    <h4>Soumya Bhave</h4>
                    <hr width="200px"></hr>
                    <span>Oral History Team</span>
                  </div>
                  </div>
                </div>
              )}
              {(
                <div className="col-md-3 mb-4">
                  <div className="teamGrid">
                    <figure>
                      <img
                        src={team6}
                        alt="team"                        
                        style={{ height: "350px" }}
                      ></img>
                    </figure>
                  
                  <div class="nameStyle" onClick={() => this.handleButtonStateChange("article6")}>
                    <h4>Kishor Vaity</h4>
                    <hr width="200px"></hr>
                    <span>Digitization Team</span>
                  </div>
                  </div>
                </div>
              )}
              {(
                <div className="col-md-3 mb-4">
                  <div className="teamGrid">
                    <figure>
                      <img
                        src={team7}
                        alt="team"                        
                        style={{ height: "350px" }}
                      ></img>{" "}
                    </figure>
                  
                  <div class="nameStyle" onClick={() => this.handleButtonStateChange("article7")}>
                    <h4>Chandrakant Jadhav</h4>
                    <hr width="200px"></hr>
                    <span>Digitization Team</span>
                  </div>
                </div>
                </div>
              )}
              { (
                <div className="col-md-3 mb-4">
                  <div className="teamGrid">
                    <figure>
                      <img
                        src={team8}
                        alt="team"                        
                        style={{ height: "350px" }}
                      ></img>{" "}
                    </figure>
                  
                  <div class="nameStyle" onClick={() => this.handleButtonStateChange("article8")}>
                    <h4>Milind Nimse</h4>
                    <hr width="200px"></hr>
                    <span>Cataloguing Team</span>
                  </div>
                  </div>                
                </div>
              )}
 </div>
              <div className="row position-relative">
              <Slide
                direction="right"
                in={this.state.article9}
                mountOnEnter
                unmountOnExit
              >
                <Box class="boxStyle">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4">
                      <figure className="boxIndent">
                          <img src={team9} alt="team" className="img-fluid"></img>
                          <div class="nameStyle2">
                          <h4>Tushar Kansara</h4>
                    <hr width="200px"></hr>
                    <span>Conservation Team</span>
                    </div>
                        </figure>
                      </div>
                      <div className="col-md-7 scrollHeight">
                        <PerfectScrollbar>
                          <p>
                            Tushar Kansara is working as Chief Conservator at
                            the Godrej Archives since 2017. With a solid
                            experience of over 15 years, innovation and
                            improvisation are his forte as he not only conserves
                            artifacts and records in our collection but also
                            creates customized storage from acid-free material.
                            He credits his expertise to the immense on-the-job
                            training he had as a youngster along with his
                            father, a private contractor who worked extensively
                            on the interior &amp; exterior wooden repair and
                            conservation work of diverse objects in many
                            prominent buildings of Mumbai such as the Taj Hotel,
                            the Army &amp; Navy Building, The Royal Yacht Club,
                            Rhythm House, etc. Later, Tushar continued this
                            legacy as contracts of public projects as well as
                            orders from private individuals came his way. Before
                            joining Godrej, he has worked with leading
                            conservators in India and completed several
                            conservation projects, including an impressive stint
                            as team leader for the conservation project at the
                            Mumbai International Airport in 2012-13. After he
                            joined the Godrej Archives, he even completed his
                            formal training in conservation from INTACH,
                            Bhubaneshwar.
                          </p>
                        </PerfectScrollbar>
                      </div>
                      <div className="col-md-1">
                        <Button
                          onClick={() => this.setState({ article9: false })}
                          className="mobileTop"
                        >
                           <figure>
                          <img src={closeButton} alt="team"></img>
                        </figure>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Box>
              </Slide>
              <Slide
                direction="right"
                in={this.state.article10}
                mountOnEnter
                unmountOnExit
              >
                <Box class="boxStyle">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4">
                      <figure className="boxIndent">
                          <img src={team10} alt="team" className="img-fluid"></img>
                          <div class="nameStyle2">
                          <h4>Priyal Chheda</h4>
                    <hr width="200px"></hr>
                    <span>Conservation Team</span>
                    </div>
                        </figure>
                      </div>
                      <div className="col-md-7 scrollHeight">
                        <PerfectScrollbar>
                          <p>
                            Priyal Chheda joined the Conservation Unit of the
                            Godrej Archives in 2019 as Conservation Associate,
                            after her brief internship with us during her
                            post-graduation in History from SNDT University,
                            Mumbai. She also holds a Diploma in ‘Built Heritage
                            Studies and Conservation’ from CSMVS, Mumbai and is
                            currently pursuing another in ‘Archiving &amp;
                            Museology’ from Somaiya College, Mumbai. All this
                            enables her to bring to the table the latest tools
                            &amp; tricks of the trade.
                          </p>
                        </PerfectScrollbar>
                      </div>
                      <div className="col-md-1">
                        <Button
                          onClick={() => this.setState({ article10: false })}
                          className="mobileTop"
                        >
                           <figure>
                          <img src={closeButton} alt="team"></img>
                        </figure>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Box>
              </Slide>

              {(
                <div className="col-md-3 mb-4">
                  <div className="teamGrid">
                    <figure>
                      <img
                        src={team9}
                        alt="team"                        
                        style={{ height: "350px" }}
                      ></img>{" "}
                    </figure>
                  
                  <div class="nameStyle" onClick={() => this.handleButtonStateChange("article9")}>
                    <h4>Tushar Kansara</h4>
                    <hr width="200px"></hr>
                    <span>Conservation Team</span>
                  </div>
                </div>
                </div>
              )}

              {(
                <div className="col-md-3 mb-4">
                  <div className="teamGrid">
                    <figure>
                      <img
                        src={team10}
                        alt="team"
                        
                        style={{ height: "350px" }}
                      ></img>
                    </figure>
                  
                  <div class="nameStyle"  onClick={() => this.handleButtonStateChange("article10")}>
                    <h4>Priyal Chheda</h4>
                    <hr width="200px"></hr>
                    <span>Conservation Team</span>
                  </div>
                  </div>
                </div>
              )}
            </div>

            {/* <div className="col-md-12 text-center">
              <button></button>
            </div> */}
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
export default WhoWeAre;
