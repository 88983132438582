import React, { Component } from "react";
import Header from "../../shared-components/Header/Header";
import "./Home.scss";
import { HashLink, NavHashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import Logo from "../../assets/images/whiteLogo.svg";
import whoWeAre from "../../assets/images/Who-We-Are.png";
import Publications from "../../assets/images/publications.png";
import PublicationsMob from "../../assets/images/publicationsMobile.png";
import calenderIcon from "../../assets/images/calenderIcon.svg";
import bookIcon from "../../assets/images/bookIcon.svg";
import OwlCarousel from "react-owl-carousel2";
import $ from "jquery";

import archiveThumbJULY24 from "../../assets/images/archives/DOM-JULY-2024.png";
import archiveThumbJUNE24 from "../../assets/images/archives/DOM-JUNE-2024.png";
import archiveThumbMAY24 from "../../assets/images/archives/DOM-MAY-2024.png";
import archiveThumbAPR24 from "../../assets/images/archives/DOM-APR-2024.png";
import archiveThumbMAR24 from "../../assets/images/archives/DOM-MAR-2024.png";
import archiveThumbFEB24 from "../../assets/images/archives/DOM-FEB-2024.png";
import archiveThumbJAN24 from "../../assets/images/archives/DOM-JAN-2024.png";
import archiveThumbDEC23 from "../../assets/images/archives/DOM-DEC-2023.png";
import archiveThumbNOV23 from "../../assets/images/archives/DOM-NOV-2023.png";
import archiveThumbOct23 from "../../assets/images/archives/DOM-OCT-2023.png";
import archiveThumbSept23 from "../../assets/images/archives/DOM-SEPT-2023.png";
import archiveThumbAug23 from "../../assets/images/archives/DOM-Aug2023.png";
import archiveThumbJuly23 from "../../assets/images/archives/DOM-July2023.png";
import archiveThumbJune23 from "../../assets/images/archives/DOM-June2023.png";

import WOW from "wowjs";
import scrollArrow from "../../assets/images/scrollDown.svg";

import latestTalksbanner2 from "../../assets/images/latest-talk/Businessinthetime.png";
import latestTalksbanner3 from "../../assets/images/latest-talk/Power_Privilege.png";
import latestTalksbanner4 from "../../assets/images/latest-talk/TimberTradeTrajectory.png";
import latestTalksbanner5 from "../../assets/images/latest-talk/BlueJeansTransformation.png";
import latestTalksbanner6 from "../../assets/images/latest-talk/MadMenOfBombay.png";
import latestTalksbanner7 from "../../assets/images/latest-talk/TheGlobalMerchants.png";
import latestTalksbanner8 from "../../assets/images/latest-talk/Typewriter.png";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import closeIcon from "../../assets/images/closeIcon.svg";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import resetIcon from "../../assets/images/resetIcon.svg";

import Footer from "../../shared-components/Footer/Footer";
import { Accordion, Tabs, Tab, Form, FloatingLabel } from "react-bootstrap";
import FeedbackForm from "../whatWedo/Talks/FeedbackForm";

import Carousel from 'react-bootstrap/Carousel';
import banner1 from "../../assets/images/bannerBg.png";
import banner2 from "../../assets/images/book/fromTheFrugalToTheOrnateBanner.png";
import banner2Tab from "../../assets/images/book/fromTheFrugalToTheOrnateTabBanner.png";
import banner2Mobi from "../../assets/images/book/fromTheFrugalToTheOrnateMobiBanner.png";



export class Home extends Component {
  constructor(){
    super();   
      this.handleChildUnmount = this.handleChildUnmount.bind(this);
  }
    state = {
      open: false,
      openFeedback:false,
      feedbackOpen: false,
      formFeedback:''
    };
  openDialog() {
    this.setState({ open: true });
   // this.setState({ type });
  }
  closeDialog() {
    this.setState({ open: false });
  }


   // FeedbackFormRef = ({handleOpen}) => {
  //   debugger;
  //   //const {val}=handleOpen || {};
  //   //if(handleOpen!=null || handleOpen!=undefined)
  //   this.openDialogBox = handleOpen;
  // }
  
  onOpenDialogClick = (e) => {
    //  this.openDialogBox(e);
    const formName = e
      this.setState({
        formFeedback:formName
      })
    this.setState({ openFeedback: true})
    }
  
    handleChildUnmount(){
      this.setState({openFeedback: false});
      this.setState({formFeedback:''});
  }

// openDialog(type) {
//   this.setState({ open: true });
//   this.setState({ type });
// }

  // Defining WOW
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();
  }

  render() {
    const options = {
      items: 1,
      nav: true,
      autoplay: false,
      loop: false,
      rewindNav: false,
      stagePadding: 50,
      navText: [
        "<div class='nav-btn prev-slide'></div>",
        "<div class='nav-btn next-slide'></div>",
      ],
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
          nav: true,
          stagePadding: 0,
        },
        600: {
          items: 1,
          nav: false,
          stagePadding: 0,
        },
        1000: {
          items: 1,
          nav: true,
          loop: false,
          stagePadding: 50,
        },
      },
    };
    const optionsTwo = {
      items: 2,
      nav: true,
      autoplay: false,
      loop: false,
      rewindNav: false,
      stagePadding: 50,

      navText: [
        "<div class='nav-btn prev-slide'></div>",
        "<div class='nav-btn next-slide'></div>",
      ],
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
          nav: true,
          stagePadding: 0,
        },
        600: {
          items: 1,
          nav: false,
          stagePadding: 0,
        },
        1000: {
          items: 2,
          nav: true,
          loop: false,
          stagePadding: 50,
        },
      },
    };

    const events = {
      onDragged: function (event) {},
      onChanged: function (event) {},
      };

      
    return (
      <div className="homePage">
        <Header className="header2" />
            <section id="section-one" className="virtalTour verticalMiddle sectionOne">
                <Carousel>
                    
                    <Carousel.Item>
                        <img
                            className="d-xl-block d-lg-block d-md-none d-none img-fluid"
                            src={banner2}
                            alt="Second slide"
                        /> 

                        <img
                            className="d-xl-none d-lg-block d-md-block d-none img-fluid"
                            src={banner2Tab}
                            alt="Second slide"
                        />

                        <img
                            className="d-xl-none d-lg-none d-md-none d-sm-block d-xs-block img-fluid"
                            src={banner2Mobi}
                            alt="Second slide"
                        />


                        <Carousel.Caption>
                        <div className="container">
                            <div className="bannerInfo banner2Txt">
                                <h1>OUT NOW</h1>

                                <h2>FROM THE FRUGAL<br />TO THE ORNATE</h2>
                                <p>
                                    STORIES OF THE SEAT IN INDIA
                                </p>

                                <h4><i>By SARITA SUNDAR</i></h4>

                                

                                <a
                                        href="https://www.amazon.in/Frugal-Ornate-Stories-Seat-India/dp/8193274016/ref=sr_1_1?crid=2X2B4D0LLSWM1&keywords=from+the+frugal+to+the+ornate+stories+of+the+seat+in+india&qid=1665389689&s=books&sprefix=from+the+frugal+to+the+ornate%2Cstripbooks%2C613&sr=1-1 "
                                    target="_blank"
                                    className="bannerBtn readMore"
                                >
                                    <span></span> BUY NOW
                                </a>
                            </div>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block img-fluid"
                            src={banner1}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                        <div className="container">
                            <div className="bannerInfo text-center banner1Txt">
                                <h1>Virtual Tour</h1>

                                <p>
                                    A ballot box from India's first elections in 1952. Currency notes
                                    saved from the Bombay Docks explosion. A square Navtal lock.
                                    Godrej's first corporate film from 1969. Explore all this and more
                                    of 125 years of Godrej history in this virtual tour of our
                                    permanent exhibition 'Back to the Future'!
                                </p>
                                <a
                                        href="https://archives.godrejenterprises.com/virtualtour/"
                                    target="_blank"
                                    className="bannerBtn readMore"
                                >
                                    <span></span> LET'S START THE TOUR
                                </a>
                            </div>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    
                </Carousel>
          <HashLink
            className="scrollDown"
            // example of custom scroll function using the scroll prop
            scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
            to={"/home#section-two"}
          >
            <img src={scrollArrow} alt="Scroll Down"></img>
          </HashLink>
        </section>

        <section id="section-two" className="who-we-are verticalMiddle">
          <div className="container p-4 p-lg-0">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="whoWeareInfo pe-lg-5 pb-5">
                  <h2 className="secHeader mb-4">Who we are</h2>
                  <p>
                    Located in Mumbai, Godrej Archives is the Godrej group's
                    business archive. The Archives collects, preserves and
                    manages records covering 125 years of the company's history.
                    The idea was mooted in 1997, the group's centenary year, by
                    Mr. Sohrab Godrej, former chairman of the Godrej group, who
                    had over the years, taken up the task of collecting relevant
                    documents and photographs. Since then, Archives has been
                    recording the history of Godrej – its products, plants and
                    people – and making it available for research.
                  </p>
                  <Nav.Link href="/who-we-are" bsPrefix="readMore mt-3">
                    <span></span> EXPLORE
                  </Nav.Link>
                </div>
              </div>
              <div className="col-md-6">
                <figure>
                  <img
                    src={whoWeAre}
                    alt="Who We Are"
                    className="img-fluid"
                  ></img>
                </figure>
              </div>
            </div>
          </div>
        </section>
        <section id="latestTalks" className="latestTalks py-90">
          <div className="container-fluid p-4 p-lg-0">
            <h2 className="secHeader mb-4">Talks</h2>
            <p className="mb-4">
              Our talks and lectures stir discussions around business history,
              business archives, products &amp; commodities, <br></br>national
              development, global economy and more. Let's keep the conversation
              flowing!
            </p>
            <OwlCarousel ref="car" options={options} events={events}>
              <div className="item">
                <div className="row align-items-end">
                  <div className="col-md-7">
                    <figure className="m-0">
                      <img src={latestTalksbanner7} alt="slider 1"></img>
                    </figure>
                  </div>
                  <div className="col-md-4">
                    <div className="letsTalkDetails">
                      <p class="mt-4 mb-0">Annual Lecture</p>
                      <h4 className="mt-1">The Global Merchants</h4>
                      <div className="actionBtn mb-5 mb-lg-1 ">
                        <Link to="/annual-details/16" className="detailsBtn">
                          DETAILS
                        </Link>
                        <Button
                          className="ms-3 registerBtn"
                          onClick={this.onOpenDialogClick.bind(this,"THURSDAY TALK")}
                        >
                          Feedback
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="row align-items-end">
                  <div className="col-md-7">
                    <figure className="m-0">
                      <img src={latestTalksbanner6} alt="slider 1"></img>
                    </figure>
                  </div>
                  <div className="col-md-4">
                    <div className="letsTalkDetails">
                      <p class="mt-4 mb-0">Thursday Talk</p>
                      <h4 className="mt-1">
                        Mad Men of Bombay: Revisiting the Creative Revolution in
                        Indian Advertising
                      </h4>
                      <div className="actionBtn">
                        <Link to="/talk-details/1" className="detailsBtn">
                          DETAILS
                        </Link>
                        <Button
                          className="ms-3 registerBtn"
                          onClick={this.onOpenDialogClick.bind(this,"THURSDAY TALK")}
                        >
                          Feedback
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="row align-items-end">
                  <div className="col-md-7">
                    <figure className="m-0">
                      <img src={latestTalksbanner5} alt="slider 1"></img>
                    </figure>
                  </div>
                  <div className="col-md-4">
                    <div className="letsTalkDetails">
                      <p class="mt-4 mb-0">Thursday Talk</p>
                      <h4 className="mt-1">
                        The Global Blue Jeans Transformation
                      </h4>
                      <div className="actionBtn">
                        <Link to="/talk-details/2" className="detailsBtn">
                          DETAILS
                        </Link>
                        <Button
                          className="ms-3 registerBtn"
                          onClick={this.onOpenDialogClick.bind(this,"THURSDAY TALK")}
                        >
                          Feedback
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="row align-items-end">
                  <div className="col-md-7">
                    <figure className="m-0">
                      <img src={latestTalksbanner4} alt="slider 1"></img>
                    </figure>
                  </div>
                  <div className="col-md-4">
                    <div className="letsTalkDetails">
                      <p class="mt-4 mb-0">Thursday Talk</p>
                      <h4 className="mt-1">
                        Timber Trade Trajectory - Urbanization of Bombay and
                        Deforestation of Western India: Nineteenth Century
                      </h4>
                      <div className="actionBtn">
                        <Link to="/talk-details/3" className="detailsBtn">
                          DETAILS
                        </Link>
                        <Button
                          className="ms-3 registerBtn"
                          onClick={this.onOpenDialogClick.bind(this,"THURSDAY TALK")}
                        >
                          Feedback
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="row align-items-end">
                  <div className="col-md-7">
                    <figure className="m-0">
                      <img src={latestTalksbanner3} alt="slider 1"></img>
                    </figure>
                  </div>
                  <div className="col-md-4">
                    <div className="letsTalkDetails">
                      <p class="mt-4 mb-0">Thursday Talk</p>
                      <h4 className="mt-1">
                        Power, Privilege and Posture: The Politics of the Seat
                        in India
                      </h4>
                      <div className="actionBtn">
                        <Link to="/talk-details/4" className="detailsBtn">
                          DETAILS
                        </Link>
                        <Button
                          className="ms-3 registerBtn"
                          onClick={this.onOpenDialogClick.bind(this,"THURSDAY TALK")}
                        >
                          Feedback
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="row align-items-end">
                  <div className="col-md-7">
                    <figure className="m-0">
                      <img src={latestTalksbanner2} alt="slider 1"></img>
                    </figure>
                  </div>
                  <div className="col-md-4">
                    <div className="letsTalkDetails">
                      <p class="mt-4 mb-0">Thursday Talk</p>
                      <h4 className="mt-1">
                        Business in the Time of Contagion
                      </h4>
                      <div className="actionBtn">
                        <Link to="/talk-details/5" className="detailsBtn">
                          DETAILS
                        </Link>
                        <Button
                          className="ms-3 registerBtn"
                          onClick={this.onOpenDialogClick.bind(this,"THURSDAY TALK")}
                        >
                          Feedback
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="row align-items-end">
                  <div className="col-md-7">
                    <figure className="m-0">
                      <img src={latestTalksbanner8} alt="slider 1"></img>
                    </figure>
                  </div>
                  <div className="col-md-4">
                    <div className="letsTalkDetails">
                      <p class="mt-4 mb-0">Thursday Talk</p>
                      <h4 className="mt-1">Typewriter Tip Tip</h4>
                      <div className="actionBtn">
                        <Link to="/talk-details/6" className="detailsBtn">
                          DETAILS
                        </Link>
                        <Button
                          className="ms-3 registerBtn"
                          onClick={this.onOpenDialogClick.bind(this,"THURSDAY TALK")}
                        >
                          Feedback
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
            {/* {this.state.feedbackOpen && (
              <FeedbackForm
                type={"THURSDAY TALK"}
                open={this.state.feedbackOpen}
                closeDialog={() => this.setState({ feedbackOpen: false })}
              />
            )} */}

{this.state.openFeedback ? <FeedbackForm unmountMe={this.handleChildUnmount}  openFeedback={this.state.openFeedback} formFeedback={this.state.formFeedback} ></FeedbackForm>:null}
            
            <Dialog open={this.state.open} onEnter={console.log("Hey.")}>
              <div className="header px-5 py-4">
                <div className="close" onClick={this.closeDialog.bind(this)}>
                  <img src={closeIcon} alt="Close Icon"></img>
                </div>
                <h2 className="secHeader">REGISTRATION FORM</h2>
                <p className="m-0">All fields are mandetory</p>
              </div>

              <DialogContent>
                <div className="dialogInfo ">
                  <div className="formBio p-5">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="formHeader mt-4 mb-3">
                          PERSONAL DETAILS
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formGrid">
                          <div className="formIcon">
                            <i className="fa fa-user"></i>
                          </div>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Name"
                            className="mb-3"
                          >
                            <Form.Control type="text" placeholder="Name" />
                          </FloatingLabel>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formGrid">
                          <div className="formIcon">
                            <i className="fa fa-envelope"></i>
                          </div>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Email ID"
                            className="mb-3"
                          >
                            <Form.Control type="email" placeholder="Email" />
                          </FloatingLabel>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formGrid">
                          <div className="formIcon">
                            <i className="fa fa-envelope"></i>
                          </div>
                          <FloatingLabel
                            controlId="floatingSelectGrid"
                            label="Location (India / Other)"
                          >
                            <Form.Select aria-label="Floating label select example">
                              <option>Open this select menu</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </Form.Select>
                          </FloatingLabel>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formGrid">
                          <div className="formIcon">
                            <i className="fa fa-user"></i>
                          </div>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Organization"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Organization"
                            />
                          </FloatingLabel>
                        </div>
                      </div>
                      <div className="text-center actionBtn mt-4">
                        <button className="resetBtn">
                          <img
                            src={resetIcon}
                            alt="Reset Icon"
                            className="me-2"
                          ></img>
                          Reset
                        </button>
                        <button className="submitBtn ms-2">SUBMIT</button>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
            <Nav.Link
              href="/talks"
              bsPrefix="readMore mt-lg-4 mt-4 mb-5 mb-lg-0"
            >
              <span></span> EXPLORE
            </Nav.Link>
          </div>
        </section>
        <section id="publications" className="publications verticalMiddle py-5">
          <div className="container p-4 p-lg-0">
            <h2 className="secHeader mb-4">Publications</h2>
            <div className="row">
              <div className="col-md-10 m-auto">
                <figure>
                  <img
                    src={Publications}
                    alt="Publications Banner"
                    className="img-fluid d-none d-sm-none d-lg-block"
                  ></img>
                  <img
                    src={PublicationsMob}
                    alt="Publications Banner"
                    className="img-fluid d-block d-sm-block d-lg-none"
                  ></img>
                </figure>
                <div className="publicationsBelow mt-4">
                  <div className="row ">
                    <div className="col-md-5">
                      <h4>Archives Times: Current Issue</h4>
                      <ul className="archivesCalender">
                        <li>
                          <img src={calenderIcon} alt="Calender Icon"></img>
                         January  2024
                        </li>
                        <li>
                          <img src={bookIcon} alt="Book Icon"></img> Volume 4,
                          Issue 4
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-7">
                      <p>
                      On February 20, 2024, class 7 students from Godrej’s Udayachal School visited Plant 13A, Godrej & Boyce for a special session on Mumbai’s history. Fiona Fernandez, Associate Editor at Mid-Day, Mumbai, conducted the session with an engaging quiz based on her book 'H for Heritage: Mumbai' published by Harper Collins India. 
                      </p>
                    </div>
                  </div>
                  <Nav.Link
                    href="/archive-times"
                    bsPrefix="readMore mt-lg-4 mt-4"
                  >
                    <span></span> EXPLORE
                  </Nav.Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="archiveTales" className="archiveTales pt-90 pb-90">
          <div className="archiveTalesInfo position-relative ">
            <div className="container p-4 p-lg-0">
              <div className="row">
                <div className="col-md-12 ms-auto">
                  <div className="row">
                    <div className="col-md-4">
                      <h2 className="secHeader mb-4">Archive Tales</h2>
                      <p>
                        Bringing you fascinating stories of grit and innovation,
                        of enterprise and humanity from our vast archival
                        collection that will shine a light on the illustrious
                        corporate past of the Godrej Group.
                      </p>
                    </div>
                    <div className="col-md-8 pe-0">
                      <div className="archiveTabs">
                        <Nav.Link
                          href="/archive-tales"
                          bsPrefix="readMore mt-lg-4 mt-3 text-left"
                        >
                          EXPLORE
                        </Nav.Link>
                      </div>
                      <OwlCarousel
                        ref="car"
                        options={optionsTwo}
                        events={events}






                                     >

<div className="item">



<figure>
    <img
        src={archiveThumbJULY24}
        alt="Grey Matter"
        className="img-fluid"></img>
</figure>
<div className="overlaySec">
    <a
        href="https://archives.godrejenterprises.com/DOM-Lectures/dom_July2024_index.html"
        target="_blank"
        className="readMore"
    >
        Read More
    </a>
    <div className="itemInfo">
        <div className="title">
            DOCUMENT OF THE MONTH - July 2024
        </div>
        <p>S P A C E B A R – Locking mechanism in Godrej typewriters - Yay or nah?</p>
    </div>
</div>
</div>

<div className="item">



<figure>
    <img
        src={archiveThumbJUNE24}
        alt="Grey Matter"
        className="img-fluid"></img>
</figure>
<div className="overlaySec">
    <a
        href="https://archives.godrejenterprises.com/DOM-Lectures/dom_June2024_index.html"
        target="_blank"
        className="readMore"
    >
        Read More
    </a>
    <div className="itemInfo">
        <div className="title">
            DOCUMENT OF THE MONTH - June 2024
        </div>
        <p>Grey Matter</p>
    </div>
</div>
</div>


                                            <div className="item">



                                                <figure>
                                                    <img
                                                        src={archiveThumbMAY24}
                                                        alt="Remembering Sohrab Godrej"
                                                        className="img-fluid"></img>
                                                </figure>
                                                <div className="overlaySec">
                                                    <a
                                                        href="https://archives.godrejenterprises.com/DOM-Lectures/dom_May2024_index.html"
                                                        target="_blank"
                                                        className="readMore"
                                                    >
                                                        Read More
                                                    </a>
                                                    <div className="itemInfo">
                                                        <div className="title">
                                                            DOCUMENT OF THE MONTH - May 2024
                                                        </div>
                                                        <p>Remembering Sohrab Godrej</p>
                                                    </div>
                                                </div>
                                            </div>




<div className="item">



<figure>
    <img
        src={archiveThumbAPR24}
        alt="AB Godrej Typewriter – a timeless triumph"
        className="img-fluid"></img>
</figure>
<div className="overlaySec">
    <a
        href="https://archives.godrejenterprises.com/DOM-Lectures/dom_Apr2024_index.html"
        target="_blank"
        className="readMore"
    >
        Read More
    </a>
    <div className="itemInfo">
        <div className="title">
            DOCUMENT OF THE MONTH - Apr 2024
        </div>
        <p>Protecting the Godrej identity</p>
    </div>
</div>
</div>

<div className="item">



<figure>
    <img
        src={archiveThumbMAR24}
        alt="AB Godrej Typewriter – a timeless triumph"
        className="img-fluid"></img>
</figure>
<div className="overlaySec">
    <a
        href="https://archives.godrejenterprises.com/DOM-Lectures/dom_Mar2024_index.html"
        target="_blank"
        className="readMore"
    >
        Read More
    </a>
    <div className="itemInfo">
        <div className="title">
            DOCUMENT OF THE MONTH - March 2024
        </div>
        <p>AB Godrej Typewriter – a timeless triumph</p>
    </div>
</div>
</div>


                                            <div className="item">



                                                <figure>
                                                    <img
                                                        src={archiveThumbFEB24}
                                                        alt="Nisarga Mitra – Mr. Sohrab Godrej"
                                                        className="img-fluid"
                                                    ></img>
                                                </figure>
                                                <div className="overlaySec">
                                                    <a
                                                        href="https://archives.godrejenterprises.com/DOM-Lectures/dom_Feb2024_index.html"
                                                        target="_blank"
                                                        className="readMore"
                                                    >
                                                        Read More
                                                    </a>
                                                    <div className="itemInfo">
                                                        <div className="title">
                                                            DOCUMENT OF THE MONTH - February 2024
                                                        </div>
                                                        <p>Nisarga Mitra – Mr. Sohrab Godrej</p>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="item">

                                               

                                                <figure>
                                                    <img
                                                        src={archiveThumbJAN24}
                                                        alt="Bombay under siege!"
                                                        className="img-fluid"
                                                    ></img>
                                                </figure>
                                                <div className="overlaySec">
                                                    <a
                                                        href="https://archives.godrejenterprises.com/DOM-Lectures/dom_Jan2024_index.html"
                                                        target="_blank"
                                                        className="readMore"
                                                    >
                                                        Read More
                                                    </a>
                                                    <div className="itemInfo">
                                                        <div className="title">
                                                            DOCUMENT OF THE MONTH - January 2024
                                                        </div>
                                                        <p>Inspiring reformers and changemakers!</p>
                                                    </div>
                                                </div>
                                            </div>

<div className="item">
                                                <figure>
                                                    <img
                                                        src={archiveThumbDEC23}
                                                        alt="Bombay under siege!"
                                                        className="img-fluid"
                                                    ></img>
                                                </figure>
                                                <div className="overlaySec">
                                                    <a
                                                        href="https://archives.godrejenterprises.com/DOM-Lectures/dom_DEC2023_index.html"
                                                        target="_blank"
                                                        className="readMore"
                                                    >
                                                        Read More
                                                    </a>
                                                    <div className="itemInfo">
                                                        <div className="title">
                                                            DOCUMENT OF THE MONTH - December 2023
                                                        </div>
                                                        <p>Homage to the Founding Father</p>
                                                    </div>
                                                </div>
                                            </div>

<div className="item">
                                                <figure>
                                                    <img
                                                        src={archiveThumbNOV23}
                                                        alt="Bombay under siege!"
                                                        className="img-fluid"
                                                    ></img>
                                                </figure>
                                                <div className="overlaySec">
                                                    <a
                                                        href="https://archives.godrejenterprises.com/DOM-Lectures/dom_NOV2023_index.html"
                                                        target="_blank"
                                                        className="readMore"
                                                    >
                                                        Read More
                                                    </a>
                                                    <div className="itemInfo">
                                                        <div className="title">
                                                            DOCUMENT OF THE MONTH - November 2023
                                                        </div>
                                                        <p>Bombay under siege!</p>
                                                    </div>
                                                </div>
                                            </div>

                      <div className="item">
                                                <figure>
                                                    <img
                                                        src={archiveThumbOct23}
                                                        alt="Call me by your extension line…"
                                                        className="img-fluid"
                                                    ></img>
                                                </figure>
                                                <div className="overlaySec">
                                                    <a
                                                        href="https://archives.godrejenterprises.com/DOM-Lectures/dom_Oct2023_index.html"
                                                        target="_blank"
                                                        className="readMore"
                                                    >
                                                        Read More
                                                    </a>
                                                    <div className="itemInfo">
                                                        <div className="title">
                                                            DOCUMENT OF THE MONTH - October 2023
                                                        </div>
                                                        <p>Call me by your extension line…</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="item">
                                                <figure>
                                                    <img
                                                        src={archiveThumbSept23}
                                                        alt="Godrej typewriters – a top notch sales game!"
                                                        className="img-fluid"
                                                    ></img>
                                                </figure>
                                                <div className="overlaySec">
                                                    <a
                                                        href="https://archives.godrejenterprises.com/DOM-Lectures/dom_Sept2023_index.html"
                                                        target="_blank"
                                                        className="readMore"
                                                    >
                                                        Read More
                                                    </a>
                                                    <div className="itemInfo">
                                                        <div className="title">
                                                            DOCUMENT OF THE MONTH - September 2023
                                                        </div>
                                                        <p>Godrej typewriters – a top notch sales game!</p>
                                                    </div>
                                                </div>
                                            </div>

                                          <div className="item">
                                                <figure>
                                                    <img
                                                        src={archiveThumbAug23}
                                                        alt="A Glimpse into the Past"
                                                        className="img-fluid"
                                                    ></img>
                                                </figure>
                                                <div className="overlaySec">
                                                    <a
                                                        href="https://archives.godrejenterprises.com/DOM-Lectures/dom_Aug2023_index.html"
                                                        target="_blank"
                                                        className="readMore"
                                                    >
                                                        Read More
                                                    </a>
                                                    <div className="itemInfo">
                                                        <div className="title">
                                                            DOCUMENT OF THE MONTH - August 2023
                                                        </div>
                                                        <p>Wit and wisdom of a sales memo</p>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="item">
                                                <figure>
                                                    <img
                                                        src={archiveThumbJuly23}
                                                        alt="A Glimpse into the Past"
                                                        className="img-fluid"
                                                    ></img>
                                                </figure>
                                                <div className="overlaySec">
                                                    <a
                                                        href="https://archives.godrejenterprises.com/DOM-Lectures/dom_July2023_index.html"
                                                        target="_blank"
                                                        className="readMore"
                                                    >
                                                        Read More
                                                    </a>
                                                    <div className="itemInfo">
                                                        <div className="title">
                                                            DOCUMENT OF THE MONTH - JULY 2023
                                                        </div>
                                                        <p>A Glimpse into the Past</p>
                                                    </div>
                                                </div>
                                            </div>

                                          
<div className="item">
                                                <figure>
                                                    <img
                                                        src={archiveThumbJune23}
                                                        alt="Double protection from fire and theft"
                                                        className="img-fluid"
                                                    ></img>
                                                </figure>
                                                <div className="overlaySec">
                                                    <a
                                                        href="https://archives.godrejenterprises.com/DOM-Lectures/dom_June2023_index.html"
                                                        target="_blank"
                                                        className="readMore"
                                                    >
                                                        Read More
                                                    </a>
                                                    <div className="itemInfo">
                                                        <div className="title">
                                                            DOCUMENT OF THE MONTH - JUNE 2023
                                                        </div>
                                                        <p>Double protection from fire and theft</p>
                                                    </div>
                                                </div>
                                            </div>


                                        

 </OwlCarousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default Home;
