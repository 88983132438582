import { Component } from "react";
import "./AnnualLecture.scss";
import Footer from "../../../shared-components/Footer/Footer";
import Header from "../../../shared-components/Header/Header";
import maskGroup257 from "../../../assets/images/what-we-do/annual-lecture/MaskGroup257.png";
import rectangle6549 from "../../../assets/images/what-we-do/research-paper/Rectangle6549.png";
import pdf3 from "../../../assets/pdf/Annual-Lecture-Series-2006-2017.pdf";

export class AnnualLecture extends Component {
  render() {
    return (
      <div className="annualLectureMain">
        <Header />
        <div className=" text-center">
          <div className="functions py-90">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="secHeader mb-4 mt-5">
                    Annual Lecture Series Booklet
                  </h2>
                </div>
              </div>
              <div className="functionGrid">
                <div className="row py-3">
                  <div className="col-12 col-lg-4">
                    <figure
                      style={{
                        backgroundImage: "url(" + rectangle6549 + ")",
                      }}
                    >
                      <img
                        src={maskGroup257}
                        alt="Functions"
                        className="img-fluid"
                      ></img>
                    </figure>
                  </div>
                  <div className="col-12 col-lg-8">
                    <div className="functionBio">
                      {/* <div className="number">01</div> */}
                      <h4 className="mt-0 mb-3">
                        <a href={pdf3} target="_blank">
                      Godrej Archives Annual Lecture Series 2006-2017 
                      </a>
                      </h4>
                      <p>
                      To encourage interest in the subject of business history and help appreciate the role of archives in understanding how business influences life patterns of any country and its people, Godrej Archives and Tata Central Archives started an Annual Lecture series from the year 2006. We believe that this lecture series will help generate awareness about archiving corporate heritage of the country. Here's a compilation of all the lectures held from 2006 to 2017.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default AnnualLecture;
