import { Component } from "react";
import "./ResearchPapers.scss";
import Footer from "../../../shared-components/Footer/Footer";
import Header from "../../../shared-components/Header/Header";
import maskGroup208 from "../../../assets/images/what-we-do/research-paper/MaskGroup208.png";
import maskGroup255 from "../../../assets/images/what-we-do/research-paper/MaskGroup255.png";
import maskGroup251 from "../../../assets/images/what-we-do/research-paper/MaskGroup251.png";
import maskGroup247 from "../../../assets/images/what-we-do/research-paper/MaskGroup247.png";
import rectangle6549 from "../../../assets/images/what-we-do/research-paper/Rectangle6549.png";
import pdf3 from "../../../assets/pdf/CollectiveMemory.pdf";
import { Nav } from "react-bootstrap";

export class ResearchPapers extends Component {
  render() {
    return (
      <div class="researchPaper">
        <Header />
        <div className="text-center">
          <div className="functions py-90">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="secHeader mb-4 mt-5">
                    Research Papers by Archives Team
                  </h2>
                </div>
              </div>
              <div className="functionGrid">
                <div className="row py-5 py-lg-4">
                  <div className="col-12 col-lg-4">
                    <figure>
                      <img
                        src={maskGroup208}
                        alt="Functions"
                        className="img-fluid"
                      ></img>
                    </figure>
                  </div>
                  <div className="col-12 col-lg-8">
                    <div className="functionBio">
                      {/* <div className="number">01</div> */}

                      <Nav.Link
                        href={
                          "https://www.ica.org/sites/default/files/business_archives_in_international_comparison_-_2021.pdf "
                        }
                        className="my-3"
                        target="_blank"
                      >
                        <h4>
                          Overview of Business Archives in India (2021) <br />-
                          By Vrunda Pathare
                        </h4>
                      </Nav.Link>
                      <p>
                        Published in 3rd edition of ‘Business Archives in
                        International Comparison’, Ed. Alison Turton and
                        published by International Council on Archives Section
                        on Business Archives (ICA SBA), Paris, France.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="functionGrid">
                <div className="row py-5 py-lg-4">
                  <div className="col-12 col-lg-4">
                    <figure
                      style={{
                        backgroundImage: "url(" + rectangle6549 + ")",
                      }}
                    >
                      <img
                        src={maskGroup247}
                        alt="Functions"
                        className="img-fluid"
                      ></img>
                    </figure>
                  </div>
                  <div className="col-12 col-lg-8">
                    <div className="functionBio">
                      {/* <div className="number">02</div> */}
                      <Nav.Link
                        href={
                          "https://archives.godrejenterprises.com/assets/pdf/NMML_Vrunda_Pathare.pdf "
                        }
                        className="my-3"
                        target="_blank"
                      >
                        <h4>
                          Section on Business Archives, International Council on
                          Archives, Business Archives: A window into the
                          corporate past (2013) <br />- By Vrunda Pathare
                        </h4>
                      </Nav.Link>
                      <p>
                        An NMML Occasional Paper from ‘Perspectives in Indian
                        Development, New Series 19’ published by Nehru Memorial
                        Museum and Library, New Delhi, India
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="functionGrid">
                <div className="row py-5 py-lg-4">
                  <div className="col-12 col-lg-4">
                    <figure
                      style={{
                        backgroundImage: "url(" + rectangle6549 + ")",
                      }}
                    >
                      <img
                        src={maskGroup255}
                        alt="Functions"
                        className="img-fluid"
                      ></img>
                    </figure>
                  </div>
                  <div className="col-12 col-lg-8">
                    <div className="functionBio">
                      {/* <div className="number">03</div> */}
                      <Nav.Link href={pdf3} className="my-3" target="_blank">
                        <h4>
                          Business Archives: Collective Memory and Selective
                          Approach (2013)
                          <br /> - By Vrunda Pathare
                        </h4>
                      </Nav.Link>
                      <p>
                        Published in ‘Springer Briefs in Political Science:
                        Archives for Maintaining Community and Society in the
                        Digital Age’, Ed. Keiji Fujiyoshi and published by
                        Springer Nature, Singapore
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="functionGrid">
                <div className="row py-5 py-lg-4">
                  <div className="col-12 col-lg-4">
                    <figure
                      style={{
                        backgroundImage: "url(" + rectangle6549 + ")",
                      }}
                    >
                      <img
                        src={maskGroup251}
                        alt="Functions"
                        className="img-fluid"
                      ></img>
                    </figure>
                  </div>
                  <div className="col-12 col-lg-8">
                    <div className="functionBio">
                      {/* <div className="number">04</div> */}
                      <Nav.Link
                        href={
                          "https://archives.godrejenterprises.com/assets/pdf/Shibusawa_Chap09_Pathare.pdf "
                        }
                        className="my-3"
                        target="_blank"
                      >
                        <h4>
                          The Shaping of History in a Corporate Setting: The
                          Godrej Scenario (2012) <br />- By Vrunda Pathare
                        </h4>
                      </Nav.Link>
                      <p>
                        Published in ‘Leveraging Corporate Assets: New Global
                        Directions for Business Archives’ by Resource Centre for
                        the History of Entrepreneurship, Shibusawa Eiichi
                        Memorial Foundation, Tokyo, Japan
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default ResearchPapers;
