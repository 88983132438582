import React, { Component } from "react";
import "./ReprographyServices.scss";
import Header from "../../../shared-components/Header/Header";
import Footer from "../../../shared-components/Footer/Footer";
import maskGroup207 from "../../../assets/images/what-we-offer/reprography-services/MaskGroup207.png";
import maskGroup208 from "../../../assets/images/what-we-offer/reprography-services/MaskGroup208.png";
import maskGroup209 from "../../../assets/images/what-we-offer/reprography-services/MaskGroup209.png";
import maskGroup210 from "../../../assets/images/what-we-offer/reprography-services/MaskGroup210.png";

export class ReprographyServices extends Component {
  render() {
    const options = {
      items: 1,
      nav: true,
      autoplay: false,
      loop: false,
      rewindNav: false,
      navText: [
        "<div class='nav-btn prev-slide'></div>",
        "<div class='nav-btn next-slide'></div>",
      ],
      stagePadding: 50,
    };
    return (
      <div className="reprographyServices">
        <Header />
        <div className="innerBanner reprographyServicebanner text-center">
          <div className="bannerInfo">
            <h1>Reprography Services</h1>
            <p>
              Need a copy or copies of our archival records? Get to know the
              nitti-gritties of our reprography services.
            </p>
          </div>
        </div>

          <div className=" text-center">
          <div className="functions py-90">
            <div className="container ps-4 p-lg-0">
              {/* <div className="row">
                <div className="col-md-12">
                  <h2 className="secHeader mb-4 mt-5">REPROGRAPHY CHARGES</h2>
                </div>
              </div> */}
              <div className="functionGrid">
                <div className="row py-3">
                  <div className="col-12 col-lg-5">
                    <figure>
                      <img
                        src={maskGroup208}
                        alt="Functions"
                        className="img-fluid"
                      ></img>
                    </figure>
                  </div>
                  <div className="col-12 col-lg-7">
                    <div className="functionBio">
                      <h4 className="my-3">Library Collection</h4>
                      <p>Photocopying charges for books are Rs. 2 per page.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="functionGrid">
                <div className="row py-3">
                  <div className="col-12 col-lg-5">
                    <figure>
                      <img
                        src={maskGroup207}
                        alt="Functions"
                        className="img-fluid"
                      ></img>
                    </figure>
                  </div>
                  <div className="col-12 col-lg-7">
                    <div className="functionBio">
                      <h4 className="my-2">
                        Manuscripts Collections (Records on Paper)
                      </h4>
                      <p>
                        <span>Black and White Reproduction: </span>
                        Photocopying manuscripts (pre-1947) - Rs. 20 per
                        page (letter and legal size) and Rs. 30 per page (A3
                        size) manuscripts (post 1947) - Rs. 10 per page ( letter
                        and legal size) and Rs. 20 per page (A3 size).
                        
                        <br /> <span>Colour reproduction: </span>Photocopying manuscripts
                        (pre-1947) - Rs. 30 per page ( letter and legal size)
                        and Rs. 40 per page (A3 size) Manuscripts (post 1947) -
                        Rs. 20 per page (letter and legal size) and Rs. 30 per
                        page (A3 size)
                        
                        <br /> <span>Digital reproduction: </span>
                         Rs. 1000 per document and an additional Rs. 10
                        for providing it on a CD Images with resolution higher
                        than 300 dpi - an additional Rs. 100 for every extra 100
                        dpi.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="functionGrid">
                <div className="row py-3">
                  <div className="col-12 col-lg-5">
                    <figure>
                      <img
                        src={maskGroup209}
                        alt="Functions"
                        className="img-fluid"
                      ></img>
                    </figure>
                  </div>
                  <div className="col-12 col-lg-7">
                    <div className="functionBio">
                      <h4 className="my-2">Photographs collection</h4>
                      <p>
                        Godrej Archives will provide only digital reproduction
                        (scanned version) of photographs with resolution 300 dpi
                        in TIF format. The charges for digital reproduction are
                        Rs. 1000 per document and additional Rs. 10 if user
                        requires it on a CD.
                        
                        <br /> If user requires an image with resolution higher
                        than 300 dpi, additional Rs. 100 will be charged for
                        every additional 100 dpi.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="functionGrid">
                <div className="row py-3">
                  <div className="col-12 col-lg-5">
                    <figure>
                      <img
                        src={maskGroup210}
                        alt="Functions"
                        className="img-fluid"
                      ></img>
                    </figure>
                  </div>
                  <div className="col-12 col-lg-7">
                    <div className="functionBio">
                      <h4 className="my-2">Audio-Visual Collection</h4>
                      <p>
                        We copy whole film, video and audio. 
                        <br /> Reproduction of the audio-visual material will be
                        done by Godrej Archives. Once the work order is placed,
                        Archives will send the material to the studio for
                        duplication. The user is required to pay the cost of
                        reproduction as per the charges fixed by the studio.
                        
                        <br /> Blank audio/video CDs may be provided by the
                        users if desired. If not, archives will provide the CDs
                        at Rs. 10 per CD.
                        
                        <br /> Godrej Archives do not allow the reproduction of
                        Oral History recordings. Only the transcriptions are
                        available for photocopying. The charges will be Rs. 5
                        per page.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="repographyService py-90 lightOrangeBg">
          <div className="container p-4 p-lg-0">
            <div className="row py-3">
              <div className="col-md-4 mb-5">
                <div className="repographyServiceGrid">
                  <div className="number">01</div>
                  <h4 className="mt-3 mb-2">Reprography Fees</h4>
                  <p>
                    The Archives will provide reprography service at standard
                    fees. The work order will be executed after the payment of
                    reprography fees. In case of payment by cheque, they are to
                    be made payable to "Godrej &amp; Boyce Mfg. Co. Ltd."
                  </p>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <div className="repographyServiceGrid">
                  <div className="number">02</div>
                  <h4 className="mt-3 mb-2">Fill up form</h4>
                  <p>
                    User has to fill up a 'Use of Material Form' along with
                    'Term and Conditions form'.
                  </p>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <div className="repographyServiceGrid">
                  <div className="number">03</div>
                  <h4 className="mt-3 mb-2">Restricted / closed records</h4>
                  <p>
                    Godrej Archives will not provide copies from restricted/
                    closed records without permission of the originating office
                    or donor.
                  </p>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <div className="repographyServiceGrid">
                  <div className="number">04</div>
                  <h4 className="mt-3 mb-2">Original item</h4>
                  <p>
                    Godrej archives will not copy an original item that is
                    deemed too fragile for copying, or any document in which
                    copying may damage the document.
                  </p>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <div className="repographyServiceGrid">
                  <div className="number">05</div>
                  <h4 className="mt-3 mb-2">Reserves the right</h4>
                  <p>
                    Godrej Archives reserves the right to refuse extremely large
                    copying orders that will place undue pressures on the staff.
                  </p>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <div className="repographyServiceGrid">
                  <div className="number">06</div>
                  <h4 className="mt-3 mb-2">Not provided for materials</h4>
                  <p>
                    Reprography service is not provided for materials other than
                    those in possession of Godrej Archives.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="repographyServiceGrid">
                  <div className="number">07</div>
                  <h4 className="mt-3 mb-2">Permission to publish</h4>
                  <p>
                    Permission to examine materials is not an authorization to
                    publish them. Separate written application for permission to
                    publish must be made to the Archives.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      
    );
  }
}

export default ReprographyServices;
