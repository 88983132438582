//import useState hook to create menu collapse state
import React, { Component } from "react";
import "./Header.scss";
import Logo from "../../assets/images/colorLogo.svg";
import LogoWhite from "../../assets/images/whiteLogo.svg";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import closeIcon from "../../assets/images/Close-black.svg";

import { Link } from "react-router-dom";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
// import DropdownItem from "react-bootstrap/esm/DropdownItem";
// import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import "react-sticky-header/styles.css";
import StickyHeader from "react-sticky-header";

//For Google Analytics starts
//import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
//import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
const TRACKING_ID = "UA-2934706-57"; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);
//For Google Analytics ends

const MyHeader = () => (
  <StickyHeader
    // This is the sticky part of the header.
    header={
      <div className="Header_root">
        <h1 className="Header_title">ReactStickyHeader</h1>

        <ul className="Header_links">
          <li className="Header_link">When</li>
          <li className="Header_link">Why</li>
          <li className="Header_link">About</li>
        </ul>
      </div>
    }
  >
    <section>
      <p>
        This section will be what the sticky header scrolls over before entering
        into sticky state. See the gif above or run the test story book to see
        examples.
      </p>
    </section>
  </StickyHeader>
);
const handleClick = () => {
  console.info("You clicked the Chip.");
};
export class Header extends Component {
  constructor(props) {
    super(props);
    document.body.classList.remove("overflow-hidden");
  }

  state = {
    menuopen: true,
    menuIcon: "",
    menuBar: false,
    searchBar: false,
    headerClass: "header2",
  };

  openDialog() {
    this.setState({ open: true });
  }
  handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 100) {
      this.setState({ headerClass: "header1" });
    } else {
      this.setState({ headerClass: "header2" });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  render() {
    return (
      <div>
        <header>
          {/* <nav class="navbar navbar-expand-sm bg-light fixed-top">
            <div class="container">
              <Link to="/" class="navbar-brand"><img src={Logo}></img></Link>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="collapsibleNavbar">
                <ul class="navbar-nav ms-auto">
                  <li class="nav-item">

                    <HashLink className="nav-link"
                      // example of custom scroll function using the scroll prop
                      scroll={(el) => el.scrollIntoView({ behavior: 'smooth' })}
                      to={'/home#section-one'}
                    >
                      Who We Are
                    </HashLink>
                  </li>
                  <li class="nav-item">
                    <HashLink className="nav-link" smooth to={'/what-we-do'}>
                      What We Do
                    </HashLink>
                  </li>
                  <li class="nav-item">
                    <HashLink className="nav-link" smooth to={'/home#section-three'}>
                      What We Offer
                    </HashLink>
                  </li>
                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Dropdown
                    </a>
                    <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink">
                      <li><a class="dropdown-item" href="#">Action</a></li>
                      <li><a class="dropdown-item" href="#">Another action</a></li>
                      <li><a class="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <HashLink className="nav-link" smooth to={'/home#focusable-input'}>
                      Media
                    </HashLink>
                  </li>
                  <li class="nav-item">
                    <HashLink className="nav-link" smooth to={'/contact-us'}>
                      Contact Us
                    </HashLink>
                  </li>
                  <li class="nav-item">
                    <a href="#" className="nav-link contribute">Contribute</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav> */}

          <Dialog
            open={this.state.open}
            onEnter={console.log("Hey.")}
            PaperProps={{
              style: {
                backgroundColor: "transparent",
                boxShadow: "none",
              },
            }}
          >
            <div className="inputField">
              <input type="text" className="form-control" />
            </div>
            <div className="cheapList p-4">
              <h4>Quick Links</h4>
              <Stack direction="row" spacing={1}>
                <Chip label="Clickable" onClick={handleClick} />
                <Chip
                  label="Clickable"
                  variant="outlined"
                  onClick={handleClick}
                />
              </Stack>
            </div>
          </Dialog>
          <Navbar
            collapseOnSelect
            expand="lg"
            sticky="top"
            className={this.state.headerClass}
          >
            <Container>
              <Navbar.Brand href="/">
                <img src={Logo} className="logoColor"></img>
                <img src={LogoWhite} className="logoWhite2"></img>
              </Navbar.Brand>
              <Navbar.Toggle
                data-bs-toggle="collapse"
                data-bs-target="#responsive-navbar-nav"
              >
                <span class="navbar-toggler-icon"></span>
              </Navbar.Toggle>

              <Navbar.Collapse id="responsive-navbar-nav">
                <Navbar.Brand className="mobileLogo" href="/">
                  <img src={Logo} className="logoColor2"></img>
                </Navbar.Brand>
                <Navbar.Toggle
                  data-bs-toggle="collapse"
                  data-bs-target="#responsive-navbar-nav"
                >
                  <span>
                    <img src={closeIcon} alt="Close Icon" />
                  </span>
                </Navbar.Toggle>
                {/* <Nav className="ms-auto mt-5 m-lg-0"> */}
                <Nav className="ms-auto">
                  {/* <Nav.Link>
                    <Link to="/home">Home</Link>
                  </Nav.Link> */}
                  <Nav.Link>
                    <Link to="/who-we-are">Who We Are</Link>
                  </Nav.Link>
                  <Nav.Link>
                    <Link to="/what-we-do">What We Do</Link>
                  </Nav.Link>
                  <NavDropdown
                    title="What We Offer"
                    id="collasible-nav-dropdown"
                  >
                    <NavDropdown.Item>
                      <Link to="/access-policy">Access Policy</Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item>
                      <Link to="/our-collection">Our Collection</Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item>
                      <Link to="/reference-enquiry-form">
                        Reference Enquiries
                      </Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item>
                      <Link to="/research-services">
                        Research Services &amp; Room Rules
                      </Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item>
                      <Link to="/reprography-services">
                        Reprography Services
                      </Link>
                    </NavDropdown.Item>

                    {/* <NavDropdown.Divider /> */}
                    {/* <DropdownButton title="Research & Referance Services">
                      <DropdownMenu
                        style={{ transform: "translate(281px, -50px)" }}
                      >
                        <DropdownItem>
                          <Link to="/access-policy">Access Policy</Link>
                        </DropdownItem>
                        <DropdownItem>
                          <Link to="/our-collection">Our Collections</Link>
                        </DropdownItem>
                        <DropdownItem>
                          <Link to="/reference-enquiry-form">
                            Reference Enquiries
                          </Link>
                        </DropdownItem>
                        <DropdownItem>
                          <Link to="/research-services">
                            Research Services & Room Rules
                          </Link>
                        </DropdownItem>
                        <DropdownItem>
                          <Link to="/reprography-services">
                            Reprography Services
                          </Link>
                        </DropdownItem>
                      </DropdownMenu>
                    </DropdownButton> */}
                  </NavDropdown>
                  <Nav.Link>
                    <Link to="/archive-tales">Archive Tales</Link>
                  </Nav.Link>

                  {/* <Nav.Link href="#">Media</Nav.Link> */}
                  <Nav.Link>
                    <Link to="/contact-us">Contact Us</Link>
                  </Nav.Link>
                  {/* <Button
                    onClick={this.openDialog.bind(this)}
                    className="searchIcon"
                  >
                    <img src={searchIcon} alt="Search Icon" />
                  </Button> */}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
      </div>
    );
  }
}

export default Header;
