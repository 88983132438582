import axios from "axios";
import { trackPromise } from 'react-promise-tracker';
const API_URL =process.env.REACT_APP_API_URL;

class EnquiryService {

    SaveReferenceEnquirySrv(data) {
    return trackPromise(axios.post(API_URL + '/api/Enquiry/SaveEnquiry',data));
  }

  GetCountriesSrv() {
    return trackPromise(axios.post(API_URL + '/api/Enquiry/GetCountries'));
  }

  GetStatesSrv(data) {
    return trackPromise(axios.post(API_URL + '/api/Enquiry/GetStates',data));
  }

  GetCitiesSrv(data) {
    return trackPromise(axios.post(API_URL + '/api/Enquiry/GetCities',data));
  }
}

export default new EnquiryService();
