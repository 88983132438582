export const details = [


  {
    title: "S P A C E B A R – Locking mechanism in Godrej typewriters - Yay or nah?",
    date: "July 2024",
    img: "JulyDom2024.png",
    link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_July2024_index.html",
},



  {
    title: "Grey Matter",
    date: "June 2024",
    img: "JuneDom2024.png",
    link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_June2024_index.html",
},


  {
      title: "Remembering Sohrab Godrej",
      date: "May 2024",
      img: "MayDom2024.png",
      link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_May2024_index.html",
  },


  {
      title: "Protecting the Godrej identity",
      date: "April 2024",
      img: "AprDom2024.png",
      link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_Apr2024_index.html",
  },


{
  title: "AB Godrej Typewriter – a timeless triumph",
  date: "March 2024",
  img: "MarDom2024.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_Mar2024_index.html",
},


  {
      title: "Nisarga Mitra – Mr. Sohrab Godrej",
      date: "February 2024",
      img: "FebDom2024.png",
      link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_Feb2024_index.html",
  },

{
  title: "Inspiring reformers and changemakers!",
  date: "January 2024",
  img: "JanDom2024.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_Jan2024_index.html",
},


{
  title: "Homage to the Founding Father",
  date: "December 2023",
  img: "DecDom2023.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_DEC2023_index.html",
},


{
  title: "Bombay under siege!",
  date: "November 2023",
  img: "NovDom2023.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_NOV2023_index.html",
},


{
title: "Call me by your extension line",
date: "October 2023",
img: "OctDom2023.png",
link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_Oct2023_index.html",
},


{
  title: "Godrej typewriters – a top notch sales game!",
  date: "September 2023",
  img: "SeptDom2023.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_Sept2023_index.html",
},
{
  title: "Wit and wisdom of a sales memo",
  date: "August 2023",
  img: "augDom2023.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_Aug2023_index.html",
},
  {
      title: "A Glimpse into the Past",
      date: "July 2023",
      img: "julyDom2023Article.jpg",
      link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_July2023_index.html",
  },
{
  title: "Double protection from fire and theft",
  date: "June 2023",
  img: "june2023.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_June2023_index.html",
},

{
  title: "A promise to personalize the Nav-Tal Padlock in 1959!",
  date: "MAY 2023",
  img: "may2023.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_MAY2023_index.html",
},
  {
      title: "Locking and Popping it, the Godrej way.",
      date: "APRIL 2023",
      img: "April2023.jpg",
      link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_APR2023_index.html",
  },
  {
      title: "Circa 1920!",
      date: "MARCH 2023",
      img: "March2023.png",
      link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_MAR2023_index.html",
  },
{
  title: "Throwback to IMTEX 72'!",
  date: "FEBRUARY 2023",
  img: "February2023.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_FEB2023_index.html",
},
{
  title: "The Godrej Story",
  date: "JANUARY 2023",
  img: "January2023.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_JAN2023_index.html",
},
{
  title: "A chair for all seasons!",
  date: "DECEMBER 2022",
  img: "December2022.jpg",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_Dec2022_index.html",
},
{
  title: "Historically Speaking!",
  date: "NOVEMBER 2022",
  img: "November2022.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_Nov2022_index.html",
},
{
  title: "Mr. Sohrab P Godrej's Guiding Light",
  date: "OCTOBER 2022",
  img: "October2022.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_Oct2022_index.html",
},
{
  title: "Godrej on the Silver Screen!",
  date: "SEPTEMBER 2022",
  img: "September2022.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_Sept2022_index.html",
},
{
  title: "Godrej's Good Samaritans! ",
  date: "AUGUST 2022",
  img: "AUG2022.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_Aug2022_index.html",
},
{
  title: "What's better than 9? Perhaps 8!",
  date: "JULY 2022",
  img: "JULY2022.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_JULY2022_index.html",
},
{
  title: "Sowing the Seeds of Manufacturing in Vikhroli",
  date: "JUNE 2022",
  img: "JUNE2022.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_JUNE2022_index.html",
},
{
  title: "New beginnings for the Godrej Swivel Chair!",
  date: "MAY 2022",
  img: "MAY2022.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_MAY2022_index.html",
},
{
  title: "What the Consumer Wants!",
  date: "APRIL 2022",
  img: "April2022.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_APR2022_index.html",
},
{
  title: "Safekeeping with Godrej",
  date: "MARCH 2022",
  img: "March2022.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_MAR2022_index.html",
},
{
  title: "A tribute to the Mahatma!",
  date: "FEBRUARY 2022",
  img: "February2022.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_FEB2022_index.html",
},
{
  title: "Time to say Goodbye!",
  date: "JANUARY 2022",
  img: "January2022.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_JAN2022_index.html",
},
{
  title: "It's Patented!",
  date: "DECEMBER 2021",
  img: "December2021.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_DEC2021_index.html",
},
{
  title:
    "The arrival of mid-century modernism revolutionised the way we perceived our built environment, homes, and offices.",
  date: "NOVEMBER 2021",
  img: "November2021.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_NOV2021_index.html",
},
{
  title:
    "A special gesture by Godrejites from the Diwali season of 1965, the year of the Indo-Pakistan War!",
  date: "OCTOBER 2021",
  img: "October2021.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_Oct2021_index.html",
},
{
  title: "New Patent unlocked!",
  date: "SEPTEMBER 2021",
  img: "September2021.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_Sept2021_index.html",
},
{
  title: "Godrej Tumbler Padlocks - A class apart!",
  date: "AUGUST 2021",
  img: "August2021.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/dom_Aug2021_index.html",
},
{
  title: "Godrej Refrigerator Model - 7",
  date: "JULY 2021",
  img: "July2021.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM-July2021.pdf",
},
{
  title:
    "On 15th February 2017, ISRO's Polar Satellite Launch Vehicle (PSLV) successfully launched...",
  date: "JUNE 2021",
  img: "June2021.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM-Jun2021.pdf",
},
{
  title:
    "Marking 50 years of manufacturing locks, in 1947, Godrej & Boyce introduced a range of padlocks,...",
  date: "MAY 2021",
  img: "May2021.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM-May2021.pdf",
},
{
  title: "Amidst the pandemic, a new work year has dawned upon us...",
  date: "APRIL 2021",
  img: "April2021.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM-Apr2021.pdf",
},
{
  title:
    "When archival documents serendipitously unearth product stories,...",
  date: "MARCH 2021",
  img: "March2021.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM-Mar2021.pdf",
},
{
  title:
    "On 22nd February 1966, Pirojsha Godrej wrote to Dr. D N Gokhale, Superintendent of Udayachal Schools,...",
  date: "FEBRUARY 2021",
  img: "February2021.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM-Feb2021.pdf",
},
{
  title:
    "Throughout January, across our social media platforms, we are revisiting some fun moments and joyful events orchestrated by the Godrej Staff Club...",
  date: "JANUARY 2021",
  img: "January2021.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM-Jan2021.pdf",
},
{
  title:
    "India's Independence was a joyous occasion; however, only post-independence, India came to be recognized as a separate entity on the world map...",
  date: "DECEMBER 2020",
  img: "December2020.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM-Dec2020.pdf",
},
{
  title:
    "This patriotic song was sung for the first time by Lata Mangeshkar on 26th January 1963....",
  date: "NOVEMBER 2020",
  img: "November2020.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Nov2020.pdf",
},
{
  title: "Information technology experts predict that the workstation....",
  date: "OCTOBER 2020",
  img: "October2020.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Oct2020.pdf",
},
{
  title:
    "This ‘Document of the Month’ is a simple Circular from September 1949...",
  date: "SEPTEMBER 2020",
  img: "September2020.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Sept_2020.pdf",
},
{
  title:
    "On India’s 73rd Independence Day, we remind you of a popular story about Ardeshir Godrej...",
  date: "AUGUST 2020",
  img: "August2020.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Aug_2020.pdf",
},
{
  title:
    "In this Document of the Month, we bring to you a Circular from 1952 informing that from 15th July onwards,.....",
  date: "JULY 2020",
  img: "July2020.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_July_2020.pdf",
},
{
  title:
    "On 3rd June 2020 we celebrated the 108th birth anniversary of Sohrab Godrej,...",
  date: "JUNE 2020",
  img: "June2020.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_June_2020.pdf",
},
{
  title:
    "In 1955, Godrej launched Model M-9 - the first all Indian typewriter,...",
  date: "MAY 2020",
  img: "May2020.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_May_2020.pdf",
},
{
  title:
    "That’s how far back The Godrej Group released its first corporate film ever. ...",
  date: "APRIL 2020",
  img: "April2020.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Apr_2020.pdf",
},
{
  title:
    "In this ‘Document of the month’ we bring you an endearing story from the past...",
  date: "FEBRUARY 2020",
  img: "February2020.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Feb_2020.pdf",
},
{
  title:
    "At Godrej, we have more than one reason to celebrate the Indian Republic Day!...",
  date: "JANUARY 2020",
  img: "January2020.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Jan_2020.pdf",
},
{
  title:
    "It’s that time of the year when the festivities reach its peak and we’re throwing it way back to 1963...",
  date: "DECEMBER 2019",
  img: "December2019.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Dec_2019.pdf",
},
{
  title:
    "On Children's Day, we celebrated the birthday of a political leader, long gone but continuing to live on in our memories...",
  date: "NOVEMBER 2019",
  img: "November2019.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Nov_2019.pdf",
},
{
  title: "Colours are more than a mere assortment of shades and pigments...",
  date: "OCTOBER 2019",
  img: "October2019.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Oct_2019.pdf",
},
{
  title:
    "The first ever South Zone Stockists Conference was a five-day engagement held at the Madras Branch from 25th to 29th September, 1962...",
  date: "SEPTEMBER 2019",
  img: "September2019.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Sept_2019.pdf",
},
{
  title: "In 1918, the then Nizam of Hyderabad Nawab Osman Ali Khan, .....",
  date: "AUGUST 2019",
  img: "August2019.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Aug_2019.pdf",
},
{
  title:
    "It was 1962. Fifteen years had passed post independence. The Godrej spirit of.....",
  date: "JULY 2019",
  img: "July2019.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_July_2019.pdf",
},
{
  title:
    "The construction of Plant 1 began at Vikhroli 71 years ago on 1st June 1948.....",
  date: "JUNE 2019",
  img: "June2019.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_JUNE_2019.pdf",
},
{
  title: "When J R D Tata became the Chairman of the Tata Group in 1938...",
  date: "MAY 2019",
  img: "May2019.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_May_2019.pdf",
},
{
  title:
    "When artistic and embellished teakwood cupboards were a rage in the market.....",
  date: "APRIL 2019",
  img: "April2019.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Apr_2019.pdf",
},
{
  title: "This March, as we celebrate International Women’s Day.....",
  date: "MARCH 2019",
  img: "March2019.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Mar_2019.pdf",
},
{
  title:
    "February is an iconic month as many landmark events in the journey of typewriters happen to fall in this month.....",
  date: "FEBRUARY 2019",
  img: "February2019.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Feb_2019.pdf",
},
{
  title: "The beginning of Godrej soaps can be traced to 1918.....",
  date: "JANUARY 2019",
  img: "January2019.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM-JAN2019.pdf",
},
{
  title:
    "The beginning of Godrej soaps can be traced to 1918, when our founder........",
  date: "DECEMBER 2018",
  img: "December2018.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/dom_dec_2018.pdf",
},
{
  title:
    "Even before ‘Employee Engagement’ became the buzz word in corporate circles....",
  date: "NOVEMBER 2018",
  img: "November2018.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Nov_2018.pdf",
},
{
  title:
    "Personal Letters can make the reader travel into the fascinating world of emotions of the writer and experience his/her feelings....",
  date: "OCTOBER 2018",
  img: "October2018.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Oct_2018.pdf",
},
{
  title:
    "Geographies play a crucial role in determining the course of businesses in the same way that businesses have an enduring influence on the landscapes that they are part of.",
  date: "SEPTEMBER 2018",
  img: "September2018.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Sept_2018.pdf",
},
{
  title:
    "Industrial Revolution significantly altered the way time has been observed by traditional societies.",
  date: "AUGUST 2018",
  img: "August2018.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Aug_2018.pdf",
},
{
  title:
    "Godrej & Boyce was the first in India to manufacture a refrigerator way back in 1958.",
  date: "JULY 2018",
  img: "July2018.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM-JULY2018.pdf",
},
{
  title:
    "Pirojsha Godrej exemplified the new spirit in modern industrial outlook by developing an array of steel furniture in the 1930’s.",
  date: "JUNE 2018",
  img: "June2018.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM-JUN2018.pdf",
},
{
  title:
    "Godrej was the first Indian company to manufacture an all-Indian typewriter in 1955.",
  date: "MAY 2018",
  img: "May2018.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM-MAY2018.pdf",
},
{
  title:
    "Introduced in the late 1940s, Godrej Storwel was the steel cupboard designed for homes and offices in India.",
  date: "APRIL 2018",
  img: "April2018.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM-APR2018.pdf",
},
{
  title: "Businesses do not work in vacuum. .........",
  date: "MARCH 2018",
  img: "March2018.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM-MAR2018.pdf",
},
{
  title:
    "After sales service is a crucial function for any business. .........",
  date: "FEBRUARY 2018",
  img: "February2018.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM-FEB2018.pdf",
},
{
  title:
    "Since its introduction in 1954, Nav-tal has been guarding Indian homes and is considered synonymous with trust......",
  date: "JANUARY 2018",
  img: "January2018.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM-JAN2018.pdf",
},
{
  title:
    "Godrej was the first Indian company to venture into the manufacturing of forklift trucks in 1961...",
  date: "DECEMBER 2017",
  img: "December2017.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Dec2017.pdf",
},
{
  title: "The 1st of May marks the anniversary of the Godrej dispensary..",
  date: "MAY 2017",
  img: "May2017.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/may2017.pdf",
},
{
  title:
    "Remember when the Aadhar card was introduced? Standing in the queues for application, biometrics, etc.,",
  date: "MARCH 2017",
  img: "March2017.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/march2017.pdf",
},
{
  title:
    "In a newly independent India, several industries shared the national vision of economic self-reliance with the State.",
  date: "JULY 2016",
  img: "July2016.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/July2016.pdf",
},
{
  title:
    "The leaders of independent India saw technology as the way forward...",
  date: "JUNE 2016",
  img: "June2016.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/June2016.pdf",
},
{
  title: "Central Sales Tax Act as applicable to Gujarat State",
  date: "MAY 2016",
  img: "May2016.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/May_DOM_2016.pdf",
},
{
  title: "Green revolution & the Business Outlook, 1970",
  date: "DECEMBER 2014",
  img: "December2014.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/dec_DOM2014.pdf",
},
{
  title: "Godrej Storwel and Allwyn's Utility Cabinets - A comparison, 1954",
  date: "NOVEMBER 2014",
  img: "November2014.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/nov_DOM2014.pdf",
},
{
  title: "Empowering workers through education, 1964. ...",
  date: "OCTOBER 2014",
  img: "October2014.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/oct_DOM2014.pdf",
},
{
  title:
    "In congruence with the Swadeshi sentiment of its founders, Godrej introduced the M9, the first All-Indian typewriter, in 1955. ........",
  date: "SEPTEMBER 2014",
  img: "September2014.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/sept_DOM2014.pdf",
},
{
  title:
    "Between 1976 and 1982, Godrej did a complete overhaul of the Sales and Marketing organization...",
  date: "AUGUST 2014",
  img: "August2014.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Aug_DOM2014.pdf",
},
{
  title: "In 1897, G&B started operations with the manufacture of locks.",
  date: "JULY 2014",
  img: "July2014.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/July_DOM2014.pdf",
},
{
  title:
    "The idea of manufacturing a completely Indian typewriter was conceived in 1942 by Naval P Godrej. ",
  date: "JUNE 2014",
  img: "June2014.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/June_DOM2014.pdf",
},
{
  title:
    "This month we celebrate the work of our Garden department, which since its initiation in 1956...",
  date: "MARCH 2014",
  img: "March2014.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Mar_DOM2014.pdf",
},
{
  title:
    "Naoroji Godrej, fondly known as Naval, joined Godrej & Boyce Mfg. Co. Ltd in 1933...",
  date: "JANUARY 2014",
  img: "January2014.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Jan_DOM2014.pdf",
},
{
  title:
    "Within a decade of its launch in 1958, Godrej refrigerators had become a household name...",
  date: "DECEMBER 2013",
  img: "December2013.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Dec_DOM2013.pdf",
},
{
  title: "By the end of mid-19th century, Kanpur (Uttar Pradesh, India)...",
  date: "NOVEMBER 2013",
  img: "November2013.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Nov_DOM2013.pdf",
},
{
  title:
    "'The outbreak of the Second World War also saw the emergence of machine tool production...",
  date: "OCTOBER 2013",
  img: "October2013.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Oct_DOM2013.pdf",
},
{
  title:
    "The Indian constitution, adopted in 1950, declared Hindi as the official language...",
  date: "SEPTEMBER 2013",
  img: "September2013.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Sept_DOM2013.pdf",
},
{
  title: "As India celebrates her 66th Independence Day...",
  date: "AUGUST 2013",
  img: "August2013.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/August_DOM2013.pdf",
},
{
  title:
    "In its journey of industrialisation post-independence, India quickly realised...",
  date: "JULY 2013",
  img: "July2013.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/July_DOM2013.pdf",
},
{
  title:
    "This month we bring to you a newspaper story on the launch of R K Laxman's audio novel 'The Messenger' in June 1996 ...",
  date: "JUNE 2013",
  img: "June2013.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/june2013_DOM.pdf",
},
{
  title:
    "Godrej was closely associated with Panchauli (1992) and Kinnaur (1994) expeditions...",
  date: "MAY 2013",
  img: "May2013.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/may2013_DOM.pdf",
},
{
  title:
    "This month we bring to you a letter written by Sohrab Godrej to Mr. R Prasad...",
  date: "APRIL 2013",
  img: "April2013.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/April2013_DOM.pdf",
},
{
  title: "Sohrab Godrej and Population Foundation of India.",
  date: "MARCH 2013",
  img: "March2013.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Dom_Mar_2013.pdf",
},
{
  title:
    "With the arrival of low-cost computers in early 80s, India witnessed the beginning of the era of personal computers.",
  date: "FEBRUARY 2013",
  img: "February2013.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_feb_2013.pdf",
},
{
  title:
    "On January 1999, Mr. Sohrab Godrej was bestowed with yet another honour for his relentless efforts...",
  date: "JANUARY 2013",
  img: "January2013.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/GoldenPeacockAwards1991.pdf",
},
{
  title:
    "This month we bring to you a letter by Sohrab Godrej that laid the foundation of Godrej Archives...",
  date: "DECEMBER 2012",
  img: "December2012.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/December2012_DOM.pdf",
},
{
  title: "Preservation of trees was close to Sohrab Godrej's heart…",
  date: "NOVEMBER 2012",
  img: "November2012.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_nov_2012.pdf",
},
{
  title:
    "Inspired by Gandhi's doctrine of trusteeship, Sohrab Godrej dedicated himself to various public causes.",
  date: "OCTOBER 2012",
  img: "October2012.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_oct_2012.pdf",
},
{
  title: "Inspired by Nehru's beliefs in international fellowship",
  date: "SEPTEMBER 2012",
  img: "Sepember2012.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/dom_sep_2012.pdf",
},
{
  title: "At the turn of the 20th century, as the Indian economy",
  date: "AUGUST 2012",
  img: "August2012.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/dom_Aug_2012.pdf",
},
{
  title: "The French Connection",
  date: "JULY 2012",
  img: "July2012.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/july2012.pdf",
},
{
  title: "Sohrab Godrej: The Environmentalist",
  date: "JUNE 2012",
  img: "June2012.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/june2012.pdf",
},
{
  title:
    "Our padlocks was received in April 1954 from Mr.Pallonji, the Godrej men took a serious note of it",
  date: "APRIL 2012",
  img: "April2012.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/APR_2012.pdf",
},
{
  title:
    "Company declares holiday for employees to receive Ration Cards, 1943",
  date: "MARCH 2012",
  img: "March2012.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/MARCH_2012.pdf",
},
{
  title:
    "Godrej became the first Indian company to provide material handling solutions with the introduction of forklift trucks in 1963un",
  date: "FEBRUARY 2012",
  img: "February2012.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/FEB_2012.pdf",
},
{
  title: "Packing of Godrej Tabular chairs CH-4, CH-6 & CH-7",
  date: "JANUARY 2012",
  img: "January2012.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Archives_Jan2012.pdf",
},
{
  title: "First step towards greener future, 1955",
  date: "DECEMBER 2011",
  img: "December2011.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_dec2011.pdf",
},
{
  title: "Vikhroli gets a unique telegraph code, 1967",
  date: "NOVEMBER 2011",
  img: "November2011.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Nov_2011.pdf",
},
{
  title: "Udayachal High School A Decade of Progress, 1965",
  date: "OCTOBER 2011",
  img: "October2011.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Oct_UHS.pdf",
},
{
  title:
    "Connecting the township to Bombay city Eastern Express Highway, 1957",
  date: "SEPTEMBER 2011",
  img: "September2011.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Sept_highway_access.pdf",
},
{
  title: "Pragati Kendra, 15th August, 1955",
  date: "AUGUST 2011",
  img: "August2011.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Aug_2011.pdf",
},
{
  title:
    "Plant-1, 60 years ago Pirojsha Godrej's letter to Central Bank of India, July, 1951",
  date: "JULY 2011",
  img: "July2011.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/July_2011.pdf",
},
{
  title: "Story of the Post Office",
  date: "JUNE 2011",
  img: "June2011.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/June_2011.pdf",
},
{
  title: "Floor Layout of Baithi Chawl, 1953",
  date: "MAY 2011",
  img: "May2011.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/May_2011.pdf",
},
{
  title: "List of Products and corresponding plants, 1961",
  date: "APRIL 2011",
  img: "April2011.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/April_2011.pdf",
},
{
  title: "Bird's-eye view of Pirojshanagar (Godrej township), 1959",
  date: "MARCH 2011",
  img: "March2011.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Mar_2011.pdf",
},
{
  title: "Application for construction of Plant-1, 1948",
  date: "FEBRUARY 2011",
  img: "February2011.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Archives_Feb.pdf",
},
{
  title: "In the year 1950, Godrej introduced 'Godrej Washing Soap Grains'.",
  date: "DECEMBER 2010",
  img: "December2010.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Document_of_the_Month.pdf",
},
{
  title:
    "In the November of 1956, a post card from Bhavnagar reached the dispatch department of the Godrej Lalbaug factory.",
  date: "NOVEMBER 2010",
  img: "November2010.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Nov2010.pdf",
},
{
  title: "The connection of Parsis and Paris goes almost 100 years back",
  date: "OCTOBER 2010",
  img: "October2010.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Oct2010.pdf",
},
{
  title:
    "In the early 20th century, the demand for European soaps by Indians",
  date: "SEPTEMBER 2010",
  img: "September2010.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Sept2010.pdf",
},
{
  title: "Today, we live in a world surrounded by brand names.",
  date: "AUGUST 2010",
  img: "August2010.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Aug2010.pdf",
},
{
  title:
    "On 7th July, 1958 a circular was issued by All India Sales Senior Manager",
  date: "JULY 2010",
  img: "July2010.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_July2010_int.pdf",
},
{
  title: "Indian consumers trust and rely on Godrej Nav-tal for strong",
  date: "MAY 2010",
  img: "May2010.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_May2010.pdf",
},
{
  title: "1947 - The year India was divided",
  date: "MARCH 2010",
  img: "March2010.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Mar2010.pdf",
},
{
  title:
    "I had never been so happy in my life. Everywhere I have been since I left you, I have talked of my love for Vikhroli - not just for India, but Vikhroli, said Lillian Carter",
  date: "FEBRUARY 2010",
  img: "February2010.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Feb10.pdf",
},
{
  title:
    "At the dawn of independence, India dreamt of being self-sufficient in agricultural",
  date: "JANUARY 2010",
  img: "January2010.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Jan10_DOM.pdf",
},
{
  title:
    "Godrej manufactured over 16 lakh ballot boxes for independent India's first general election",
  date: "DECEMBER 2009",
  img: "December2009.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_dec09.pdf",
},
{
  title:
    "In 1817, the bicycle was invented by Baron Karl von Drais, a German. Its rider sat astride",
  date: "NOVEMBER 2009",
  img: "November2009.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_nov09.pdf",
},
{
  title:
    "This month we bring to you a letter written by Mr. S. Grahame-Armstrong requesting the 'Godrej Safe Company'",
  date: "OCTOBER 2009",
  img: "October2009.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_oct09.pdf",
},
{
  title:
    "In September 1965, India declared war on Pakistan in response to Pakistani army offensives in Kashmir.",
  date: "SEPTEMBER 2009",
  img: "September2009.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_sept09.pdf",
},
{
  title: "WE REMEMBER YOU!",
  date: "AUGUST 2009",
  img: "August2009.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/AUG09.pdf",
},
{
  title: "After experiencing two centuries of capitalistic imperialism",
  date: "JULY 2009",
  img: "July2009.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_july09.pdf",
},
{
  title:
    "The history of imparting formal technical education in India can be traced back to the mid 19th century",
  date: "JUNE 2009",
  img: "June2009.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_june09.pdf",
},
{
  title: "Record keeping always has been important to organisations",
  date: "MAY 2009",
  img: "May2009.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_may09.pdf",
},
{
  title: "The first two decades of the twentieth century witnessed",
  date: "MARCH 2009",
  img: "March2009.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Mar09.pdf",
},
{
  title: "Independent India's first general elections",
  date: "FEBRUARY 2009",
  img: "February2009.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Feb09.pdf",
},
{
  title: "India embarked on a regime of import restrictions during the 1950s",
  date: "JANUARY 2009",
  img: "January2009.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/DOM_Jan09.pdf",
},
{
  title:
    "1st December 2008 being the 36th Death Anniversary of Late Mr. Pirojsha Godrej (Chairman 1936-1972),",
  date: "DECEMBER 2008",
  img: "December2008.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Dec2008.pdf",
},
{
  title:
    "Being an imperial colony of Great Britain, India (modern-day India and parts of Pakistan and Bangladesh)",
  date: "NOVEMBER 2008",
  img: "November2008.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Nov2008.pdf",
},
{
  title:
    "In the year 1967, Godrej & Boyce forayed into the international scene with its maiden venture in Malaysia",
  date: "OCTOBER 2008",
  img: "October2008.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Oct2008.pdf",
},
{
  title:
    "Godrej Archives is delighted to bring to you the Godrej Supplement of 1955.",
  date: "SEPTEMBER 2008",
  img: "September2008.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Sept2008.pdf",
},
{
  title:
    "On the 15th of this month, India will celebrate her 61st Independence Day",
  date: "AUGUST 2008",
  img: "August2008.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Aug2008.pdf",
},
{
  title: "GODREJ CENTENARY STAMP",
  date: "JULY 2008",
  img: "July2008.png",
  link: "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/July2008.pdf",
},
];
