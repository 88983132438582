import axios from "axios";
import { trackPromise } from 'react-promise-tracker';
const API_URL =process.env.REACT_APP_API_URL;

class SubscribeService {

    SaveSubscribeSrv(data) {
    
    return trackPromise(axios.post(API_URL + '/api/Subscribe/SaveSusbcribe',data));
  }

  GetPrivacyPolicySrv(data) {
    
    return trackPromise(axios.post(API_URL + '/api/PrivacyPolicy/GetPrivacyPolicy',data));
  }
}

export default new SubscribeService();
