import React, { Component } from "react";
import "./OurCollection.scss";
import Header from "../../../shared-components/Header/Header";
import Footer from "../../../shared-components/Footer/Footer";
import maskGroup202 from "../../../assets/images/what-we-offer/our-collections/manuScripts/MaskGroup202.png";
import manuscripts5 from "../../../assets/images/what-we-offer/our-collections/manuScripts/Manuscripts5.png";
import manuscripts2 from "../../../assets/images/what-we-offer/our-collections/manuScripts/Manuscripts2.png";
import manuscripts3 from "../../../assets/images/what-we-offer/our-collections/manuScripts/Manuscripts3.png";
import manuscripts4 from "../../../assets/images/what-we-offer/our-collections/manuScripts/Manuscripts4.png";
import photograph1 from "../../../assets/images/what-we-offer/our-collections/photographs/Photographs1.png";
import photograph2 from "../../../assets/images/what-we-offer/our-collections/photographs/Photographs2.png";
import photograph3 from "../../../assets/images/what-we-offer/our-collections/photographs/Photographs3.png";
import photograph4 from "../../../assets/images/what-we-offer/our-collections/photographs/Photographs4.png";
import photograph5 from "../../../assets/images/what-we-offer/our-collections/photographs/Photographs5.png";
import oralHistory1 from "../../../assets/images/what-we-offer/our-collections/oral-history/OralHistory1.png";
import oralHistory2 from "../../../assets/images/what-we-offer/our-collections/oral-history/OralHistory2.png";
import memorabilia1 from "../../../assets/images/what-we-offer/our-collections/memorabilia/Memorabilia1.png";
import memorabilia2 from "../../../assets/images/what-we-offer/our-collections/memorabilia/Memorabilia2.png";
import memorabilia3 from "../../../assets/images/what-we-offer/our-collections/memorabilia/Memorabilia3.png";
import memorabilia4 from "../../../assets/images/what-we-offer/our-collections/memorabilia/Memorabilia4.png";
import memorabilia5 from "../../../assets/images/what-we-offer/our-collections/memorabilia/Memorabilia5.png";
import printedMaterial1 from "../../../assets/images/what-we-offer/our-collections/printed-material/PrintedMaterial1.png";
import printedMaterial2 from "../../../assets/images/what-we-offer/our-collections/printed-material/PrintedMaterial2.png";
import printedMaterial3 from "../../../assets/images/what-we-offer/our-collections/printed-material/PrintedMaterial3.png";
import printedMaterial4 from "../../../assets/images/what-we-offer/our-collections/printed-material/PrintedMaterial4.png";
import printedMaterial5 from "../../../assets/images/what-we-offer/our-collections/printed-material/PrintedMaterial5.png";
import group15104 from "../../../assets/images/what-we-offer/our-collections/audio-visual/Group15104.png";
import group15184 from "../../../assets/images/what-we-offer/our-collections/audio-visual/Group15184.png";
import audioVisual1 from "../../../assets/images/what-we-offer/our-collections/audio-visual/AudioVisual1.mp4";
import audioVisual2 from "../../../assets/images/what-we-offer/our-collections/audio-visual/AudioVisual2.mp4";

import { Container, Nav, Navbar, Modal, Button } from "react-bootstrap";

export class OurCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article1: true,
      article2: false,
      article3: false,
      article4: false,
      article5: false,
      article6: false,
      show1: false,
      show2: false,
    };
  }
  handleButtonStateChange = (toggleKey) => {
    const stateCopy = { ...this.state };
    const nextValue = !stateCopy[toggleKey];
    Object.keys(stateCopy).forEach((key) => (stateCopy[key] = false));
    stateCopy[toggleKey] = nextValue;
    this.setState(stateCopy);
  };

  render() {
    const options = {
      items: 1,
      nav: true,
      autoplay: false,
      loop: false,
      rewindNav: false,
      navText: [
        "<div class='nav-btn prev-slide'></div>",
        "<div class='nav-btn next-slide'></div>",
      ],
      stagePadding: 50,
    };
    return (
      <div className="ourCollectionNew">
        <Header />
        <div className="innerBanner ourCollectionbanner text-center">
          <div className="bannerInfo">
            <h1>Our collection</h1>
            <p>
            Our collection documents not only the journey of many of the Godrej businesses but also narrates <br></br>the story of a nation on the rise and celebrates people and institutions who made it all possible!
            </p>
          </div>
        </div>

        <div className="collectionService pt-90">
          <div className="container p-4 p-lg-0">
            <h2 className="secHeader redBorder mb-3">Collection Guides </h2>
            <p class="mb-0 mb-lg-3">Godrej Archives is a repository of records that shed light 
              on the product histories, business leaders, milestones, events, etc 
              from almost 125 years of Godrej history. Most importantly, they preserve 
              the legacy and values cherished by the company, and thus communicate the brand ethos of Godrej.
               Currently, the collection houses more than 16000 files and over one lakh items.</p>
              </div> 
            <div>
              <Navbar style={{ background: "#E8EBF1"}}>
              <div className="container p-0">
                  <Nav className="text-center">
                    <Nav.Link
                      onClick={() => this.handleButtonStateChange("article1")}
                      active={this.state.article1}
                      disabled={this.state.article1}
                    >
                      Manuscripts Collection <br></br>
                       (1836-2022)
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => this.handleButtonStateChange("article2")}
                      active={this.state.article2}
                      disabled={this.state.article2}
                    >
                      Photographs Collection <br></br>
                       (1880-2022)
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => this.handleButtonStateChange("article3")}
                      active={this.state.article3}
                      disabled={this.state.article3}
                    >
                      Audio-Visuals Collection <br></br>
                       (1960-2022)
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => this.handleButtonStateChange("article4")}
                      active={this.state.article4}
                      disabled={this.state.article4}
                    >
                      Oral History Collection <br></br>
                      (2006-2022)
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => this.handleButtonStateChange("article5")}
                      active={this.state.article5}
                      disabled={this.state.article5}
                    >
                      Memorabilia <br></br>
                      (1893-2022)
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => this.handleButtonStateChange("article6")}
                      active={this.state.article6}
                      disabled={this.state.article6}
                    >
                      Printed Material <br></br>
                       (1920-2022)
                    </Nav.Link>
                  </Nav>
                  </div>
              </Navbar>
              <br />
            </div>
            <div className="container px-4 py-0 p-lg-0">
            <div>
              {this.state.article1 && (
                <>
                  <p className="mb-4">
                    Paper documents which include key correspondence, business
                    letters, writings, speeches, annual reports, advertisements,
                    product catalogues, sales and marketing literature,
                    technical drawings, newspaper clippings, policy circulars,
                    customer appreciation letters, in-house magazines etc. form
                    a part of the extensive Manuscripts collection.                 
                    Some priceless ones in the collection are the first patent
                    granted to Pirojsha Godrej in the year 1908-09 for springless lock, old advertisements (1914 onwards) of Godrej
                    products in newspapers, old catalogues of products (1910
                    onwards) etc.
                  </p>
                  {/* <div className="row">
                    <div className="col-md-12 pb-3">
                      <h6><u>Explore our Centenary Collection</u></h6>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-sm-5">
                      <figure>
                        <img
                          src={maskGroup202}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <div className="col-sm-7">
                      <figure>
                        <img
                          src={manuscripts5}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <figure>
                        <img
                          src={manuscripts2}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <div className="col-sm-4">
                      <figure>
                        <img
                          src={manuscripts3}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <div className="col-sm-4">
                      <figure>
                        <img
                          src={manuscripts4}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div>
              {this.state.article2 && (
                <>
                  <p className="mb-4">
                    This collection which embraces the entire gamut of
                    activities at Godrej group contains over
                    53,000 photographs. These include photographs of Godrej
                    family members and their personal and official functions,
                    inauguration of showrooms, important events held at Vikhroli
                    and other locations across the country, visits of
                    dignitaries, of manufacturing plants, products, etc.
                    Photographs chronicling work and life at Godrej clicked by Mitter Bedi, the famed industrial photographer, in 1970s are one of the most prized collections of the archives.
                  </p>
                  {/* <div className="row">
                    <div className="col-md-12 pb-3">
                      <h6><u>Explore our Centenary Collection</u></h6>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-sm-7">
                      <figure>
                        <img
                          src={photograph1}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                      <figure>
                        <img
                          src={photograph2}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <div className="col-sm-5">
                      <figure>
                        <img
                          src={photograph3}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                      <figure>
                        <img
                          src={photograph4}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                      <figure>
                        <img
                          src={photograph5}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div>
              {this.state.article3 && (
                <>
                  <p className="mb-4">
                    This contains video cassettes, audio cassettes, CDs, DVDs
                    capturing events, advertisements, corporate films, speeches
                    and television news coverage. The most iconic among these is
                    Godrej’s first corporate film produced in the 1960s – The
                    Godrej Story – directed by B D Garga, one of India’s most
                    well-known documentary filmmakers.
                  </p>
                  {/* <div className="row">
                    <div className="col-md-12 pb-3">
                      <h6><u>Explore our Centenary Collection</u></h6>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-sm-6">
                      <figure className="pointerStyle">
                        <img
                          src={group15104}
                          className="img-fluid"
                          onClick={() => this.setState({ show1: true })}
                        ></img>
                      </figure>
                     
                          <p>Godrej lock manufacturing
Clipping from 'The Godrej Story', first corporate film of Godrej released in 1969</p>
                      <Modal show={this.state.show1} size="lg">
                        <Modal.Body centered>
                           <video width="100%" height="350" controls autoPlay controlsList="nodownload" >
                            <source src={audioVisual1} type="video/mp4" />
                          </video>
                          
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={() => this.setState({ show1: false })}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                    <div className="col-sm-6">
                      <figure className="pointerStyle">
                        <img
                          src={group15184}
                          className="img-fluid"
                          onClick={() => this.setState({ show2: true })}
                        ></img>
                      </figure>
                      <p>Godrej Storwel TVC titled 'Saajan ke aangan mein', 
                          Circa 1984</p>
                      <Modal show={this.state.show2} size="lg">
                        <Modal.Body centered>
                            <video width="100%" height="350" controls autoPlay controlsList="nodownload" >
                            <source src={audioVisual2} type="video/mp4" />
                          </video>
                          
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={() => this.setState({ show2: false })}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div>
              {this.state.article4 && (
                <>
                  <p className="mb-4">
                    Godrej Archives has recorded more than 300 oral histories so
                    far that document reminiscences of former employees,
                    dealers, vendors, and others who are associated with Godrej
                    for many years. These oral histories shed light on different aspects of history of Godrej, the country, its economy &amp; businesses, etc.


                  </p>
                  {/* <div className="row">
                    <div className="col-md-12 pb-3">
                      <h6><u>Explore our Centenary Collection</u></h6>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-sm-6">
                      <figure>
                        <img
                          src={oralHistory1}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <div className="col-sm-6">
                      <figure>
                        <img
                          src={oralHistory2}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div>
              {this.state.article5 && (
                <>
                  <p className="mb-4">
                    Actual objects i.e. products like safes, almirahs, locks,
                    soaps, refrigerator, typewriters etc. mainly of Godrej and
                    some of other brands, belong to this collection. Besides
                    these, awards, gifts, souvenirs received by the Godrej
                    family, employees and different business units (plaques and
                    certificates, prints and framed works, art works, and
                    textile items) also form a part of this collection.
                  </p>
                  {/* <div className="row">
                    <div className="col-md-12 pb-3">
                      <h6><u>Explore our Centenary Collection</u></h6>
                    </div>
                  </div> */}
                                <div className="row">
                                    <div className="col-sm-4">
                                        <figure>
                                            <img
                                                src={memorabilia1}
                                                alt="Functions"
                                                className="img-fluid"
                                            ></img>
                                        </figure>
                                    </div>
                                    <div className="col-sm-4">
                                        <figure>
                                            <img
                                                src={memorabilia3}
                                                alt="Functions"
                                                className="img-fluid"
                                            ></img>
                                        </figure>
                                    </div>
                                    <div className="col-sm-4">
                                        <figure>
                                            <img
                                                src={memorabilia2}
                                                alt="Functions"
                                                className="img-fluid"
                                            ></img>
                                        </figure>
                                    </div>
                                </div>
                    
                  <div className="row">
                    <div className="col-sm-6">
                      <figure>
                        <img
                           src={memorabilia4}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <div className="col-sm-6">
                      <figure>
                        <img
                          src={memorabilia5}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                  </div>
                  
                </>
              )}
            </div>

            <div className="printedMaterial">
              {this.state.article6 && (
                <>
                  <p className="mb-2">
                  It contains a wide range of printed literature pertaining to the Godrej Group such as company brochures, product catalogues, editions of in-house magazine earlier titled 'Godrej News' (1995-2000) and now called 'Change' (2001-2021), media coverage, Annual magazines of Udayachal Schools, publications of Pragati Kendra (Welfare Centre) and Upchar Gruha (Dispensary), Staff Club Bulletins, etc. 
                  </p>
                  {/* <ul class="list-group list-group-flush mt-1">
                    <li class="list-group-item">Company brochures published during 1970s to 1990s</li>
                    <li class="list-group-item">Old product catalogues (1920s onwards)</li>
                    <li class="list-group-item">Editions of in-house magazine earlier titled 'Godrej News' (1995-2000) and now called 'Change' (2001-2021)</li>
                    <li class="list-group-item">Media coverage (newspaper and magazine clippings etc.)</li>
                    <li class="list-group-item">Annual magazines of Udayachal Schools (1965-2000)</li>
                    <li class="list-group-item">Publications of Pragati Kendra (Welfare Centre) and Upchar Gruha (Dispensary)</li>
                    <li class="list-group-item">Old editions of Staff Club Bulletin (1972-1980)</li>
                  </ul> */}
                  {/* <div className="row">
                    <div className="col-md-12 pb-3">
                      <h6><u>Explore our Centenary Collection</u></h6>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-sm-4">
                      <figure>
                        <img
                          src={printedMaterial1}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <div className="col-sm-4">
                      <figure>
                        <img
                          src={printedMaterial2}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <div className="col-sm-4">
                      <figure>
                        <img
                          src={printedMaterial5}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-8">
                      <figure>
                        <img
                          src={printedMaterial4}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    
                      <div className="col-sm-4">
                      <figure>
                        <img
                          src={printedMaterial3}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div class="py-90"></div>
          <Footer />
        </div>
      
      </div>
    );
  }
}
export default OurCollection;
