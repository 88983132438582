import React, { Component } from "react";
import './ResearchServices.scss';
import OwlCarousel from 'react-owl-carousel2';
import latestTalksbanner1 from '../../../assets/images/latest-talk/slide1.png';
import Header from "../../../shared-components/Header/Header";
import homeIcon from "../../../assets/images/who-we-are/homeIcon.svg";
import researchBanner from "../../../assets/images/what-we-offer/research-services.png";
import researchBannerMobile from "../../../assets/images/what-we-offer/research-services-Mobile.png";
import icon1 from "../../../assets/images/what-we-offer/research-room/icon1.svg";
import icon2 from "../../../assets/images/what-we-offer/research-room/icon2.svg";
import icon3 from "../../../assets/images/what-we-offer/research-room/icon3.svg";
import icon4 from "../../../assets/images/what-we-offer/research-room/icon4.svg";
import icon5 from "../../../assets/images/what-we-offer/research-room/icon5.svg";
import icon6 from "../../../assets/images/what-we-offer/research-room/icon6.svg";
import icon7 from "../../../assets/images/what-we-offer/research-room/icon7.svg";
import icon8 from "../../../assets/images/what-we-offer/research-room/icon8.svg";
import icon9 from "../../../assets/images/what-we-offer/research-room/icon9.svg";
import icon10 from "../../../assets/images/what-we-offer/research-room/icon10.svg";
import Footer from "../../../shared-components/Footer/Footer";
import { Scrollbars } from 'react-custom-scrollbars';

export class ResearchServices extends Component {
    render() {
        const options = {
            items: 1,
            nav: true,
            autoplay: false,
            loop: false,
            rewindNav: false,
            navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
            stagePadding: 50,

        };
        return (
            <div className="researchServices">
                <Header />
                <div className="innerBanner reprographySerbanner text-center">
                    <div className="bannerInfo">
                        <h1>Research Services</h1>
                        <p>We'd love to aid you in your research journey and help you enrich your work.</p>
                    </div>
                </div>
                <div className="researchPolicy">
                    <div className="container p-0 p-lg-0">
                        {/* <figure>
                            <img src={researchBanner} alt="Research Banner" className="img-fluid d-none d-sm-none d-lg-block"></img>
                            <img src={researchBannerMobile} alt="Research Banner" className="img-fluid d-block d-sm-block d-lg-none"></img>
                        </figure> */}
                        {/* <div className="row mt-4 subInfo">
                            <div className="col-md-5 pe-5">
                                <p>Archives Staff will offer general advice on our holdings but if you would like us to search on your behalf for information within records, there may be a charge. The charges will be decided on the basis of staff time required for the same.</p>
                            </div>
                            <div className="col-md-7">
                                <h4>Operating Hours</h4>
                                <p>Consultation of Archive materials is by appointment only, during normal business hours <br></br>(Monday to Saturday, 10:00 am to 4:30 pm)</p>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="repographyService pt-90 lightOrangeBg">
                    <div className="container p-4 p-lg-0">
                        <h2 className="secHeader redBorder mb-3">Research room rules</h2>
                       
                        <div className="row mt-5">
                            <div className="col-md-4 mb-4">
                                <div className="repographyServiceGrid">
                                    <div className="icon mb-3"><img src={icon1} alt="Home Icon"></img> </div>
                                    <p>All users must fill up the User Form.</p>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <div className="repographyServiceGrid">
                                    <div className="icon mb-3"><img src={icon2} alt="Home Icon"></img> </div>
                                    <p>All users should fill up the 'Request Form for Archival Material' giving details of the archival material they require for research and hand it over to the Archives' Staff who will then get the required material for the user.</p>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <div className="repographyServiceGrid">
                                    <div className="icon mb-3"><img src={icon3} alt="Home Icon"></img> </div>
                                    <p>ONLY PENCILS may be used in the Reference Room (no pens).</p>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <div className="repographyServiceGrid">
                                    <div className="icon mb-3"><img src={icon4} alt="Home Icon"></img> </div>
                                    <p>NO POST-IT NOTES can be used to mark original documents. Archives Staff will provide book-marks to mark pages.</p>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <div className="repographyServiceGrid">
                                    <div className="icon mb-3"><img src={icon5} alt="Home Icon"></img> </div>
                                    <p>NO FOOD OR BEVERAGES are allowed in the Reference Room.</p>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <div className="repographyServiceGrid">
                                    <div className="icon mb-3"><img src={icon6} alt="Home Icon"></img> </div>
                                    <p>The use of personal portable computers will be allowed when possible. However, the use of hand-held scanners, digital cameras, and mobile with cameras is prohibited.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="repographyServiceGrid">
                                    <div className="icon mb-3"><img src={icon7} alt="Home Icon"></img> </div>
                                    <p>Archival records and objects are to be used only in the reference room.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="repographyServiceGrid">
                                    <div className="icon mb-3"><img src={icon8} alt="Home Icon"></img> </div>
                                    <p>All stack areas of the Archives are closed to users. All materials to be retrieved by the archives Staff only. No materials will be retrieved after 4:45 PM.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="repographyServiceGrid">
                                    <div className="icon mb-3"><img src={icon9} alt="Home Icon"></img> </div>
                                    <p>Materials should be handled with care and kept flat on the table. They must not be leaned on, written on, folded, or otherwise handled in any way likely to damage them.</p>
                                </div>
                            </div>
                            <div className="col-md-4 mb-5">
                                <div className="repographyServiceGrid">
                                    <div className="icon mb-3"><img src={icon10} alt="Home Icon"></img> </div>
                                    <p>When handling photographs, users must wear white cotton gloves provided by the Archives.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />

                </div>


            </div>
        )
    }
}
export default ResearchServices;
