import { Component } from "react";
import "./ThursdayTalkDetail.scss";
import Header from "../../../../shared-components/Header/Header";
import Footer from "../../../../shared-components/Footer/Footer";
import { Nav } from "react-bootstrap";
import { details } from "./AnnualTalkDetailData";
import wallCalendar from "../../../../assets/images/what-we-do/talks/wallCalendar.svg";
import timeIcon from "../../../../assets/images/what-we-do/talks/VenueIcon.svg";

export class AnnualTalkDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      param: this.props.match.id,
    };
  }
  render() {
    return (
      <>
        {details.map((data, key) => {
          if (data.id == this.state.param) {
            return (
              <div className="thursTalk">
                <Header />
                <div className="innerBanner ">
                  <div className="container">
                    <div className="row speakerDetail">
                      <div className="col-md-3">
                        <figure>
                          <img
                            src={
                              require("../../../../assets/images/what-we-do/talks/annual-detail/" +
                                data.img).default
                            }
                            className="img-fluid"
                          />
                        </figure>
                      </div>
                      <div className="col-md-9">
                        <h2 className="secHeader redBorder mb-4 mt-1">
                          {data.header}
                        </h2>
                        <div className="row mt-5">
                          <div className="col-2 col-md-1 pe-2 pe-lg-0">
                            <figure className="wallCalendar3">
                              <img src={wallCalendar} className="img-fluid" />
                            </figure>
                          </div>
                          <div className="col-4 col-md-3 ps-2 ps-lg-2">
                            <span>Date</span>
                            <h6>{data.date}</h6>
                          </div>
                          <div className="col-2 col-md-1 pe-2 pe-lg-0">
                            <figure className="wallCalendar2">
                              <img src={timeIcon} className="img-fluid" />
                            </figure>
                          </div>
                          <div className="col-4 col-md-4 ps-2 ps-lg-2">
                            <span>Venue</span>
                            <h6>{data.venue}</h6>
                          </div>
                          <div className="col-2 col-md-1 pe-2 pe-lg-0">
                            {/* <figure className="wallCalendar2">
                              <img src={venueIcon} className="img-fluid" />
                            </figure> */}
                          </div>
                          <div className="col-2 col-md-2 ps-2 ps-lg-2">
                            {/* <span>Timing</span>
                            <h6>{data.time}</h6> */}
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-4 p-0">
                            <Nav.Link href={data.imgLink} target="_blank">
                              Click here for more pictures
                            </Nav.Link>
                          </div>
                          <div className="col-md-6 ClickHere pt-2">
                            <Nav.Link href={data.videoLink} target="_blank">
                              Click here to see the video /Full Text here
                            </Nav.Link>
                          </div>
                          {/* <div className="col-md-3">
                            <span>
                              <img src={downloadIcon} alt="Download Icon"></img>
                            </span>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="row speakerDetail">
                    <div className="col-md-3">
                      <h4 class="speakerName"> Speaker</h4>
                      <h5>{data.name}</h5>
                    </div>
                    <h4 className="mt-3">About the Speaker</h4>
                    <p className="pb-3">{data.content1}</p>
                    <h4>Abstract</h4>
                    <p className="pb-5">{data.content2}</p>
                  </div>
                </div>
                <Footer />
              </div>
            );
          }
        })}
      </>
    );
  }
}

export default AnnualTalkDetail;
