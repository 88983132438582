import axios from "axios";
import { trackPromise } from 'react-promise-tracker';
const API_URL =process.env.REACT_APP_API_URL;

class FeedbackService {

    SaveAnnualFeedbackSrv(data) {
    return trackPromise(axios.post(API_URL + '/api/Feedback/SaveAnnualFeedback',data));
  }

  SaveThursdayFeedbackSrv(data) {
    return trackPromise(axios.post(API_URL + '/api/Feedback/SaveThursdayFeedback',data));
  }
}

export default new FeedbackService();
