import React, { Component } from "react";
import './TheAccessPolicy.scss';
import OwlCarousel from 'react-owl-carousel2';
import latestTalksbanner1 from '../../../assets/images/latest-talk/slide1.png'
import Header from "../../../shared-components/Header/Header"
import homeIcon from "../../../assets/images/who-we-are/homeIcon.svg"
import Icon1 from "../../../assets/images/what-we-offer/access-policy/icon1.svg"
import Icon2 from "../../../assets/images/what-we-offer/access-policy/icon2.svg"
import Icon3 from "../../../assets/images/what-we-offer/access-policy/icon3.svg"
import Icon4 from "../../../assets/images/what-we-offer/access-policy/icon4.svg"
import Icon5 from "../../../assets/images/what-we-offer/access-policy/icon5.svg"
import Icon6 from "../../../assets/images/what-we-offer/access-policy/icon6.svg"
import Icon7 from "../../../assets/images/what-we-offer/access-policy/icon7.svg"
import Icon8 from "../../../assets/images/what-we-offer/access-policy/icon8.svg"
import Icon9 from "../../../assets/images/what-we-offer/access-policy/icon9.svg"
import Icon10 from "../../../assets/images/what-we-offer/access-policy/icon10.svg"
import Footer from "../../../shared-components/Footer/Footer"
import { Scrollbars } from 'react-custom-scrollbars';
export class TheAccessPolicy extends Component {
    render() {
        const options = {
            items: 1,
            nav: true,
            autoplay: false,
            loop: false,
            rewindNav: false,
            navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
            stagePadding: 50,

        };
        return (
            <div className="accessPolicies">
                <Header />
                <div className="innerBanner reprographySerbanner text-center">
                    <div className="bannerInfo">
                        <h1>The Access Policy</h1>
                        <p>Get acquainted with our access policy as you get ready to explore our collections and unique holdings</p>
                    </div>
                </div>
                <div className="repographyService pt-90 lightOrangeBg">
                    <div className="container  p-4 p-lg-0">                        
                        
                        <div className="row">
                            <div className="col-md-4 mb-4">
                                <div className="repographyServiceGrid">
                                    <div className="icon mb-3"><img src={Icon1} alt="Home Icon"></img> </div>
                                    <p>Consultation of Archives' materials is by appointment only, during normal business hours (Monday to Friday, 10:00 am to 4:30 pm)</p>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <div className="repographyServiceGrid">
                                    <div className="icon mb-3"><img src={Icon2} alt="Home Icon"></img> </div>

                                    <p>Reference availability to archival records in possession of Godrej Archives will be open to all bonafide researchers.</p>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <div className="repographyServiceGrid">
                                    <div className="icon mb-3"><img src={Icon3} alt="Home Icon"></img> </div>

                                    <p>Bonafide researchers include students pursuing graduation, post-graduation and Ph.D, historians, academicians, Godrejites. Students have to submit the letter from the head of their respective institutions.</p>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <div className="repographyServiceGrid">
                                    <div className="icon mb-3"><img src={Icon4} alt="Home Icon"></img> </div>

                                    <p>The users must comply with the all instructions given in the access policy.</p>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <div className="repographyServiceGrid">
                                    <div className="icon mb-3"><img src={Icon5} alt="Home Icon"></img> </div>

                                    <p>Staff will offer help with use of finding aids and materials location but not help with research unless the user is willing to recompense the Archives staff for time spent.</p>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <div className="repographyServiceGrid">
                                    <div className="icon mb-3"><img src={Icon6} alt="Home Icon"></img> </div>

                                    <p>The user has to fill the User Form giving complete contact information as requested.</p>
                                </div>
                            </div>
                            {/* <div className="col-md-4">
                                <div className="repographyServiceGrid">
                                    <div className="icon mb-3"><img src={Icon7} alt="Home Icon"></img> </div>

                                    <p>Archives material cannot be taken to any other location.</p>
                                </div>
                            </div> */}
                            <div className="col-md-4">
                                <div className="repographyServiceGrid">
                                    <div className="icon mb-3"><img src={Icon8} alt="Home Icon"></img> </div>

                                    <p>Archival material cannot be taken to any other location and may not be duplicated without written permission from the Head, Godrej Archives.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="repographyServiceGrid">
                                    <div className="icon mb-3"><img src={Icon9} alt="Home Icon"></img> </div>

                                    <p>Access to closed records will be limited to individuals authorized by the record creating entity.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="repographyServiceGrid">
                                    <div className="icon mb-3"><img src={Icon10} alt="Home Icon"></img> </div>

                                    <p>Permission to examine materials is not an authorization to publish them. Separate written application for permission to publish must be made to the Archives.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />

                </div>
                

            </div>
        )
    }
}
export default TheAccessPolicy;
