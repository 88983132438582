import React, { Component, useState, useMemo } from "react";
import Header from "../../shared-components/Header/Header";
import { details } from "./ArchiveTalesData";

import "./ArchiveTales.scss";
import Footer from "../../shared-components/Footer/Footer";
import { Nav } from "react-bootstrap";
import Pagination from "./Pagination";

let PageSize = 9;

const ArchiveTales = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return details.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);
  return (
    <div className="archivesTalesNew">
      <Header />
      <div className="innerBanner archiveTalesbanner text-center">
        <div className="bannerInfo">
          <h1>Archive Tales</h1>
          <p>
            Bringing you fascinating stories of grit and innovation, of
            enterprise and humanity from our vast archival collection <br></br>that will
            shine a light on the illustrious corporate past of the Godrej Group.
          </p>
        </div>
      </div>
      <div className="blogs pt-90">
        <div className="container p-4 p-lg-0">
          <div className="filter mb-4">
            <div className="row">
              {currentTableData.map((data, key) => {
                return (
                  <div className="col-sm-6 col-lg-4 col-12 mb-5 ">
                    <div className="blogGrid">
                      <figure>
                        <img
                          src={
                            require("../../assets/images/archive-tales/" +
                              data.img).default
                          }
                          alt="Post 1"
                          className="img-fluid"
                        ></img>
                      </figure>
                      <div className="blogsInfo pt-3">
                        <div className="title">
                          <Nav.Link href={data.link} target="_blank">
                            {data.title}
                          </Nav.Link>
                        </div>
                        <div className="date mb-2">{data.date}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={details.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
      <Footer />
    </div>
  );
};

export default ArchiveTales;
