import React, { Component } from "react";
import Header from "../../shared-components/Header/Header";
import OwlCarousel from "react-owl-carousel2";
import latestTalksbanner2 from "../../assets/images/latest-talk/Businessinthetime.png";
import latestTalksbanner3 from "../../assets/images/latest-talk/Power_Privilege.png";
import latestTalksbanner4 from "../../assets/images/latest-talk/TimberTradeTrajectory.png";
import latestTalksbanner5 from "../../assets/images/latest-talk/BlueJeansTransformation.png";
import latestTalksbanner6 from "../../assets/images/latest-talk/MadMenOfBombay.png";
import latestTalksbanner7 from "../../assets/images/latest-talk/TheGlobalMerchants.png";
import latestTalksbanner8 from "../../assets/images/latest-talk/Typewriter.png";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import "./whatWedo.scss";
import exhibitions1 from "../../assets/images/what-we-do/exhibitions1.png";
import exhibitions2 from "../../assets/images/what-we-do/exhibitions2.png";
import exhibitions3 from "../../assets/images/what-we-do/exhibitions3.png";
import conference1 from "../../assets/images/what-we-do/icaMumbai.png";
import conference2 from "../../assets/images/what-we-do/iea-nantes.png";
import virtualTour from "../../assets/images/what-we-do/virtual-tour.png";
import virtualTourMob from "../../assets/images/what-we-do/virtual-tourMOB.png";
import playIcon from "../../assets/images/what-we-do/playIcon.png";
import collaboration1 from "../../assets/images/what-we-do/collaboration1.png";
import talks1 from "../../assets/images/what-we-do/talks1.png";
import collaboration2 from "../../assets/images/what-we-do/collaboration2.png";
import ArchivesTimesIcon from "../../assets/images/what-we-do/ArchivesTimesIcon.svg";
import bookIcon from "../../assets/images/what-we-do/bookIcon.svg";
import ResearchPapers from "../../assets/images/what-we-do/ResearchPapers.svg";
import SeriesBookletIcon from "../../assets/images/what-we-do/SeriesBookletIcon.svg";
import Footer from "../../shared-components/Footer/Footer";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import resetIcon from "../../assets/images/resetIcon.svg";
import DialogContent from "@material-ui/core/DialogContent";
import closeIcon from "../../assets/images/closeIcon.svg";
import { Form, FloatingLabel } from "react-bootstrap";
import FeedbackForm from "../whatWedo/Talks/FeedbackForm";

export class whatWedo extends Component {
 
  constructor(){
  super();   
    this.handleChildUnmount = this.handleChildUnmount.bind(this);
}
  state = {
    open: false,
    openFeedback:false,
    feedbackOpen: false,
    formFeedback:''
  };
  openDialog() {
    this.setState({ open: true });
  }
  closeDialog() {
    this.setState({ open: false });
  }

  // FeedbackFormRef = ({handleOpen}) => {
  //   debugger;
  //   //const {val}=handleOpen || {};
  //   //if(handleOpen!=null || handleOpen!=undefined)
  //   this.openDialogBox = handleOpen;
  // }
  
  onOpenDialogClick = (e) => {
  //  this.openDialogBox(e);
  const formName = e
    this.setState({
      formFeedback:formName
    })
  this.setState({ openFeedback: true})
  }

  handleChildUnmount(){
    this.setState({openFeedback: false});
    this.setState({formFeedback:''});
}

  render() {
    
    const options = {
      items: 1,
      nav: true,
      autoplay: false,
      loop: false,
      rewindNav: false,
      stagePadding: 50,
      navText: [
        "<div class='nav-btn prev-slide'></div>",
        "<div class='nav-btn next-slide'></div>",
      ],
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
          nav: true,
          stagePadding: 0,
        },
        600: {
          items: 1,
          nav: false,
          stagePadding: 0,
        },
        1000: {
          items: 1,
          nav: true,
          loop: false,
          stagePadding: 50,
        },
      },
    };
    const optionsCollaboration = {
      items: 6,
      nav: true,
      autoplay: false,
      loop: true,
      autoplay: true,
      autoplayTimeout: 1500,
      rewindNav: false,
      navText: [
        "<div class='nav-btn prev-slide'></div>",
        "<div class='nav-btn next-slide'></div>",
      ],
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
          nav: true,
        },
        600: {
          items: 1,
          nav: false,
        },
        1000: {
          items: 1.5,
          nav: true,
          loop: false,
        },
      },
    };
    const events = {
      onDragged: function (event) {},
      onChanged: function (event) {},
    };
    return (
      <div class="whatWeDoMain">
        <Header />
        <div className="innerBanner whatWedo text-center">
          <div className="bannerInfo">
            <h1>What we do</h1>
            <p>
              As we preserve Godrej history, we ultimately aim to promote
              business history &amp; archives, <br></br>and to make corporates
              everywhere see heritage as a strategic asset. Let our banner wave!
            </p>
          </div>
        </div>

        <div id="latestTalks" className="latestTalks py-90">
          <div className="container-fluid p-4 p-lg-0">
            <h2 className="secHeader mb-4">Talks</h2>
            <p className="mb-4">
              Our talks and lectures stir discussions around business history,
              business archives, products &amp; commodities, <br></br>national
              development, global economy and more. Let's keep the conversation
              flowing!
            </p>
            <OwlCarousel ref="car" options={options} events={events}>
              <div className="item">
                <div className="row align-items-end">
                  <div className="col-md-7">
                    <figure className="m-0">
                      <img src={latestTalksbanner7} alt="slider 1"></img>
                    </figure>
                  </div>
                  <div className="col-md-4">
                    <div className="letsTalkDetails">
                      <p class="mt-4 mb-0">Annual Lecture</p>
                      <h4 className="mt-1">The Global Merchants</h4>
                      <div className="actionBtn mb-5 mb-lg-1 ">
                        <Link to="/annual-details/16" className="detailsBtn">
                          DETAILS
                        </Link>
                        <Button
                          className="ms-3 registerBtn"
                          onClick={this.onOpenDialogClick.bind(this,"THURSDAY TALK")}
                        >
                          Feedback
                        </Button>                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="row align-items-end">
                  <div className="col-md-7">
                    <figure className="m-0">
                      <img src={latestTalksbanner6} alt="slider 1"></img>
                    </figure>
                  </div>
                  <div className="col-md-4">
                    <div className="letsTalkDetails">
                      <p class="mt-4 mb-0">Thursday Talk</p>
                      <h4 className="mt-1">
                        Mad Men of Bombay: Revisiting the Creative Revolution in
                        Indian Advertising
                      </h4>
                      <div className="actionBtn">
                        <Nav.Link href="/talk-details/6" bsPrefix="detailsBtn">
                          DETAILS
                        </Nav.Link>
                        <Button
                          className="ms-3 registerBtn"
                          onClick={this.onOpenDialogClick.bind(this,"THURSDAY TALK")}
                        >
                          Feedback
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="row align-items-end">
                  <div className="col-md-7">
                    <figure className="m-0">
                      <img src={latestTalksbanner5} alt="slider 1"></img>
                    </figure>
                  </div>
                  <div className="col-md-4">
                    <div className="letsTalkDetails">
                      <p class="mt-4 mb-0">Thursday Talk</p>
                      <h4 className="mt-1">
                        The Global Blue Jeans Transformation
                      </h4>
                      <div className="actionBtn">
                        <Nav.Link href="/talk-details/5" bsPrefix="detailsBtn">
                          DETAILS
                         </Nav.Link>
                         <Button
                          className="ms-3 registerBtn"
                          onClick={this.onOpenDialogClick.bind(this,"THURSDAY TALK")}
                        >
                          Feedback
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="row align-items-end">
                  <div className="col-md-7">
                    <figure className="m-0">
                      <img src={latestTalksbanner4} alt="slider 1"></img>
                    </figure>
                  </div>
                  <div className="col-md-4">
                    <div className="letsTalkDetails">
                      <p class="mt-4 mb-0">Thursday Talk</p>
                      <h4 className="mt-1">
                        Timber Trade Trajectory - Urbanization of Bombay and
                        Deforestation of Western India: Nineteenth Century
                      </h4>
                      <div className="actionBtn">
                        <Nav.Link href="/talk-details/4" bsPrefix="detailsBtn">
                          DETAILS
                        </Nav.Link>
                        <Button
                          className="ms-3 registerBtn"
                          onClick={this.onOpenDialogClick.bind(this,"THURSDAY TALK")}
                        >
                          Feedback
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="row align-items-end">
                  <div className="col-md-7">
                    <figure className="m-0">
                      <img src={latestTalksbanner3} alt="slider 1"></img>
                    </figure>
                  </div>
                  <div className="col-md-4">
                    <div className="letsTalkDetails">
                      <p class="mt-4 mb-0">Thursday Talk</p>
                      <h4 className="mt-1">
                        Power, Privilege and Posture: The Politics of the Seat
                        in India
                      </h4>
                      <div className="actionBtn">
                        <Nav.Link href="/talk-details/3" bsPrefix="detailsBtn">
                          DETAILS
                        </Nav.Link>
                        <Button
                          className="ms-3 registerBtn"
                          onClick={this.onOpenDialogClick.bind(this,"THURSDAY TALK")}
                        >
                          Feedback
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="row align-items-end">
                  <div className="col-md-7">
                    <figure className="m-0">
                      <img src={latestTalksbanner2} alt="slider 1"></img>
                    </figure>
                  </div>
                  <div className="col-md-4">
                    <div className="letsTalkDetails">
                      <p class="mt-4 mb-0">Thursday Talk</p>
                      <h4 className="mt-1">
                        Business in the Time of Contagion
                      </h4>
                      <div className="actionBtn">
                        <Nav.Link href="/talk-details/2" bsPrefix="detailsBtn">
                          DETAILS
                        </Nav.Link>
                       <Button
                          className="ms-3 registerBtn"
                          onClick={this.onOpenDialogClick.bind(this,"THURSDAY TALK")}
                        >
                          Feedback
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="row align-items-end">
                  <div className="col-md-7">
                    <figure className="m-0">
                      <img src={latestTalksbanner8} alt="slider 1"></img>
                    </figure>
                  </div>
                  <div className="col-md-4">
                    <div className="letsTalkDetails">
                      <p class="mt-4 mb-0">Thursday Talk</p>
                      <h4 className="mt-1">Typewriter Tip Tip</h4>
                      <div className="actionBtn">
                        <Nav.Link href="/talk-details/1" bsPrefix="detailsBtn">
                          DETAILS
                        </Nav.Link>
                        <Button
                          className="ms-3 registerBtn"
                          onClick={this.onOpenDialogClick.bind(this,"THURSDAY TALK")}
                        >
                          Feedback
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
            {this.state.openFeedback ? <FeedbackForm unmountMe={this.handleChildUnmount}  openFeedback={this.state.openFeedback} formFeedback={this.state.formFeedback} ></FeedbackForm>:null}
            <Dialog open={this.state.open} onEnter={console.log("Hey.")}>
              <div className="header px-5 py-4">
                <div className="close" onClick={this.closeDialog.bind(this)}>
                  <img src={closeIcon} alt="Close Icon"></img>
                </div>
                <h2 className="secHeader">REGISTRATION FORM</h2>
                <p className="m-0">All fields are mandetory</p>
              </div>

              <DialogContent>
                <div className="dialogInfo ">
                  <div className="formBio p-5">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="formHeader mt-4 mb-3">
                          PERSONAL DETAILS
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formGrid">
                          <div className="formIcon">
                            <i className="fa fa-user"></i>
                          </div>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Name"
                            className="mb-3"
                          >
                            <Form.Control type="text" placeholder="Name" />
                          </FloatingLabel>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formGrid">
                          <div className="formIcon">
                            <i className="fa fa-envelope"></i>
                          </div>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Email ID"
                            className="mb-3"
                          >
                            <Form.Control type="email" placeholder="Email" />
                          </FloatingLabel>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formGrid">
                          <div className="formIcon">
                            <i className="fa fa-envelope"></i>
                          </div>
                          <FloatingLabel
                            controlId="floatingSelectGrid"
                            label="Location (India / Other)"
                          >
                            <Form.Select aria-label="Floating label select example">
                              <option>Open this select menu</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </Form.Select>
                          </FloatingLabel>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formGrid">
                          <div className="formIcon">
                            <i className="fa fa-user"></i>
                          </div>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Organization"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Organization"
                            />
                          </FloatingLabel>
                        </div>
                      </div>
                      <div className="text-center actionBtn mt-4">
                        <button className="resetBtn">
                          <img
                            src={resetIcon}
                            alt="Reset Icon"
                            className="me-2"
                          ></img>
                          Reset
                        </button>
                        <button className="submitBtn ms-2">SUBMIT</button>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
            <Nav.Link
              href="/talks"
              bsPrefix="readMore mt-lg-4 mt-4 mb-5 mb-lg-0"
            >
              <span></span> EXPLORE
            </Nav.Link>
          </div>
        </div>
        <div className="virtualTour pt-5 pb-90">
          <div className="container p-4 p-lg-0">
            <h2 className="secHeader mb-4">Virtual Tour</h2>
            <p className="m-0">
              A ballot box from India's first elections in 1952. Currency notes
              saved from the Bombay Docks explosion. A square Navtal lock.
              Godrej's first corporate film from 1969. Explore all this and more
              of 125 years of Godrej history in this virtual tour of our
              permanent exhibition 'Back to the Future'!
            </p>
            <figure className="mt-4 position-relative">
              <div className="playIcon">
                <a
                  href="https://archives.godrejenterprises.com/virtualtour/"
                  target="_blank"
                >
                  <img src={playIcon} alt="Play Icon"></img>
                </a>
              </div>

              <img
                src={virtualTour}
                alt="virtual Tour"
                className="img-fluid d-none d-sm-none d-lg-block"
              ></img>
              <img
                src={virtualTourMob}
                alt="Publications Banner"
                className="img-fluid d-block d-sm-block d-lg-none"
              ></img>
            </figure>
          </div>
        </div>
        <div className="publications py-90">
          <div className="container p-4 p-lg-0">
            <h2 className="secHeader mb-4">Publications</h2>

            <div className="col-11 m-auto">
              <div className="row">
                <div className="col-6 col-md-3 publicationInfo text-center">
                  <div className="publicationsGrid">
                    <Nav.Link href="/book-details">
                      <figure>
                        <img src={bookIcon} alt="Book Icon"></img>
                      </figure>
                      <div className="name">Books</div>
                    </Nav.Link>
                  </div>
                </div>

                <div className="col-6 col-md-3 publicationInfo text-center">
                  <div className="publicationsGrid">
                    <Nav.Link href="/archive-times">
                      <figure>
                        <img
                          src={ArchivesTimesIcon}
                          alt="Publications Icon"
                        ></img>
                      </figure>
                      <div className="name">Archives Times</div>
                    </Nav.Link>
                  </div>
                </div>
                <div className="col-6 col-md-3 pt-4 p-md-0 publicationInfo text-center">
                  <div className="publicationsGrid">
                    <Nav.Link href="/research-paper">
                      <figure>
                        <img src={ResearchPapers} alt="Publications Icon"></img>
                      </figure>
                      <div className="name">
                        Research Papers by <br></br>Archives Team
                      </div>
                    </Nav.Link>
                  </div>
                </div>
                <div className="col-6 col-md-3 pt-4 p-md-0 publicationInfo text-center">
                  <div className="publicationsGrid">
                    <Nav.Link href="/annual-lecture">
                      <figure>
                        <img
                          src={SeriesBookletIcon}
                          alt="Publications Icon"
                        ></img>
                      </figure>
                      <div className="name">
                        Annual Lecture Series<br></br> Booklet
                      </div>
                    </Nav.Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="exibitions py-90 d-none">
          <div className="container p-4 p-lg-0">
            <h2 className="secHeader mb-4">Exhibitions</h2>
            <p>
              Located in Mumbai, Godrej Archives is the Godrej group's business
              archive. The Archives collects, preserves and manages records
              covering 121 years of the company's history. The idea was mooted
              in 1997, the group's centenary year, by Mr. Sohrab Godrej, former
              chairman of the Godrej group
            </p>
            <div className="row mt-5">
              <div className="col-md-4">
                <div className="exibitionsGrid position-relative">
                  <figure>
                    <img
                      src={exhibitions1}
                      alt="Exhibitions"
                      className="img-fluid"
                    ></img>
                  </figure>
                  <div className="exhibitionsBio">
                    <h4>Sohrab Godrej</h4>
                    <p className="m-0">
                      The Restless Visionary (1912-2012) Exhibition oranised
                      from 3rd June to 3rd July, 2013
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="exibitionsGrid position-relative">
                  <figure>
                    <img
                      src={exhibitions2}
                      alt="Exhibitions"
                      className="img-fluid"
                    ></img>
                  </figure>
                  <div className="exhibitionsBio">
                    <h4>Sohrab Godrej</h4>
                    <p className="m-0">
                      The Restless Visionary (1912-2012) Exhibition oranised
                      from 3rd June to 3rd July, 2013
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="exibitionsGrid position-relative">
                  <figure>
                    <img
                      src={exhibitions3}
                      alt="Exhibitions"
                      className="img-fluid"
                    ></img>
                  </figure>
                  <div className="exhibitionsBio">
                    <h4>Sohrab Godrej</h4>
                    <p className="m-0">
                      The Restless Visionary (1912-2012) Exhibition oranised
                      from 3rd June to 3rd July, 2013
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="conference py-90 d-none">
          <div className="container p-4 p-lg-0">
            <h2 className="secHeader mb-5">Conferences</h2>
            <div className="row">
              <div className="col-md-6">
                <div className="conferenceGrid position-relative">
                  <Link to="/" className="readMore">
                    Read More
                  </Link>
                  <figure>
                    <img
                      src={conference1}
                      alt="Conference"
                      className="img-fluid"
                    ></img>
                  </figure>
                  <div className="conferenceInfo">
                    <h3>ICA SBA MUMBAI 2017</h3>
                    <p>
                      Section on Business Archives (SBA) of International
                      Council of Archives (ICA) in collaboration with Godrej
                      Archives
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="conferenceGrid position-relative">
                  <Link to="/" className="readMore">
                    Read More
                  </Link>
                  <figure>
                    <img
                      src={conference2}
                      alt="Conference"
                      className="img-fluid"
                    ></img>
                  </figure>
                  <div className="conferenceInfo">
                    <h3>IEA NANTES 2020</h3>
                    <p>
                      Section on Business Archives (SBA) of International
                      Council of Archives (ICA) in collaboration with Godrej
                      Archives
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ourCollaborations pb-90 pt-0 d-none">
          <div className="container p-4 p-lg-0">
            <h2 className="secHeader mb-4">OUR COLLABORATIONS</h2>
            <OwlCarousel
              ref="car"
              options={optionsCollaboration}
              events={events}
            >
              <div className="item text-center">
                <img src={collaboration1} alt="icon"></img>
              </div>
              <div className="item text-center">
                <img src={collaboration2} alt="icon"></img>
              </div>
              <div className="item text-center">
                <img src={collaboration1} alt="icon"></img>
              </div>
              <div className="item text-center">
                <img src={collaboration2} alt="icon"></img>
              </div>
              <div className="item text-center">
                <img src={collaboration1} alt="icon"></img>
              </div>
              <div className="item text-center">
                <img src={collaboration2} alt="icon"></img>
              </div>
              <div className="item text-center">
                <img src={collaboration1} alt="icon"></img>
              </div>
              <div className="item text-center">
                <img src={collaboration2} alt="icon"></img>
              </div>
            </OwlCarousel>
          </div>

          <Dialog open={this.state.open} onEnter={console.log("Hey.")}>
            <div className="header px-5 py-4">
              <div className="close" onClick={this.closeDialog.bind(this)}>
                <img src={closeIcon} alt="Close Icon"></img>
              </div>
              <h2 className="secHeader">REGISTRATION FORM</h2>
              <p className="m-0">All fields are mandetory</p>
            </div>

            <DialogContent>
              <div className="dialogInfo ">
                <div className="formBio p-5">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="formHeader mt-4 mb-3">
                        PERSONAL DETAILS
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="formGrid">
                        <div className="formIcon">
                          <i className="fa fa-user"></i>
                        </div>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Name"
                          className="mb-3"
                        >
                          <Form.Control type="text" placeholder="Name" />
                        </FloatingLabel>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="formGrid">
                        <div className="formIcon">
                          <i className="fa fa-envelope"></i>
                        </div>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Email ID"
                          className="mb-3"
                        >
                          <Form.Control type="email" placeholder="Email" />
                        </FloatingLabel>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="formGrid">
                        <div className="formIcon">
                          <i className="fa fa-envelope"></i>
                        </div>
                        <FloatingLabel
                          controlId="floatingSelectGrid"
                          label="Location (India / Other)"
                        >
                          <Form.Select aria-label="Floating label select example">
                            <option>Open this select menu</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </FloatingLabel>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="formGrid">
                        <div className="formIcon">
                          <i className="fa fa-user"></i>
                        </div>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Organization"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Organization"
                          />
                        </FloatingLabel>
                      </div>
                    </div>
                    <div className="text-center actionBtn mt-4">
                      <button className="resetBtn">
                        <img
                          src={resetIcon}
                          alt="Reset Icon"
                          className="me-2"
                        ></img>
                        Reset
                      </button>
                      <button className="submitBtn ms-2">SUBMIT</button>
                    </div>
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
        <Footer />
      </div>
    );
  }
}
export default whatWedo;
