import React, { Component } from "react";
import Header from "../../shared-components/Header/Header";
// import TextField from '@material-ui/icons/TextField';
import "./ArchiveTimes.scss";
import Footer from "../../shared-components/Footer/Footer";
import { Link } from "react-router-dom";
import maskGroup279 from "../../assets/images/what-we-do/archives-times/MaskGroup279.png";
import maskGroup280 from "../../assets/images/what-we-do/archives-times/MaskGroup280.png";
import maskGroup281 from "../../assets/images/what-we-do/archives-times/MaskGroup281.png";
import maskGroup304 from "../../assets/images/what-we-do/archives-times/MaskGroup304.png";
import maskGroup306 from "../../assets/images/what-we-do/archives-times/MaskGroup306.png";
import maskGroup309 from "../../assets/images/what-we-do/archives-times/MaskGroup309.png";
import maskGroup311 from "../../assets/images/what-we-do/archives-times/MaskGroup311.png";
import maskGroup313 from "../../assets/images/what-we-do/archives-times/MaskGroup313.png";
import maskGroup315 from "../../assets/images/what-we-do/archives-times/MaskGroup315.png";
import maskGroup317 from "../../assets/images/what-we-do/archives-times/MaskGroup317.png";
import maskGroup319 from "../../assets/images/what-we-do/archives-times/MaskGroup319.png";
import maskGroup321 from "../../assets/images/what-we-do/archives-times/MaskGroup321.png";
import maskGroup329 from "../../assets/images/what-we-do/archives-times/MaskGroup329.png";
import maskGroup331 from "../../assets/images/what-we-do/archives-times/MaskGroup331.png";
import maskGroup339 from "../../assets/images/what-we-do/archives-times/MaskGroup339.png";
import maskGroup341 from "../../assets/images/what-we-do/archives-times/MaskGroup341.png";
import maskGroup343 from "../../assets/images/what-we-do/archives-times/MaskGroup343.png";
import maskGroup347 from "../../assets/images/what-we-do/archives-times/MaskGroup347.png";
import maskGroup349 from "../../assets/images/what-we-do/archives-times/MaskGroup349.png";
import DEC2021Issue2 from "../../assets/images/what-we-do/archives-times/ArchiveTimes-DEC2021.png";
import June2022Vol3Issue1 from "../../assets/images/what-we-do/archives-times/juneVol3Issue1.png";
import Oct2022Vol3Issue2 from "../../assets/images/what-we-do/archives-times/Vol3Issue2-Oct2022.png";
import Mar2023Vol3Issue3 from "../../assets/images/what-we-do/archives-times/ArchivesTimes_Vol3Issue3.png";
import June2023Vol4Issue1 from "../../assets/images/what-we-do/archives-times/ArchivesTimes_Vol4Issue1.png";
import Oct2023Vol4Issue2 from "../../assets/images/what-we-do/archives-times/vol4Issue2.png";
import Dec2023Vol4Issue3 from "../../assets/images/what-we-do/archives-times/vol4Issue3.png";
import Jan2024Vol4Issue4 from "../../assets/images/what-we-do/archives-times/Vol4Issue4.png";


import email from "../../assets/images/what-we-do/archives-times/Email.svg";
import user from "../../assets/images/what-we-do/archives-times/User.svg";
import SubscribeService from "../../services/Subscribe.service";
import PrivacyService from "../../services/Privacy.service";

const privacy_link = process.env.REACT_APP_MY_LINK;

export class ArchiveTimes extends Component {
  state = {
    chars_left: 1000,
    max_char: 1000,
    scroll: false,
  };

  
  constructor(props) {
    super(props);    
    this.state = {  
      subname: '',
      subemail: '',
      subchkPrivacyNotice:'',
      subchkaccurate:'',
      privacyurl:privacy_link,

      fields: {
      subname: '',
      subemail: '',
      subchkPrivacyNotice:'',
      subchkaccurate:'',
      },

      errors: {
        subname: '',
        subemail: '',
        subchkPrivacyNotice:'',
        subchkaccurate:'',
      }
    };
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    
  }



  flipCheckbox()
  {
    this.setState({subchkPrivacyNotice:!this.state.subchkPrivacyNotice})
  }
 
  flipCheckbox1()
  {
    this.setState({subchkaccurate:!this.state.subchkaccurate})
  }

  // handleChange(e) {
  //   let fields = this.state.fields;
  //   fields[e.target.name] = e.target.value;
  //   this.setState({
  //     fields
  //   });

  // }
  handleChangeName(e) {
  this.setState({ subname: e.target.value });
    this.setState({
      errors: {
        ...this.state.errors,
      },
      fields: {
        ...this.state.fields,
        [e.target.name]: e.target.value
      }
    });
  }

  handleChangeEmail(e) {
    this.setState({ subemail: e.target.value });
      this.setState({
        errors: {
          ...this.state.errors,
          [e.target.name]: this.validateForm(e.target.name, e.target.value)
        },
        fields: {
          ...this.state.fields,
          [e.target.name]: e.target.value
        }
      });
    }

  handleWordCount = (event) => {
    const charCount = event.target.value.length;
    const maxChar = this.state.max_char;
    const charLength = charCount;
    this.setState({ chars_left: charLength });
  };

  handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 700) {
      this.setState({ scroll: true });
    } else {
      this.setState({ scroll: false });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    //this.getPrivacyDetails();
  }

getPrivacyDetails()
{
  const req = {
    
    pg_company: "GNB",
    pg_PolicyName: "GodrejArchives",
  }
  PrivacyService.GetPrivacyPolicySrv(req).then(
    response => {
      if (response.data.model != null) {
        if (response.data.message == "200")
        {
          //debugger;
          this.state.privacyurl=response.data.model.policyURL;
        
        }
        else {
          alert('Error while fetching data');
        }


      }
      else {
        alert('Error while fetching data');
      }
    },
    error => {
      alert('Error while fetching data');
    }
    
  );
  }
validateForm() {
  let fields = this.state.fields;
  let errors = {};
  let formIsValid = true;

  if (!fields["subname"]) {
    formIsValid = false;
    errors["subname"] = "*Please enter your Name.";
  }

  if (typeof fields["subname"] !== "undefined") {
    if (!fields["subname"].match(/^[a-zA-Z ]*$/)) {
      formIsValid = false;
      errors["subname"] = "*Please enter alphabet characters only.";
    }
  }

  if (!fields["subemail"]) {
    formIsValid = false;
    errors["subemail"] = "*Please enter your email-ID.";
  }

  if (typeof fields["subemail"] !== "undefined") {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!fields["subemail"]) {
      formIsValid = false;
    errors["subemail"] = "*Please enter your email-ID.";
    }
    else {
    if (!pattern.test(fields["subemail"])) {
      formIsValid = false;
      errors["subemail"] = "*Please enter valid email-ID.";
    }
  }
  }

  if (!this.state.subchkPrivacyNotice){
    formIsValid = false;
    errors["subchkPrivacyNotice"] = "*Please select consent for privacy notice.";
  }

  if (!this.state.subchkaccurate){
    formIsValid = false;
    errors["subchkaccurate"] = "*Please select declaration for valid inforamtion provided by you.";
  }
 

  this.setState({
    errors: errors
  });
  return formIsValid;


}




btnSaveSubscribe = () => {  
  const { fields } = this.state;   
  let validationErrors = {};  
  //Object.keys(fields).forEach(name => {
  //     const error = this.validateForm(name, fields[name]);
  //     if (error && error.length > 0) {
  //         validationErrors[name] = error;
  //     }
  // });
  if (!this.validateForm()) {
  return;
  }
  // debugger;
  // if (Object.keys(validationErrors).length > 0) {
  //   debugger;
  //     this.setState({ errors: validationErrors });
  //     return;
  // }
  if (fields.subname && fields.subemail && fields.subchkPrivacyNotice && fields.subchkaccurate) {
      const data = {
          subname: fields.subname,
          subemail: fields.subemail,
          subchkPrivacyNotice: fields.subchkPrivacyNotice,
          subchkaccurate: fields.subchkaccurate      
        };

  }
  
  const req = {
    
    nsfname: this.state.subname,
    nsfemailid: this.state.subemail,
    nsfpolicyconsent: this.state.subchkPrivacyNotice,
    nsfvaliddataconsent:this.state.subchkaccurate,
  }
  SubscribeService.SaveSubscribeSrv(req).then(
    response => {
      //Commented by saditi
     // debugger;
      if (response.data.model != null) {
        if (response.data.model.status == "Success") {
          //toast.success('Data Saved Successfully', { transition: this.fade });
          alert('Data Saved Successfully');
          window.location.reload(false);
          this.setState({
            subname: '',
            subemail: '',
            
        });
        
      
        }
        else {
          //debugger;
          alert('Error while submitting data');
          window.location.reload(false);
        }


      }
      else {
        alert('Error while submitting data');
        window.location.reload(false);
      }
    },
    error => {
      alert('Error while submitting data');
      window.location.reload(false);
    }
    
  );
}

  render() {
    const {
      errors
   } = this.state
    return (
      <div className="archivesTimes">
        <Header />
        <div className="innerBanner archiveBanner text-center">
          <div className="bannerInfo">
            <h1>Archives Times</h1>
            <p>
              Archives Times, newsletter of the Godrej Archives, aims to bring
              you news of our latest events and activities, stories of new
              arrivals, collections researched, summaries of talks, tales of
              conservation &amp; digitization and more.
            </p>
          </div>
        </div>
        <div className="archive-times py-90">
          <div className="container">
                    <div className="row">

                    <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                            <Link
                                to={{
                                    pathname:
                                        "https://archives.godrejenterprises.com/ArchivesTimes/index.aspx?vol=4&issue=4",
                                }}
                                target="_blank"
                            >
                                <div className="archiveTimesGrid pt-4">
                                    <div>
                                        <figure>
                                            <img
                                                src={Jan2024Vol4Issue4}
                                                alt="Functions"
                                                className="img-fluid"
                                            ></img>
                                        </figure>
                                    </div>
                                    <h4 className="mt-3">Exploring the Maximum City with Gen A!</h4>
                                    <div className="volume">Volume 4, Issue 4 | January 2024</div>
                                </div>
                            </Link>
                        </div>

                    <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                            <Link
                                to={{
                                    pathname:
                                        "https://archives.godrejenterprises.com/ArchivesTimes/index.aspx?vol=4&issue=3",
                                }}
                                target="_blank"
                            >
                                <div className="archiveTimesGrid pt-4">
                                    <div>
                                        <figure>
                                            <img
                                                src={Dec2023Vol4Issue3}
                                                alt="Functions"
                                                className="img-fluid"
                                            ></img>
                                        </figure>
                                    </div>
                                    <h4 className="mt-3">Textures, Patterns, A Splash of Colour!</h4>
                                    <div className="volume">Volume 4, Issue 3 | January 2024</div>
                                </div>
                            </Link>
                        </div>


                        <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                            <Link
                                to={{
                                    pathname:
                                        "https://archives.godrejenterprises.com/ArchivesTimes/index.aspx?vol=4&issue=2",
                                }}
                                target="_blank"
                            >
                                <div className="archiveTimesGrid pt-4">
                                    <div>
                                        <figure>
                                            <img
                                                src={Oct2023Vol4Issue2}
                                                alt="Functions"
                                                className="img-fluid"
                                            ></img>
                                        </figure>
                                    </div>
                                    <h4 className="mt-3">All Roads Lead To Archives</h4>
                                    <div className="volume">Volume 4, Issue 2 | October 2023</div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                            <Link
                                to={{
                                    pathname:
                                        "https://archives.godrejenterprises.com/ArchivesTimes/index.aspx?vol=4&issue=2",
                                }}
                                target="_blank"
                            >
                                <div className="archiveTimesGrid pt-4">
                                    <div>
                                        <figure>
                                            <img
                                                src={June2023Vol4Issue1}
                                                alt="Functions"
                                                className="img-fluid"
                                            ></img>
                                        </figure>
                                    </div>
                                    <h4 className="mt-3">Diving into the social nature of business</h4>
                                    <div className="volume">Volume 4, Issue 1 | June 2023</div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                            <Link
                                to={{
                                    pathname:
                                        "https://archives.godrejenterprises.com/ArchivesTimes/index.aspx?vol=3&issue=3",
                                }}
                                target="_blank"
                            >
                                <div className="archiveTimesGrid pt-4">
                                    <div>
                                        <figure>
                                            <img
                                                src={Mar2023Vol3Issue3}
                                                alt="Functions"
                                                className="img-fluid"
                                            ></img>
                                        </figure>
                                    </div>
                                    <h4 className="mt-3">Heritage in the post Covid-19 world</h4>
                                    <div className="volume">Volume 3, Issue 3 | March 2023</div>
                                </div>
                            </Link>
                        </div>


                        <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                            <Link
                                to={{
                                    pathname:
                                        "https://archives.godrejenterprises.com/ArchivesTimes/index.aspx?vol=3&issue=2",
                                }}
                                target="_blank"
                            >
                                <div className="archiveTimesGrid pt-4">
                                    <div>
                                        <figure>
                                            <img
                                                src={Oct2022Vol3Issue2}
                                                alt="Functions"
                                                className="img-fluid"
                                            ></img>
                                        </figure>
                                    </div>
                                    <h4 className="mt-3">The Chair as we know it</h4>
                                    <div className="volume">Volume 3, Issue 2 | October 2022</div>
                                </div>
                            </Link>
                        </div>



                        <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                            <Link
                                to={{
                                    pathname:
                                        "https://archives.godrejenterprises.com/ArchivesTimes/index.aspx?vol=3&issue=1",
                                }}
                                target="_blank"
                            >
                                <div className="archiveTimesGrid pt-4">
                                    <div>
                                        <figure>
                                            <img
                                                src={June2022Vol3Issue1}
                                                alt="Functions"
                                                className="img-fluid"
                                            ></img>
                                        </figure>
                                    </div>
                                    <h4 className="mt-3">Communicating the 125-years-legacy of Godrej!</h4>
                                    <div className="volume">Volume 3, Issue 1 | June 2022</div>
                                </div>
                            </Link>
                        </div>
            <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                <Link
                  to={{
                    pathname:
                      "https://archives.godrejenterprises.com/ArchivesTimes/index.aspx?vol=2&issue=2",
                  }}
                  target="_blank"
                >
                  <div className="archiveTimesGrid pt-4">
                    <div>
                      <figure>
                        <img
                          src={DEC2021Issue2}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <h4 className="mt-3">International Archives Week Celebrations At Godrej Archives</h4>
                    <div className="volume">Volume 2, Issue 2 | December 2021</div>
                  </div>
                </Link>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                <Link
                  to={{
                    pathname:
                      "https://archives.godrejenterprises.com/ArchivesTimes/index.aspx?vol=2&issue=1",
                  }}
                  target="_blank"
                >
                  <div className="archiveTimesGrid pt-4">
                    <div>
                      <figure>
                        <img
                          src={maskGroup279}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <h4 className="mt-3">Pandemics and Business in History</h4>
                    <div className="volume">Volume 2, Issue 1 | July 2021</div>
                  </div>
                </Link>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                <Link
                  to={{
                    pathname:
                      "https://archives.godrejenterprises.com/ArchivesTimes/index.aspx?vol=1&issue=2",
                  }}
                  target="_blank"
                >
                  <div className="archiveTimesGrid pt-4">
                    <div>
                      <figure>
                        <img
                          src={maskGroup280}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <h4 className="mt-3">‘Technologies of Trust’ Conference</h4>
                    <div className="volume"> Volume 1, Issue 2 | January 2021</div>
                  </div>
                </Link>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                <Link
                  to={{
                    pathname:
                      "https://archives.godrejenterprises.com/ArchivesTimes/index.aspx?vol=1&issue=1",
                  }}
                  target="_blank"
                >
                  <div className="archiveTimesGrid pt-4">
                    <div>
                      <figure>
                        <img
                          src={maskGroup281}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <h4 className="mt-3">
                      The Godrej Archive Internship Experience - A Reflection
                    </h4>
                    <div className="volume"> Volume 1, Issue 1 | January 2020</div>
                  </div>
                </Link>
              </div>

              <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                <Link
                  to={{
                    pathname:
                      "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Dec_2018_Archives_Times.pdf",
                  }}
                  target="_blank"
                >
                  <div className="archiveTimesGrid pt-4">
                    <div>
                      <figure>
                        <img
                          src={maskGroup304}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <h4 className="mt-3">
                      Augmenting the Physical with the Digital
                    </h4>
                    <div className="volume"> Volume 5, Issue 1 | December 2018</div>
                  </div>
                </Link>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                <Link
                  to={{
                    pathname:
                      "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/Dec_2017_Archives_Times.pdf",
                  }}
                  target="_blank"
                >
                  <div className="archiveTimesGrid pt-4">
                    <div>
                      <figure>
                        <img
                          src={maskGroup306}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <h4 className="mt-3">
                      Pushing the Bounds of Conventional Definitions of Skill
                      Development
                    </h4>
                    <div className="volume"> Volume 4, Issue 1  | December 2017</div>
                  </div>
                </Link>
              </div>

              <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5">
                <Link
                  to={{
                    pathname:
                      "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/TheArchivesTimes2015-16.pdf",
                  }}
                  target="_blank"
                >
                  <div className="archiveTimesGrid pt-4">
                    <div>
                      <figure>
                        <img
                          src={maskGroup309}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <h4 className="mt-3">
                      The beginning of Godrej’s Aerospace story
                    </h4>
                    <div className="volume"> Volume 3, Issue 1 | 2015-2016</div>
                  </div>
                </Link>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                <Link
                  to={{
                    pathname:
                      "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/The_Archives_Times_Jun_Dec_2014.pdf",
                  }}
                  target="_blank"
                >
                  <div className="archiveTimesGrid pt-4">
                    <div>
                      <figure>
                        <img
                          src={maskGroup311}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <h4 className="mt-3">
                      International Product Catalogues Collection: Snapshots of
                      a bygone era
                    </h4>
                    <div className="volume"> Volume 2, Issue 2 | June-December 2014</div>
                  </div>
                </Link>
              </div>

              <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                <Link
                  to={{
                    pathname:
                      "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/The_Archives_Times_Apr_May_2014.pdf",
                  }}
                  target="_blank"
                >
                  <div className="archiveTimesGrid pt-4">
                    <div>
                      <figure>
                        <img
                          src={maskGroup313}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <h4 className="mt-3">
                      An oral account of Godrej’s architectural fabrication
                      business
                    </h4>
                    <div className="volume"> Volume 2, Issue 1 | April-May 2014</div>
                  </div>
                </Link>
              </div>

              <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                <Link
                  to={{
                    pathname:
                      "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/The_Archives_Times_May_Oct_2013.pdf",
                  }}
                  target="_blank"
                >
                  <div className="archiveTimesGrid pt-4">
                    <div>
                      <figure>
                        <img
                          src={maskGroup315}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <h4 className="mt-3">
                      Interview with Ted Ryan, Director of the Coca Cola
                      Archives
                    </h4>
                    <div className="volume"> Volume 1, Issue 10 | May-October 2013</div>
                  </div>
                </Link>
              </div>
              <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                <Link
                  to={{
                    pathname:
                      "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/The_Archives_Times_Mar_Apr_2013.pdf",
                  }}
                  target="_blank"
                >
                  <div className="archiveTimesGrid pt-4">
                    <div>
                      <figure>
                        <img
                          src={maskGroup317}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <h4 className="mt-3">
                      Lost &amp; Found: A 1984 photo reappears to revive
                      memories
                    </h4>
                    <div className="volume"> Volume 1, Issue 9 | March-April 2013</div>
                  </div>
                </Link>
              </div>

              <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                <Link
                  to={{
                    pathname:
                      "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/TheArchivesTimes_JanFeb2013.pdf",
                  }}
                  target="_blank"
                >
                  <div className="archiveTimesGrid pt-4">
                    <div>
                      <figure>
                        <img
                          src={maskGroup319}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <h4 className="mt-3">Godrej and Nation Building</h4>
                    <div className="volume"> Volume 1, Issue 8 | January-February 2013</div>
                  </div>
                </Link>
              </div>

              <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                <Link
                  to={{
                    pathname:
                      "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/The_Archives_Times_Nov_Dec2012.pdf",
                  }}
                  target="_blank"
                >
                  <div className="archiveTimesGrid pt-4">
                    <div>
                      <figure>
                        <img
                          src={maskGroup321}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <h4 className="mt-3">Farewell Mr. Muncherji</h4>
                    <div className="volume"> Volume 1, Issue 7 | November-December 2012</div>
                  </div>
                </Link>
              </div>

              <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                <Link
                  to={{
                    pathname:
                      "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/The_Archives_Times_Oct2012.pdf",
                  }}
                  target="_blank"
                >
                  <div className="archiveTimesGrid pt-4">
                    <div>
                      <figure>
                        <img
                          src={maskGroup329}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <h4 className="mt-3">
                      Railway Collection (1941-1962) Sales &amp; Marketing
                      Records catalogued
                    </h4>
                    <div className="volume"> Volume 1, Issue 6 | October 2012</div>
                  </div>
                </Link>
              </div>

              <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                <Link
                  to={{
                    pathname:
                      "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/The_Archives_Times_Sept2012.pdf",
                  }}
                  target="_blank"
                >
                  <div className="archiveTimesGrid pt-4">
                    <div>
                      <figure>
                        <img
                          src={maskGroup349}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <h4 className="mt-3">
                      Archivist captures the untold saga of 'company towns'
                    </h4>
                    <div className="volume"> Volume 1, Issue 5 | September 2012</div>
                  </div>
                </Link>
              </div>

              <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                <Link
                  to={{
                    pathname:
                      "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/The_Archives_Times_Aug2012.pdf",
                  }}
                  target="_blank"
                >
                  <div className="archiveTimesGrid pt-4">
                    <div>
                      <figure>
                        <img
                          src={maskGroup331}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <h4 className="mt-3">Simplifying the Art of Archiving</h4>
                    <div className="volume"> Volume 1, Issue 5 | August 2012</div>
                  </div>
                </Link>
              </div>

              <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5">
                <Link
                  to={{
                    pathname:
                      "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/The_Archives_Times_July2012.pdf",
                  }}
                  target="_blank"
                >
                  <div className="archiveTimesGrid pt-4">
                    <div>
                      <figure>
                        <img
                          src={maskGroup339}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <h4 className="mt-3">
                      Godrej Hospital Furniture Collection (1950s) catalogued
                    </h4>
                    <div className="volume"> Volume 1, Issue 4 | July 2012</div>
                  </div>
                </Link>
              </div>

              <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                <Link
                  to={{
                    pathname:
                      "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/The_Archives_Times_June2012.pdf",
                  }}
                  target="_blank"
                >
                  <div className="archiveTimesGrid pt-4">
                    <div>
                      <figure>
                        <img
                          src={maskGroup341}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <h4 className="mt-3">
                      Pirojshanagar: Innovating Technology, Enriching Lives
                    </h4>
                    <div className="volume"> Volume 1, Issue 3 | June 2012</div>
                  </div>
                </Link>
              </div>

              <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                <Link
                  to={{
                    pathname:
                      "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/The_Archives_Times_May2012.pdf",
                  }}
                  target="_blank"
                >
                  <div className="archiveTimesGrid pt-4">
                    <div>
                      <figure>
                        <img
                          src={maskGroup343}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <h4 className="mt-3">
                      New Accessions: Linking the past with the present
                    </h4>
                    <div className="volume"> Volume 1, Issue 2 | May 2012</div>
                  </div>
                </Link>
              </div>

              <div className="col-sm-6 col-lg-4 col-12 mb-0 mb-lg-5 ">
                <Link
                  to={{
                    pathname:
                      "https://archives.godrejenterprises.com/DOM-Lectures/assets/pdf/The_Archives_Times_Jan2012.pdf",
                  }}
                  target="_blank"
                >
                  <div className="archiveTimesGrid pt-4">
                    <div>
                      <figure>
                        <img
                          src={maskGroup347}
                          alt="Functions"
                          className="img-fluid"
                        ></img>
                      </figure>
                    </div>
                    <h4 className="mt-3">
                      New Accessions: Linking the past with the present
                    </h4>
                    <div className="volume"> Volume 1, Issue 4 | January-March 2012</div>
                  </div>
                </Link>
              </div>
              {this.state.scroll && (
                  <div className="custom-container">
                  <h2 className="secHeader redBorder mt-4 mb-2">
                    SUBSCRIBE TO OUR NEWSLETTER
                  </h2>
                  <div className="filterSec mb-3">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="formGrid">
                          <div className="formIcon">
                            <img src={user} width="25px" height="25px"></img>
                          </div>
                          <div className="form-floating mb-4">
                            <input
                              name="subname"
                              placeholder="name"
                              type="text"
                              size="30"
                              className="form-control"
                              value={this.state.subname} 
                              onChange={this.handleChangeName}
                              //onChange={e => { this.handleChangeName(e); this.validateForm(e) }}  
                            />
                             <div>
                                <span className="text-danger">{this.state.errors.subname}</span>
                            </div>
                            <label for="floatingInput">Name</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formGrid">
                          <div className="formIcon">
                            <img src={email} width="25px" height="25px" />
                          </div>
                          <div className="form-floating">
                              <input
                              name="subemail"
                              placeholder="name@example.com"
                              type="email"
                              size="30"
                              id="floatingInput"
                              className="form-control"
                              value={this.state.subemail} 
                              onChange={this.handleChangeEmail}
                              //onChange={e => { this.handleChangeEmail(e); this.validateForm(e) }}  
                            />
                            <div>
                            <span className="text-danger">{this.state.errors.subemail}</span>
                            </div>
                            <label for="floatingInput">Email ID</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-5">
                        <div class="form-check">
                          <input
                            id="subchkPrivacyNoticeid"
                            class="form-check-input"
                            type="checkbox"
                            //id="flexCheckDefault"
                            width="25px"
                            height="25px"
                            value={this.state.subchkPrivacyNotice}
                            onChange={this.flipCheckbox.bind(this)}
                          />                       
                          <label
                            class="form-check-label"
                            for="subchkPrivacyNotice"
                          >
                          I have read the <a href={this.state.privacyurl} target="_blank">Privacy Notice</a> and hereby provide my
                            consent to process the information for the purposes
                            defined in the notice.
                          </label>
                          <div>
                            <span className="text-danger">{this.state.errors.subchkPrivacyNotice}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div class="form-check">
                          <input
                            id="subchkaccurate"
                            class="form-check-input"
                            type="checkbox"
                            //id="flexCheckDefault"
                            required="required"
                            value={this.state.subchkaccurate}
                            onChange={this.flipCheckbox1.bind(this)}
                          />
                          <label
                            class="form-check-label"
                            for="subchkaccurate"
                          >
                            I hereby declare that the information provided by me
                            is accurate.
                          </label>
                          <div>
                            <span className="text-danger">{this.state.errors.subchkaccurate}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 actionBtn pe-0">
                        <button className="bt exploreBtn ms-2" onClick={this.btnSaveSubscribe} >
                          SUBSCRIBE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                            
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  };
}
export default ArchiveTimes;
