import React, { Component } from "react";
import './BookDetails.scss';
import Header from "../../../shared-components/Header/Header"
import { Link } from 'react-router-dom';
import Book1 from '../../../assets/images/book/book1.png';
import frugalToTheOrnate from '../../../assets/images/book/frugalToTheOrnate.png';
import GodrejBhavaneBook from '../../../assets/images/book/GodrejBhavaneBook.png';
import Footer from "../../../shared-components/Footer/Footer";


export class BookDetails extends Component {
    render() {
        return (
            <div className="bookDetails">


                <Header />
                <div className="innerWrapper py-90 bookDetails">
                    <div className="container">

                    <div className="row pb-5">
                            <div className="col-md-12">
                                <h2 className="secHeader mb-4 mt-5">
                                    BOOKS
                                </h2>
                            </div>
                            <div className="col-md-3">
                                <div className="bookImg">
                                    <figure className="pb-3">
                                        <img src={GodrejBhavaneBook} alt="Book" className="img-fluid" /></figure>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="bookGrid">
                                    <h6 className="mt-0 mb-1">Godrej Bhavan : A Journey</h6>
                                    <h4 className="mb-4"></h4>
                                    <h5 className="mb-4 authorName"></h5>
                                    <b>About the Book</b>
                                    <p>'Godrej Bhavan: A Journey' is a captivating portrayal of the magnificent structure of Godrej Bhavan nestled in Fort, Mumbai. This distinctive structure, defying modern skyscraper norms, stands as a testament to architectural elegance, embracing environmental consciousness and Godrej's enduring dedication to sustainability.</p>

 

<p>Godrej Bhavan was born “green” in the present context, long before people had coined the term “green building”. Through eco-friendly construction practices, mindful resource utilization, and the cherished green sanctuary, featuring a mesmerizing garden graced by three majestic trees atop its six floors,  Godrej Bhavan never ceases to impress.</p>

 

<p>Delve into the pages of this book, chronicling its 50-year odyssey since its establishment in 1972. Immerse yourself in the narrative accompanied by captivating photographs, showcasing the façade, structure, and breathtaking city vistas, creating an evocative tapestry of time and architectural brilliance.</p>


<Link to={{ pathname: "https://archives.godrejenterprises.com/Godrej_Bhavan_eBook.html" }} target="_blank" className="readMore mt-3">Read Now</Link>
                                </div>
                            </div>
                        </div>  

                        <div className="row pb-5">
                          
                            <div className="col-md-3">
                                <div className="bookImg">
                                    <figure className="pb-3">
                                        <img src={frugalToTheOrnate} alt="Book" className="img-fluid" /></figure>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="bookGrid">
                                    <h6 className="mt-0 mb-1">From the Frugal to the Ornate</h6>
                                    <h4 className="mb-4">Stories of the Seat in India</h4>
                                    <h5 className="mb-4 authorName">By Sarita Sundar</h5>
                                    <b>About the Book</b>
                                    <p>Cross-linking disciplines from contemporary design practice and production to design anthropology and cultural trends, this book investigates and spotlights the seat's relationship with its sitter, and other people in its periphery. It examines the power the seat wields, and the power it grants by sheer association. Bestowing life and spirit into objects, the book asks: what is the seat for, what are the worlds to which it belongs, what worlds have been (and will be) opened by it? By deconstructing the seat, From the Frugal to the Ornate: Stories of the Seat in India, reflects upon the marked shift in the way practitioners, users, and analysts conceptualise and engage with object culture, and a subsequent 'turn to the material'. </p>
                                    <p>To buy a copy, write to <a href="mailto:archives@godrej.com">archives@godrej.com</a></p>
                                    <b>About the website </b>
                                    <p>Along with the book, a website <a href="www.100seatsofindia.com" target="_blank">www.100seatsofindia.com</a> was also launched, that documents and displays an array of 100 seats of India featured in the book. Interactive interface, stunning graphics and deeply researched content wherein the seats are categorized thematically and era-wise, make navigating through this website a very engaging experience. It offers a virtual tour of India itself through her seats.  </p>

                                    <Link to={{ pathname: "https://www.amazon.in/Frugal-Ornate-Stories-Seat-India/dp/8193274016" }} target="_blank" className="readMore mt-3">BUY NOW</Link>
                                </div>
                            </div>
                        </div>

                        <div class="hr"></div>

                        <div className="row py-5 py-lg-4">
                            <div className="col-md-12">
                 
                </div>
                            <div className="col-md-3">
                                <div className="bookImg">
                                    <figure className="pb-3">
                                        <img src={Book1} alt="Book" className="img-fluid" /></figure>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="bookGrid">
                                <h6 className="mt-0 mb-1">With Great Truth &amp; Regard </h6>                                    
                                    <h4 className="mb-4">The Story of the Typewriter in India</h4>
                                    <h5 className="mb-4 authorName">By Sidharth Bhatia and Chirodeep Chaudhuri</h5>
                                    <p>The Story of the Typewriter in India' documents the world of typewriters in India from the lens of journalists, writers, artists, photographers, collectors, etc. Supported by archival records as well as anecdotes and stories gathered from across India, the book pays tribute to this humble writing machine that ruled homes &amp; offices and even the streets for over a century!</p>
                                    
                                    <Link to={{pathname: "https://www.amazon.in/Great-Truth-Regard-Story-Typewriter/dp/8193274008"}}  target="_blank" className="readMore mt-3">BUY NOW</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
export default BookDetails;
