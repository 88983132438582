import { Component } from "react";
import "./ThursdayTalkDetail.scss";
import Header from "../../../../shared-components/Header/Header";
import Footer from "../../../../shared-components/Footer/Footer";
import { details } from "./ThursdayTalkData";
import downloadIcon from "../../../../assets/images/downloadIcon.svg";
import { Nav } from "react-bootstrap";
import wallCalendar from "../../../../assets/images/what-we-do/talks/wallCalendar.svg";
import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'html-react-parser';

export class TalksDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      param: this.props.match.id,
    };
  }
  render() {
    return (
      <>
        {details.map((data, key) => {
          if (data.id == this.state.param) {
            return (
              <div className="thursTalk">
                <Header />
                 <div className="container pt-100">
                
                        {/*New*/}
                        <div className="row speakerDetail">
                            <div className="col-md-3">
                                <figure>
                                    <img
                                        src={
                                            require("../../../../assets/images/what-we-do/talks/talks-detail/" +
                                                data.speakerImg).default
                                        }
                                        className="img-fluid"
                                    />
                                </figure>
                            </div>
                            <div className="col-md-9">
                                <h2 className="secHeader redBorder mb-4 mt-1">
                                    {data.header}
                                </h2>
                                <div className="row mt-5">
                                    <div className="col-2 col-md-3 mt-3 pe-2 pe-lg-0">
                                        <h5 class="m-0 speakerName">Speaker</h5>
                                        <h6>{data.name}</h6>
                                    </div>
                                    <div className="col-2 col-md-1 mt-3" style={{ textAlign: "end" }}>
                                        <figure className="wallCalendar3">
                                            <img src={wallCalendar} className="img-fluid" />
                                        </figure>
                                    </div>
                                    <div className="col-3 col-md-4 mt-3 ps-0 ps-lg-0">
                                        <span>Date</span>
                                        <h6>{data.date}</h6>
                                    </div>
                                    {/*<div className="col-4 col-md-4 ps-2 ps-lg-2">
                                        <span>Venue</span>
                                        <h6>{data.venue}</h6>
                                    </div>*/}
                                </div>

                                <div className="row">
                                    
                                    <div className="col-md-5 pe-0 ClickHere">
                                        <Nav.Link href={data.videoLink} target="_blank">
                                            Click here to see the video
                                        </Nav.Link>
                                    </div>
                                    {/* <div className="col-md-1 pe-0 text-end">
                      <span>
                        <img src={downloadIcon} alt="Download Icon"></img>
                      </span>
                    </div> */}
                                </div>

                            </div>

                           

                        </div>

                        

                        {/*end New*/}
               
                
                  <h4 class="mt-4">About the Speaker</h4>
                        <p className="pb-3">{ReactHtmlParser(data.contain1)}</p>
                  <h4>Abstract</h4>
                  <p className="pb-5">{data.contain2}</p>
                </div>
                <Footer />
              </div>
            );
          }
        })}
      </>
    );
  }
}

export default TalksDetail;
