import React, { Component } from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import facebookIcon from "../../assets/images/facebookIcon.svg";
import twitterIcon from "../../assets/images/twitterIcon.svg";
import instaIcon from "../../assets/images/instaIcon.svg";
import linkedInIcon from "../../assets/images/linkedInIcon.svg";
import youtubeIcon from "../../assets/images/youtubeIcon.svg";

export class Footer extends Component {
  render() {
    return (
      <div>
        <footer className="text-center py-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 text-md-start text-center">
                Copyright © 2022. Godrej.com |{" "}
                <a href="#" className="linkStyle">
                  Disclaimer
                </a>{" "}
                |{" "}
                <a
                  href="https://gdpr.godrejenterprises.com/document/GodrejArchives/Privacy.html"
                  target="_blank"
                  className="linkStyle privacy"
                >
                  Privacy Policy
                </a>{" "}
              </div>
              <div className="col-md-6 text-md-end text-center mt-4 mt-md-0">
                <ul className="footerIcon mb-0">
                  <span>Follow us on</span>
                  <Link
                    to={{
                      pathname: "https://www.facebook.com/GodrejArchives/",
                    }}
                    target="_blank"
                  >
                    {" "}
                    <img src={facebookIcon} alt="Facebook Icon"></img>{" "}
                  </Link>
                  <Link
                    to={{
                      pathname: "https://twitter.com/godrejarchives?lang=en",
                    }}
                    target="_blank"
                  >
                    {" "}
                    <img src={twitterIcon} alt="Twitter Icon"></img>
                  </Link>
                  <Link
                    to={{
                      pathname:
                        "https://www.instagram.com/godrej.archives/?hl=en",
                    }}
                    target="_blank"
                  >
                    {" "}
                    <img src={instaIcon} alt="Instagram Icon"></img>{" "}
                  </Link>
                  <Link
                    to={{
                      pathname:
                        "https://www.linkedin.com/in/godrej-archives-5452a9144/",
                    }}
                    target="_blank"
                  >
                    {" "}
                    <img src={linkedInIcon} alt="Linkedin Icon"></img>{" "}
                  </Link>
                  <Link
                    to={{
                      pathname:
                        "https://www.youtube.com/channel/UCGNtoQJx94guoF3nclCXuJg",
                    }}
                    target="_blank"
                  >
                    {" "}
                    <img src={youtubeIcon} alt="YouTube Icon"></img>{" "}
                  </Link>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
export default Footer;
