import "./App.scss";
import React from "react";
import Home from "./components/Home/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import whatWedo from "./components/whatWedo/whatWedo";
import WhoWeAre from "./components/WhoWeAre/WhoWeAre";
import ContactUs from "./components/ContactUs/ContactUs";
import ArchiveTimes from "./components/ArchiveTimes/ArchiveTimes";
import ReprographyServices from "./components/WhatWeOffer/ReprographyServices/ReprographyServices";
import TheAccessPolicy from "./components/WhatWeOffer/TheAccessPolicy/TheAccessPolicy";
import OurCollection from "./components/WhatWeOffer/OurCollection/OurCollection";
import ReferenceEnquiryForm from "./components/WhatWeOffer/ReferenceEnquiryForm/ReferenceEnquiryForm";
import ResearchServices from "./components/WhatWeOffer/ResearchServices/ResearchServices";
import ArchiveTales from "./components/ArchiveTales/ArchiveTales";
import Talks from "./components/whatWedo/Talks/Talks";
import BookDetails from "./components/whatWedo/BookDetails/BookDetails";
import ResearchPapers from "./components/whatWedo/ResearchPapers/ResearchPapers";
import AnnualLecture from "./components/whatWedo/AnnualLecture/AnnualLecture";
import TalksDetail from "./components/whatWedo/Talks/TalksDetails/ThursdayTalkDetail";
import AnnualTalkDetail from "./components/whatWedo/Talks/TalksDetails/AnnualTalkDetail";
/*import AnnualLecture2017 from "./components/whatWedo/Talks/TalksDetails/AnnualLecture2017";*/
import "react-perfect-scrollbar/dist/css/styles.css";
import { useParams } from "react-router-dom";

import ReactGA from 'react-ga';
  const TRACKING_ID = "UA-2934706-57"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

function App() {
  const ThursdayTalkComp = () => {
    const params = useParams();
    return <TalksDetail match={params} />;
  };
  const AnnualTalkComp = () => {
    const params = useParams();
    return <AnnualTalkDetail match={params} />;
  };

  
  
  return (    
    <div>      
      <Router>
        <Switch>
          <Route path="/home" component={Home} />
          <Route path="/what-we-do" component={whatWedo} />
          <Route path="/talks" component={Talks} />
          <Route path="/who-we-are" component={WhoWeAre} />
          <Route path="/reprography-services" component={ReprographyServices} />
          <Route path="/the-access-policy" component={TheAccessPolicy} />
          <Route path="/our-collection" component={OurCollection} />
          <Route
            path="/reference-enquiry-form"
            component={ReferenceEnquiryForm}
          />
          <Route path="/research-services" component={ResearchServices} />
          <Route path="/archive-tales" component={ArchiveTales} />
          <Route path="/archive-times" component={ArchiveTimes} />
          <Route path="/book-details" component={BookDetails} />
          <Route path="/access-policy" component={TheAccessPolicy} />
          <Route path="/research-paper" component={ResearchPapers} />
          <Route path="/annual-lecture" component={AnnualLecture} />
          <Route path="/talk-details/:id" component={ThursdayTalkComp} />
          <Route path="/annual-details/:id" component={AnnualTalkComp} />
                  {/*<Route path="/imgGallery2017" component={AnnualLecture2017} /> --- For testing added by Tabbu*/}
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/" component={Home} />

          {/* </div> */}
        </Switch>
      </Router>
      
    </div>
  );
}

export default App;
