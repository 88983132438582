export const details = [
  {
    id: 1,
    img: "MadMenOfBombay.png",
    header:
      "Mad Men of Bombay: Revisiting the Creative Revolution in Indian Advertising ",
    name: "William Mazzarella",
    speakerImg: "WilliamMazzarella.png",
    date: "28-10-2021",
    videoLink: "https://www.youtube.com/watch?v=7leEZv6ueBc",
    contain1:
      "William Mazzarella is the Neukom Family Professor of Anthropology at the University of Chicago, where he has taught since 2001. He works on the political anthropology of mass publicity, critical theory, affect and aesthetics, psychoanalysis, ritual and performance, and the occult shadow of the modern. His books include <i>Shoveling Smoke: Advertising and Globalization in Contemporary India</i> (2003), <i>Censorium: Cinema and the Open Edge of Mass Publicity</i> (2013), and <i>The Mana of Mass Society</i> (2017). He is the co-author, with Eric Santner and Aaron Schuster, of <i>Sovereignty, Inc: Three Inquiries in Politics and Enjoyment</i> (2020), the co-editor, with Raminder Kaur, of <i>Censorship in South Asia: Cultural Regulation Between Sedition and Seduction</i> (2009), and the editor of <i>K D Katrak: Collected Poems</i> (2016). ",
    contain2:
      "This talk examines the generation of Indian advertising professionals that, in the first half of the 1960s, broke away from the big multinational agencies to start their own operations. How are we to understand the ambivalence with which this generation is remembered today? On the one hand, they are nostalgically celebrated as pathbreaking creative innovators; on the other hand, they are dismissed as a historically obsolete elite. This presentation seeks to understand the young ad men and women who made waves in the 1960s both in relation to the broader context of the Indian business world during those years and in relation to the subsequent developments that we know as ‘liberalization.’ ",
  },
  {
    id: 2,
    img: "TheGlobalBlueJeans.png",
    header: "The Global Blue Jeans Transformation",
    name: "Tracey Panek",
    speakerImg: "TraceyPanek.png",
    date: "05-08-2021",
    videoLink: "https://www.youtube.com/watch?v=qHRijb5J-ic ",
    contain1:
      "Tracey Panek is the Historian for Levi Strauss & Co. She manages the day-to-day workings of the Levi Strauss & Co. Archives as a key corporate asset, answering historical questions, assisting designers, brand managers, executives and other employees whose work requires historical materials in the Archives. She is a regular contributor to Unzipped, the company’s blog, writing about company history, vintage Levi’s® garments, and behind the scenes and Archives highlights. Tracey is the media spokesperson for Levi Strauss & Co. heritage. Prior to joining LS&Co., Tracey spent 14 years as Historian and Archivist at AAA Northern California, Nevada & Utah where she managed a corporate history program for the 100+ year old company. She began her corporate history career at AirTouch Communications—today Verizon and Vodafone—a San Francisco based company that launched cellular service at the Los Angeles Olympics in 1984. ",
    contain2:
      "Blue jeans—cool, comfortable, and durable—began as work pants. First manufactured by Levi Strauss & Co. in 1873, the riveted denim pants revolutionized fashion. They evolved from workwear to become a cultural symbol of America, rebellion, freedom and youth. Today, jeans can be seen on Parisian catwalks or public sidewalks. Earth is often described as the Blue Planet in reference to water, but perhaps the term could be used to describe a world wardrobe. Some have speculated that on any given day, half of Earth’s residents are wearing blue jeans. Tracey Panek, in her talk, examined the remarkable rise of global blue jeans, from Miami to Mumbai.",
  },  
  {
    id: 3,
    img: "TimberTradeTrajectory.png",
    header:
      "Timber Trade Trajectory - Urbanization of Bombay and Deforestation of Western India: Nineteenth Century",
    name: "Louiza Rodrigues ",
    speakerImg: "LouizaRodrigues.png",
    date: "18-03-2021",
    videoLink: "https://www.youtube.com/watch?v=6Kaoon8dXFY",
    contain1:
      "Dr. Louiza Rodrigues is the Professor and Head, Department of History, Ramnarain Ruia Autonomous College, Mumbai. Her area of interest and specialization is Modern India-Environmental History, Urban History, Genealogy, Maritime and Business History. She has to her credit major research projects from K R Cama Oriental Institute, Mumbai, Asiatic Society of Mumbai, Mumbai University and Exeter University U.K for Gulf Studies. She has published more than 30 Research papers and articles in books and journals of national and International repute. She is a recipient of many national awards in recognition of her contribution to research. She is the chief author of two books on the history of the Seth and Sethna family and edited two books on this family published by K R Cama Oriental Institute in 2015. Her monograph on Philip Anderson (1816 – 1857) was published by Indus, Delhi. Her recent publications include- a book on <i>‘Development and Deforestation, Making of Urban Bombay, 1800-1878’</i> published in 2019 by Primus, New Delhi and a research paper on <i>‘Gujarat Ornamental Furniture: Artisans, techniques, Skills and Global Markets during the Nineteenth Century’</i> published in an edited volume on Knowledge and Sea, Palgrave Macmillan, 2018. She is a recognized PhD guide from Ramnarain Ruia Autonomous College and Mumbai University and has successfully guided Ph.D. students.",
    contain2:
      "Dr. Louiza Rodrigues introduced some fascinating vignettes on the trajectory of timber trade in western India and illustrated as to how the urbanization process spewed into deforestation, paradoxically causing a decline in the vibrant timber trade by the end of the nineteenth century. ",
  },
  {
    id: 4,
    img: "PowerPrivilegeposture.png",
    header: "Power, Privilege and Posture: The Politics of the Seat in India",
    name: "Sarita Sundar",
    speakerImg: "SaritaSundar.png",
    date: "28-01-2021",
    videoLink: "https://www.youtube.com/watch?v=vOkq6k2eaPg",
    contain1:
      "Sarita Sundar’s practice and research spans heritage studies, popular, material and visual culture, and design theory. Over the years, she had engaged in critical enquiries into how culture engages with the visual – ranging from research into Indian vernacular typography to studies of intangible culture in performance practices. At Hanno (www.hanno.in), Sarita combines her 30+ years of working with brand strategy and design solutions with her academic training in museum studies and heritage interpretation. She has a Post-Graduation in Visual Communications from NID, Ahmedabad (1990) and a Master of Arts in Museum Studies from the University of Leicester, UK (2016). She ran an award winning multi-disciplinary design company, Trapeze, for a decade before moving her focus to her research interests at Hanno. She received the Professor Eilean Hooper-Greenhill Academic Prize for 2016 from the School of Museum Studies, University of Leicester. She is a recipient of an Arts Research Grant from the India Foundation for the Arts (IFA) through which she looked at object systems that surround ritual performances in a Kerala village festival. She is a visiting faculty at National Institute of Design, Ahmedabad, and Srishti Manipal Institute of Art, Design and Technology, Bengaluru. ",
    contain2:
      "Sarita Sundar highlighted the politics and material culture surrounding the very ordinary, common and ‘taken for granted’ activity and object from everyday life – Seating! She traversed across centuries to portray an evocative picture of the play of power, prestige and privilege manifested through various forms of seating in India and delved into the various narratives of class, caste, gender, region, religion and more while exploring the nature of relationship of the seated with the seat.",
  },
    {
    id: 5,
    img: "BusinessInTheTimeOfContagion.png",
    header: "Business in the Time of Contagion",
    name: "Lakshmi Subramanian",
    speakerImg: "LakshmiSubramanian.png",
    date: "26-11-2020",
    videoLink: "https://www.youtube.com/watch?v=trZLVTjJSsg",
    contain1:
      "Lakshmi Subramanian, currently Professor of History at HSS in BITS Pilani, Goa and Associate member of the Institute of Advanced Studies, Nantes in France, has had a long and distinguished teaching and research career with a string of books and essays to her credit. She is well known for her expertise in Indian maritime history and business groups and in the field of social and cultural history of modern India. Her major publications include: <i>Singing Gandhi’s India Music and sonic nationalism</i> (Roli Books, 2020), <i>The Sovereign and the Pirate Ordering maritime subjects in India’s western littoral</i> (Oxford University Press, New Delhi, 2016), <i>Three merchants of Bombay</i> (Penguin India, 2012), <i>A history of India 1707-1857</i> (Orient Blackswan, Delhi, 2010), <i>Veena Dhanammal The making of a legend</i> (Routledge, 2009), <i>Ports, towns and cities: A Historical tour of the Indian littoral</i> (Marg Mumbai, 2008), <i>New Mansions for Music Performance, Pedagogy and Criticism</i> (SSP- Orient Longmans, Delhi 2008), <i>From the Tanjore Court to the Madras Music Academy: A social history of music in South India</i> (Oxford University Press, New Delhi, 2006)",
    contain2:
      "In her talk, Dr. Lakshmi Subramanian gave a historical perspective as to how pandemics have impacted businesses in the past and the learnings we can draw from them for the present. She sought to explain the phenomenon of contagion more broadly and to investigate the narratives it generates. ",
  },
  
  {
    id: 6,
    img: "TypewriterTipTip.png",
    header: "Typewriter Tip Tip",
    name: "Chirodeep Chaudhuri",
    speakerImg: "Speaker1.png",
    date: "22-11-2019",
    videoLink: "https://www.youtube.com/watch?v=eD52TAyBqcI",
    contain1:
      "Chirodeep Chaudhuri is the author of the critically feted book <i>‘A Village In Bengal: Photographs and an Essay</i>”, a result of his 13-year long engagement with his ancestral village in West Bengal and his family’s nearly two century old tradition of the Durga Puja. Chirodeep’s work documents the urban landscape and he has often been referred to as the “chronicler of Bombay”. As a photographer he has produced diverse documents of his home city in a range of projects like 'Seeing Time: Public Clocks of Bombay', 'The One-Rupee Entrepreneur', 'The Commuters', 'In the city, a library' among others. His work has also been featured in important publications about the city like <i>“Bombay: The Cities Within”, “Fort Walks”, “Anchoring a City Line”, “A City’s Legacy: The Indian Navy’s Heritage in Mumbai”. “Bombay Then: Mumbai Now” and “Bombay, Meri Jaan”<\i> to name a few. He is also the co-author of <i>“With Great Truth & Regard: The History of the Typewriter in India”<\i>. Over the last two and a half decades, he has been on the teams of media organisations like The Sunday Observer, the Outlook group and traveljini.com. He was the Head of the Design and Photography departments of the international arts and culture magazine Time Out’s three India editions and also the Editor of Photography of National Geographic Traveler (India). He lives in Bombay and divides his time between his assignments, projects, teaching commitments and chasing subjects as diverse as abandoned helmets and airport smoking rooms.",
    contain2:
      "Chirodeep Chaudhuri has extensively photographed the world of typewriters in India for the book ‘With Great Truth & Regard: The History of the Typewriter in India’ published by the Godrej Archives in 2016. During the talk, he gave glimpses of that to the audience along with amusing anecdotes and personal observations and experience about the typewriter’s fascinating public life on the street and how it lives on in people’s hearts.  ",
  },
  
];
